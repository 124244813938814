import React from "react";
import main from '../css/main.module.css';
import { Link } from 'react-router-dom';


const Footer = () => {
    return(
        <footer className={main.footer_nav}>
            <ul className={main.cf}>
                <li><Link to='/company'>運営者情報</Link></li>
                <li><Link to='/termsofuse'>利用規約</Link></li>
                <li><Link to='/termsofuse'>プライバシーポリシー</Link></li>
                <li><Link to='/specifiedcommercial'>特定商取引法に基づく表示</Link></li>
            </ul>
        </footer>
    )
}
export default Footer;