import React, { useState } from "react";
import termsofuse from '../css/termsofuse.module.css';
import GoBack from "./GoBack";

//言語切替
import { useTranslation } from 'react-i18next';


function TermsOfUse() {
    const [t] = useTranslation();
    const [onPrivacyPolicyTab, setOnPrivacyPolicyTab] = useState(true);
    // inputタグに初期値を与えてないとエラーになる
    const [avoidErrorDummy, setAvoidErrorDummy] = useState("");

    const tabConponent = () => {
        if(onPrivacyPolicyTab){
            return(
                <div>
                    <input id="user" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={termsofuse.tab_item_on_termsofuse} htmlFor="user" onClick={() => setOnPrivacyPolicyTab(true)}>{t('termsofuse_tab')}</label>
                    <input id="creator" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={termsofuse.tab_item_off} htmlFor="creator" onClick={() => setOnPrivacyPolicyTab(false)}>{t('privacypolicy_tab')}</label>
                </div>
            )
        } else {
            return(
                <div>
                    <input id="user" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={termsofuse.tab_item_off} htmlFor="user" onClick={() => setOnPrivacyPolicyTab(true)}>{t('termsofuse_tab')}</label>
                    <input id="creator" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={termsofuse.tab_item_on_termsofuse} htmlFor="creator" onClick={() => setOnPrivacyPolicyTab(false)}>{t('privacypolicy_tab')}</label>
                </div>
            )
        }
    }

    const switchContentConponent = () => {
        if(onPrivacyPolicyTab){
            return(
                <div>
                    {termsOfUseEnglishSummaryConponent()}
                    {termsOfUseConponent()}
                </div>
            )
        } else {
            return(
                <div>
                    {privacyPolicyEnglishSummaryConponent()}
                    {privacyPolicyConponent()}
                </div>
            )
        }
    }

    const termsOfUseEnglishSummaryConponent = () => {
        return(
            <React.Fragment>
                <div className={termsofuse.summary_title}>
                    {t('termsofuse_english_summary_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_english_summary_paragraph_1')}</p>                    
                    <p>{t('termsofuse_english_summary_paragraph_2')}</p>                    
                    <p>{t('termsofuse_english_summary_paragraph_3')}</p>                    
                    <p>{t('termsofuse_english_summary_paragraph_4')}</p>                    
                    <p>{t('termsofuse_english_summary_paragraph_5')}</p>                    
                    <p>{t('termsofuse_english_summary_paragraph_6')}</p>                    
                </div>
            </React.Fragment>
        )
    }
    const privacyPolicyEnglishSummaryConponent = () => {
        return(
            <React.Fragment>
                <div className={termsofuse.summary_title}>
                    {t('privacypolicy_english_summary_title')}
                </div>
                <div className={termsofuse.summary_section_title}>
                    {t('privacypolicy_english_summary_1_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_1_paragraph_1')}</p>                    
                </div>
                <div className={termsofuse.summary_section_title}>
                    {t('privacypolicy_english_summary_2_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_2_paragraph_1')}</p>                    
                </div>
                <div className={termsofuse.summary_section_title}>
                    {t('privacypolicy_english_summary_3_title')}
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_3_small_title_1')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_3_paragraph_1')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_3_small_title_2')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_3_paragraph_2')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_3_small_title_3')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_3_paragraph_3')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_3_small_title_4')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_3_paragraph_4')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_3_small_title_5')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_3_paragraph_5')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_3_small_title_6')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_3_paragraph_6')}</p>                    
                </div>
                <div className={termsofuse.summary_section_title}>
                    {t('privacypolicy_english_summary_4_title')}
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_4_small_title_1')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_4_paragraph_1')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_4_small_title_2')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_4_paragraph_2')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_english_summary_4_small_title_3')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_4_paragraph_3')}</p>                    
                </div>
                <div className={termsofuse.summary_section_title}>
                    {t('privacypolicy_english_summary_5_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_5_paragraph_1')}</p>                    
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_english_summary_5_paragraph_2')}</p>                    
                </div>



            </React.Fragment>
        )
    }


    const termsOfUseConponent = () => {
        return(
            <React.Fragment>
                <div className={termsofuse.preface}>
                    {t('termsofuse_preface')}
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_1_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_1_body_initial')}</p>
                    <p>{t('termsofuse_main_1_body_1')}</p>                    
                    <p>{t('termsofuse_main_1_body_2')}</p>                    
                    <p>{t('termsofuse_main_1_body_3')}</p>                    
                    <p>{t('termsofuse_main_1_body_4')}</p>  
                    <p>{t('termsofuse_main_1_body_5')}</p>                   
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_2_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_2_body_1')}</p>                    
                    <p>{t('termsofuse_main_2_body_2')}</p>                    
                    <p>{t('termsofuse_main_2_body_3')}</p>                    
                    <p>{t('termsofuse_main_2_body_4')}</p>  
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_3_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_3_body_1')}</p>                    
                    <p>{t('termsofuse_main_3_body_2')}</p>                    
                    <p>{t('termsofuse_main_3_body_3')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_4_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_4_body_1')}</p>                    
                    <p>{t('termsofuse_main_4_body_2')}</p>                    
                    <p>{t('termsofuse_main_4_body_3')}</p>                    
                    <p>{t('termsofuse_main_4_body_4')}</p>                    
                    <p>{t('termsofuse_main_4_body_5')}</p>                    
                    <p>{t('termsofuse_main_4_body_6')}</p>                    
                    <p>{t('termsofuse_main_4_body_7')}</p>                    
                    <p>{t('termsofuse_main_4_body_8')}</p>                    
                    <p>{t('termsofuse_main_4_body_9')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_5_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_5_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_6_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_6_body_1')}</p>                    
                    <p>{t('termsofuse_main_6_body_2')}</p>                    
                    <p>{t('termsofuse_main_6_body_3')}</p>                                        
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_7_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_7_body_initial')}</p>   
                    <p>{t('termsofuse_main_7_body_1')}</p>                    
                    <p>{t('termsofuse_main_7_body_2')}</p>                    
                    <p>{t('termsofuse_main_7_body_3')}</p>                    
                    <p>{t('termsofuse_main_7_body_4')}</p>                    
                    <p>{t('termsofuse_main_7_body_5')}</p>                    
                    <p>{t('termsofuse_main_7_body_6')}</p>                    
                    <p>{t('termsofuse_main_7_body_7')}</p>                    
                    <p>{t('termsofuse_main_7_body_8')}</p>                    
                    <p>{t('termsofuse_main_7_body_9')}</p>
                    <p>{t('termsofuse_main_7_body_10')}</p>                    
                    <p>{t('termsofuse_main_7_body_11')}</p>                    
                    <p>{t('termsofuse_main_7_body_12')}</p>                    
                    <p>{t('termsofuse_main_7_body_13')}</p>                    
                    <p>{t('termsofuse_main_7_body_14')}</p>                    
                    <p>{t('termsofuse_main_7_body_15')}</p>                    
                    <p>{t('termsofuse_main_7_body_16')}</p>                    
                    <p>{t('termsofuse_main_7_body_17')}</p>                    
                    <p>{t('termsofuse_main_7_body_18')}</p>
                    <p>{t('termsofuse_main_7_body_19')}</p>                    
                    <p>{t('termsofuse_main_7_body_20')}</p>                    
                    <p>{t('termsofuse_main_7_body_21')}</p>                    
                    <p>{t('termsofuse_main_7_body_22')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_7.5_important_title.5_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_7.5_important_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_8_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_8_body_initial')}</p>
                    <p>{t('termsofuse_main_8_body_1')}</p>              
                    <p>{t('termsofuse_main_8_body_2')}</p>              
                    <p>{t('termsofuse_main_8_body_3')}</p>              
                    <p>{t('termsofuse_main_8_body_4')}</p>              
                    <p>{t('termsofuse_main_8_body_5')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_9_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_9_body_1')}</p>              
                    <p>{t('termsofuse_main_9_body_2')}</p>              
                    <p>{t('termsofuse_main_9_body_3')}</p>              
                    <p>{t('termsofuse_main_9_body_4')}</p>
                    <p>{t('termsofuse_main_9_body_5')}</p>              
                    <p>{t('termsofuse_main_9_body_6')}</p>              
                    <p>{t('termsofuse_main_9_body_7')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_10_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_10_body_1')}</p>              
                    <p>{t('termsofuse_main_10_body_2')}</p>              
                    <p>{t('termsofuse_main_10_body_3')}</p>       
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_11_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_11_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_12_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_12_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_13_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_13_body_initial')}</p>              
                </div>

            </React.Fragment>
        )
    }

    const privacyPolicyConponent = () => {
        return(
            <React.Fragment>
                <div className={termsofuse.preface}>
                    {t('privacypolicy_preface')}
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_1_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_1_body_initial')}</p>
                    <p>{t('privacypolicy_main_1_body_1')}</p>                    
                    <p>{t('privacypolicy_main_1_body_2')}</p>                    
                    <p>{t('privacypolicy_main_1_body_3')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_2_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_2_body_initial')}</p>
                    <p>{t('privacypolicy_main_2_body_1')}</p>                    
                    <p>{t('privacypolicy_main_2_body_2')}</p>
                    <p>{t('privacypolicy_main_2_body_3')}</p>                    
                    <p>{t('privacypolicy_main_2_body_4')}</p>                    
                    <p>{t('privacypolicy_main_2_body_5')}</p>                    
                    <p>{t('privacypolicy_main_2_body_6')}</p>                    
                    <p>{t('privacypolicy_main_2_body_7')}</p>                    
                    <p>{t('privacypolicy_main_2_body_8')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_3_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_3_body_initial')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_4_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_4_body_initial')}</p>
                    <p>{t('privacypolicy_main_4_body_1')}</p>                    
                    <p>{t('privacypolicy_main_4_body_2')}</p>
                    <p>{t('privacypolicy_main_4_body_3')}</p>                    
                    <p>{t('privacypolicy_main_4_body_4')}</p>                    
                    <p>{t('privacypolicy_main_4_body_5')}</p>                    
                    <p>{t('privacypolicy_main_4_body_6')}</p>                    
                    <p>{t('privacypolicy_main_4_body_7')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_5_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_5_body_initial')}</p>
                    <p>{t('privacypolicy_main_5_body_1')}</p>                    
                    <p>{t('privacypolicy_main_5_body_2')}</p>
                    <p>{t('privacypolicy_main_5_body_3')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_6_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_6_body_initial')}</p>
                    <p>{t('privacypolicy_main_6_body_1')}</p>                    
                    <p>{t('privacypolicy_main_6_body_2')}</p>
                    <p>{t('privacypolicy_main_6_body_3')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_7_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_7_body_initial')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_8_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_8_body_initial')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_9_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_9_body_initial')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_10_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_10_body_initial')}</p>
                    <p>{t('privacypolicy_main_10_body_1')}</p>                    
                    <p>{t('privacypolicy_main_10_body_2')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_11_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_11_body_initial')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('privacypolicy_main_complement_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('privacypolicy_main_complement_body_1')}</p>                    
                    <p>{t('privacypolicy_main_complement_body_2')}</p>
                    <p>{t('privacypolicy_main_complement_body_3')}</p>
                </div>







            </React.Fragment>
        )
    }



    return(
        <div>
            <GoBack />
            <div className={termsofuse.tabs}>
                {tabConponent()}
            </div>
            {switchContentConponent()}
        </div>
    )
}

export default TermsOfUse;


