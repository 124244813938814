import React from "react";
import { useHistory } from 'react-router-dom';
import main from '../css/main.module.css';


//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


//言語切替
import { useTranslation } from 'react-i18next';


function GoBackMarginLess() {
    const [t] = useTranslation();
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    }


    return(
        <div onClick={goBack}>
            <div className={main.goback_btn_margin_less}>
                <FontAwesomeIcon icon={['far', 'arrow-left']} />
                &nbsp;
                {t('goback')}
            </div>
        </div>
    )
}

export default GoBackMarginLess;