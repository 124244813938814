import React , { useContext, useState, useEffect } from "react";
import { AuthContext } from "../providers/AuthProvider";
import * as Api from "../service/api";
import { notificationType } from '../service/constants';

import dig from "object-dig";
import notificationlist from '../css/notificationlist.module.css';
import { Link } from 'react-router-dom';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//言語切替
import { useTranslation } from 'react-i18next';

const moment = require('moment');

function Notifications(props) {
    // currentUser呼んでたら、404になるらしい
    const currentUser = useContext(AuthContext);
    const [notifications, setNotifications] = useState([]);

    const [t] = useTranslation();


    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                const notificationsList = await Api.getNotificationsByUserId(currentUser.currentUser.uid);
                // 表示名の取得が必要
                // 一旦これで行くが、notificationの数が増えるとつらいので、表示名を変更不可とかにして、notification突っ込むときに入れるか
                setNotifications(notificationsList);
            }
        }
        fetch();
    }, [currentUser]);

    //遷移時にオープンフラグ立てる
    const handleOpen = async (docId) => {
        await Api.updateNotificationOpenFlg(docId, true);
        //openFLgを書き換えた後に引き直す
        if(dig(currentUser,"currentUser", "uid")){
            const dataNotificationCount = await Api.getNotificationCountByUserId(currentUser.currentUser.uid);
            props.changeNotificationCount(dataNotificationCount.length);
        }
    };

    const notificationViewList = notifications.map((notification) => {
        return (
            <div key={notification.id} className={
                notification.openFlg ? notificationlist.isOpen : ""
            }>
                {
                    (() => {
                        if(notification.notificationType === notificationType.confirmRequest){
                            return(
                                <div className={notificationlist.item}>
                                    <Link to={"/requestdetail?reuqestId="+notification.requestId} onClick={() => handleOpen(notification.id)} className={notificationlist.linktag}>
                                        <div className={notificationlist.flex}>
                                            <div className={notificationlist.requstorImage}>
                                                <img src={notification.requestorProfileImageUrl} alt="" />
                                            </div>
                                            <div className={notificationlist.content}>
                                                <div>{notification.requestorDisplayName}さんからのリクエストがあります</div>
                                                {/* <div>{notification.createdAt}</div> */}
                                                <div className={notificationlist.date}>
                                                    {moment(notification.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                                </div>
                                            </div>
                                            <div className={notificationlist.arrow}>
                                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        } else if(notification.notificationType === notificationType.cancel){
                            return(
                                <div className={notificationlist.item}>
                                    <Link to={"/requestdetail?reuqestId="+notification.requestId} onClick={() => handleOpen(notification.id)} className={notificationlist.linktag}>
                                        <div className={notificationlist.flex}>
                                            <div className={notificationlist.requstorImage}>
                                                <img src={notification.creatorProfileImageUrl} alt="" />
                                            </div>
                                            <div className={notificationlist.content}>
                                                <div>From:&nbsp;{notification.creatorDisplayName}</div>
                                                <div>{t('notification_view_text_cancel')}</div>
                                                {/* <div>{notification.createdAt}</div> */}
                                                <div className={notificationlist.date}>
                                                    {moment(notification.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                                </div>
                                            </div>
                                            <div className={notificationlist.arrow}>
                                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        } else if(notification.notificationType === notificationType.payment){
                            return(
                                <div className={notificationlist.item}>
                                    <Link to={"/requestdetail?reuqestId="+notification.requestId} onClick={() => handleOpen(notification.id)} className={notificationlist.linktag}>
                                        <div className={notificationlist.flex}>
                                            <div className={notificationlist.requstorImage}>
                                                <img src={notification.creatorProfileImageUrl} alt="" />
                                            </div>
                                            <div className={notificationlist.content}>
                                                <div>From:&nbsp;{notification.creatorDisplayName}</div>
                                                <div>{t('notification_view_text_payment')}</div>
                                                {/* <div>{notification.createdAt}</div> */}
                                                <div className={notificationlist.date}>
                                                    {moment(notification.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                                </div>
                                            </div>
                                            <div className={notificationlist.arrow}>
                                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        } else if(notification.notificationType === notificationType.startCreate){
                            return(
                                <div className={notificationlist.item}>
                                    <Link to={"/requestdetail?reuqestId="+notification.requestId} onClick={() => handleOpen(notification.id)} className={notificationlist.linktag}>
                                        <div className={notificationlist.flex}>
                                            <div className={notificationlist.requstorImage}>
                                                <img src={notification.requestorProfileImageUrl} alt="" />
                                            </div>
                                            <div className={notificationlist.content}>
                                                <div>{notification.requestorDisplayName}さんから支払いがありました。創作を開始してください。すでに作品データがある場合は納品をお願いします</div>
                                                {/* <div>{notification.createdAt}</div> */}
                                                <div className={notificationlist.date}>
                                                    {moment(notification.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                                </div>
                                            </div>
                                            <div className={notificationlist.arrow}>
                                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        } else if(notification.notificationType === notificationType.receive){
                            return(
                                <div className={notificationlist.item}>
                                    <Link to={"/requestdetail?reuqestId="+notification.requestId} onClick={() => handleOpen(notification.id)} className={notificationlist.linktag}>
                                        <div className={notificationlist.flex}>
                                            <div className={notificationlist.requstorImage}>
                                                <img src={notification.creatorProfileImageUrl} alt="" />
                                            </div>
                                            <div className={notificationlist.content}>
                                            <div>From:&nbsp;{notification.creatorDisplayName}</div>
                                                <div>{t('notification_view_text_receive')}</div>
                                                {/* <div>{notification.createdAt}</div> */}
                                                <div className={notificationlist.date}>
                                                    {moment(notification.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                                </div>
                                            </div>
                                            <div className={notificationlist.arrow}>
                                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        } else if(notification.notificationType === notificationType.done){
                            return(
                                <div className={notificationlist.item}>
                                    <Link to={"/requestdetail?reuqestId="+notification.requestId} onClick={() => handleOpen(notification.id)} className={notificationlist.linktag}>
                                        <div className={notificationlist.flex}>
                                            <div className={notificationlist.requstorImage}>
                                                <img src={notification.requestorProfileImageUrl} alt="" />
                                            </div>
                                            <div className={notificationlist.content}>
                                                <div>{notification.requestorDisplayName}さんが受け取りを完了しました。</div>
                                                {/* <div>{notification.createdAt}</div> */}
                                                <div className={notificationlist.date}>
                                                    {moment(notification.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                                </div>
                                            </div>
                                            <div className={notificationlist.arrow}>
                                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        }
                    })()
                }
            </div>
        );
      });
    

    return(
        <div>
            <h2>{t('notification_title')}</h2>
            <div>
                {notificationViewList}
            </div>
        </div>
    )
}

export default Notifications;