import React , { useRef } from "react";
import * as Api from "../service/api";
import main from '../css/main.module.css';
import receivedeliverwork from '../css/receivedeliverwork.module.css';
import { requestStatus, notificationType, salesStatus, serviceUrl } from '../service/constants';
import * as mail from "./SendEventTriggerMail";
import useImageEnhancer from './EnhanceImage';
import useFileEnhancer from './EnhanceFile';

//言語切替
import { useTranslation } from 'react-i18next';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";


function ReceiveDeliverWork(props) {

    const [t] = useTranslation();

    const DownloadProductImage = () => {
        const enhance = useImageEnhancer();
      
        return (
            <div>
                <div className={receivedeliverwork.btn}>
                    <button
                        className={main.primary_btn}
                        onClick={() => {
                            enhance.downloadImage(enhance.canv, enhance.img, 'product_image');
                            completeRequest();
                        }}
                        type="button"
                    >
                        {t('download_img')}
                    </button>
                </div>
                <canvas ref={enhance.canv} style={{ display: 'none' }}>
                    <img
                        ref={enhance.img}
                        src={props.request.productImageUrl}
                        alt=""
                        crossOrigin="anonymous"
                    />
                </canvas>
            </div>
        );
      };
      
      const DownloadProductFile = () => {
        const enhance = useFileEnhancer();
      
        return (
            <div>
                <div className={receivedeliverwork.btn}>
                    <button
                        className={main.primary_btn}
                        onClick={() => {
                            enhance.downloadImage(enhance.canv, enhance.img, 'product_file', props.request.productFileUrl);
                            completeRequest();
                        }}
                        type="button"
                    >
                    {t('download_psd')}
                    </button>
                </div>
                <p>{t('psd_attention')}</p>
                <canvas ref={enhance.canv} style={{ display: 'none' }}>
                    <img
                        ref={enhance.img}
                        src={props.request.productFileUrl}
                        alt=""
                        crossOrigin="anonymous"
                    />
                </canvas>
            </div>
        );
      };

    const processing = useRef(false);

    const completeRequest= async () => {
        //処理中(true)なら処理せずに抜ける
        if (processing.current) {
            return
        };
                
        //処理中フラグを上げる
        processing.current = true;
        
        Api.updateRequestStatusOnlyStatus(props.request.id, requestStatus.done);
        const isNewNotification = await Api.isNewNotification(props.request.requestId, notificationType.done);
        if(isNewNotification){
            await Api.createNotificationForCreator(
                props.request.requestId, 
                props.request.requestorId, 
                props.request.creatorId, 
                props.request.requestType, 
                notificationType.done, 
                props.request.requestorDisplayName, 
                props.request.creatorDisplayName, 
                props.request.requestorProfileImageUrl
            )
            // クリエイターさんにメール
            let title = `${props.request.requestorDisplayName}さんの受け取りが完了しました`;
            let content = props.request.creatorDisplayName +"さま\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
            +props.request.requestorDisplayName
            +"さんの受け取りが完了しました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
            +serviceUrl.urlToRequestDetail+"/?reuqestId="+props.request.requestId
            +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-";

            mail.SendEventTriggerMail(props.request.creatorMailAddress, title, content);     
        }
        const data = await Api.getSalesDocId(props.request.requestId);
        if(data.length > 0){
            const salesDocId = data[0].id;
            Api.updateSalesStatus(salesDocId,salesStatus.done);
        }
        //処理中フラグを下げる
        processing.current = false;
    }


    return(
        <div>
            <div>
                <h2>{t('receive_deliver_work_title')}</h2>
                <div>
                    <div className={receivedeliverwork.image}>
                        <img src={props.request.productImageUrl} alt="" />
                    </div>
                    {DownloadProductImage()}
                    {/* psdファイルを一旦辞める */}
                    {/* {DownloadProductFile()} */}
                </div>
            </div>
            <div className={receivedeliverwork.ask_section}>
                <h2>{t('receive_deliver_work_ask_title')}</h2>
                <div>
                    {t('receive_deliver_work_ask_body')}
                </div>
                <br />
                <div>
                    {t('receive_deliver_work_ask_attention')}
                </div>
                <br />
                <div>
                    {t('receive_deliver_work_ask_please')}
                </div>
                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className={receivedeliverwork.instgram_share_btn}>
                    <FontAwesomeIcon icon={faInstagram} className={receivedeliverwork.instagram_icon}/>
                    &nbsp;
                    {t('receive_deliver_work_share_instagram')}
                </a>
                <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className={receivedeliverwork.twittershare_btn}>
                    <FontAwesomeIcon icon={faTwitter} className={receivedeliverwork.instagram_icon}/>
                    &nbsp;
                    {t('receive_deliver_work_share_twitter')}
                </a>
            </div>
        </div>
)
}

export default ReceiveDeliverWork;