import React , { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { UserInfoContext } from "../providers/UserInfoProvider";

import * as Api from "../service/api";
import * as Logic from "../service/logic";
import main from '../css/main.module.css';
import profile from '../css/profile.module.css';
import readmore from '../css/readmore.module.css';
import confirmationdialog from '../css/confirmationdialog.module.css';
import { Link, useLocation } from 'react-router-dom';
import workdetail from '../css/workdetail.module.css';
import dig from "object-dig";
import { requestClass, feeRate, requestStatus, eventCode, queryParameterKeys, languageCode } from '../service/constants';
import LoginModal from "./LoginModal";
import GoBack from "./GoBack";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import SinglSquareImageListForHorizontalScroll from "./SinglSquareImageListForHorizontalScroll";


import { getAnalytics, logEvent } from "firebase/analytics";

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//言語切替
import { useTranslation } from 'react-i18next';


// サインインボタンタップ時の確認ポップアップのアニメーション
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });  
  

const WorkDetail = (props) => {

    const currentUser = useContext(AuthContext);
    const userInfo = useContext(UserInfoContext);

    const analytics = getAnalytics();
    
    const [t] = useTranslation();

    const [work, setWork] = useState("");
    const [creatorProfile, setCreatorProfile] = useState("");
    const [isLiked, setIsLiked] = useState(false);
    const [isFollowed, setIsFollowed] = useState(false);
    const [isIntroOpened, setIsIntroOpened] = useState(false);
    const [introductionJaLength, setIntroductionJaLength] = useState(0);
    const [introductionEnLength, setIntroductionEnLength] = useState(0);
    const [workViewCount, setWorkViewCount] = useState(0);

    const [requestorId, setRequestorId] = useState("");

    const [langCode, setLangCode] = useState("");  

    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [dialogOpenForRequestCreate, setDialogOpenForRequestCreate] = useState(false);
    const [dialogOpenForRequestUse, setDialogOpenForRequestUse] = useState(false);


    const [otherWorks, setOtherWorks] = useState([]);
    const [popularWorks, setPopularWorks] = useState([]);




    const location = useLocation();
    let creatorId ="";
    let workId ="";
    let trackingId ="";
    let queryParams = {};
    queryParams = Logic.convertToAssociatvieArray(location);
    // for (let key in queryParams) {
    //     console.log('key_result:' + key + ' value_result:' + queryParams[key]);
    // }
    if(Object.keys(queryParams).length > 0){
        if(typeof queryParams[queryParameterKeys.creatorId] !== "undefined"){
            creatorId = queryParams[queryParameterKeys.creatorId]
        }
        if(typeof queryParams[queryParameterKeys.workId] !== "undefined"){
            workId = queryParams[queryParameterKeys.workId]
        }
        if(typeof queryParams[queryParameterKeys.trackingId] !== "undefined"){
            trackingId = queryParams[queryParameterKeys.trackingId]
        }
    }

    useEffect(()=>{
        // ここでRenderのタイミングでviewのカウントアップとか、、、
        // 作るか悩む

        // これ、一覧を取得したときに、workの情報全部取ってきて、propsで渡せんのかな
        const initImage = async() =>{
            const data = await Api.getWork(workId);
            setWork(data);
        } 

        const initCreatorProfile = async() =>{
            const data = await Api.getProfileByUserId(creatorId);
            setCreatorProfile(data[0]);
            setIntroductionJaLength(data[0].introductionJa.length);
            setIntroductionEnLength(data[0].introductionEn.length)
        } 
    
        const checkIsAlreadyLiked = async () => {
            if(dig(userInfo,"userInfo", "uid")){
                const likes = await Api.getLikes(userInfo.userInfo.uid, workId);
                
                let isAlreadyLiked = false;
                if(likes.length > 0){
                    setIsLiked(!isAlreadyLiked);
                }else{
                    setIsLiked(isAlreadyLiked);
                }
            }
        }

        const checkIsAlreadyFollowed = async () => {
            if(dig(userInfo,"userInfo", "uid")){
                const follow = await Api.getFollowedId(userInfo.userInfo.uid, creatorId);
                
                let isAlreadyFollowed = false;
                if(follow.length > 0){
                    setIsFollowed(!isAlreadyFollowed);
                }else{
                    setIsFollowed(isAlreadyFollowed);
                }
            }
        }

        const initWorkViewCount = async (workId) =>{
            const workviews = await Api.getWorkViewRecord(workId);
            if(workviews.length >0){
                setWorkViewCount(workviews[0].viewCount);
            }
        }

        const initOtherWorks = async () => {
            const tmpOtherWorks = await Api.getOtherWorksOfCreator(workId, creatorId);
            setOtherWorks(tmpOtherWorks);
        }

        const initPopularWorks = async () => {
            let topViewWorkIds = [];
            const tmpTopViewWorkIds = await Api.getTopViewWorkIds();
            tmpTopViewWorkIds.forEach(function(item) {
                topViewWorkIds.push(item.workId);
            });
        
            if(topViewWorkIds.length > 0){
                const tmpPopularWorks = await Api.getPopularWorksOfCreator(workId, topViewWorkIds);
                setPopularWorks(tmpPopularWorks);
            }
        }

        if(dig(userInfo,"userInfo", "uid")){
            setRequestorId(userInfo.userInfo.uid);
        }
        if(dig(userInfo,"userInfo", "langCode")){
            setLangCode(userInfo.userInfo.langCode);
        }

        initImage();
        initCreatorProfile();
        checkIsAlreadyLiked();
        checkIsAlreadyFollowed();
        initOtherWorks();
        initPopularWorks();

        window.scrollTo(0,0);

        logEvent(analytics, 'view_workdetail', { name: 'workddetail?workId='+workId});

        Logic.eventLogging(userInfo, eventCode.viewWorkDetail, workId, trackingId);
        if(workId !== ""){
            Api.addOrUpdateWorkViews(workId);
            initWorkViewCount(workId);
        }
    }, [userInfo, location.search]);    

    const handleLike = async () =>{
        if(dig(currentUser,"currentUser", "uid")){
            await Api.addOrDeleteLike(currentUser.currentUser.uid, workId, isLiked, creatorId, work.imageUrl);
            const likes = await Api.getLikeNumberOfWork(workId);
            // like数をworkに詰める
            await Api.updateLikeNumberOfWork(workId, likes.length);
            setIsLiked(!isLiked);
        }else {
            // 未ログインで押されたときに　ログインモーダルを呼ぶ必要がある
            setOpenLoginModal(!openLoginModal);
            Logic.eventLogging(userInfo, eventCode.openLoginModalOnWorkDetail, workId, "");
        }
        Logic.eventLogging(userInfo, eventCode.tapLike, workId, "");
    }    

    const likeButton = () => {
        // 再代入可能
        let buttonDom
        if(!isLiked){
            buttonDom =  <div className={main.like_area} onClick={handleLike}><div className={main.anime}><FontAwesomeIcon icon={['far', 'star']} className={main.like_star}/></div></div>

        }else{
            buttonDom = <div className={main.like_area} onClick={handleLike}><FontAwesomeIcon icon={['fas', 'star']} className={main.like_star}/></div>
        }
        return buttonDom;
    }

    const handleReadMore = () =>{
        setIsIntroOpened(!isIntroOpened);
    }

    const intorductionContentComponent = () => {
        if(props.langCode === languageCode.english && creatorProfile.introductionEn !== ""){
            return(
                <div>
                    {creatorProfile.introductionEn}
                </div>
            )
        }else{
            return(
                <div>
                    {creatorProfile.introductionJa}
                </div>
            )
        }
    }
    const intorductionComponent = () => {
        if(introductionJaLength < 100 && introductionEnLength < 200){
            return(
                <div className={profile.profile_introduction}>
                    <div className={readmore.creator_introduction_content_open}>
                        {intorductionContentComponent()}
                    </div>
                </div>
            )
        }else {
            if(isIntroOpened){
                    return(
                        <div className={profile.profile_introduction}>
                            <div className={readmore.creator_introduction_content_open}>
                                {intorductionContentComponent()}
                            </div>
                            <p className={readmore.open_close_btn} onClick={handleReadMore}>{t('readmore')}</p>
                        </div>
                    )    
            } else {
                    return(
                        <div className={profile.profile_introduction}>
                            <div className={readmore.creator_introduction_content}>
                                {intorductionContentComponent()}
                            </div>
                            <p className={readmore.open_close_btn} onClick={handleReadMore}>{t('readmore')}</p>
                        </div>
                    )
            }
        }
    }

    const handleFollow = async () =>{
        if(dig(currentUser,"currentUser", "uid")){
            await Api.addOrDeleteFollow(currentUser.currentUser.uid, creatorId, isFollowed);
            setIsFollowed(!isFollowed);
        } else {
            // 未ログインで押されたときに　ログインモーダルを呼ぶ必要がある
            setOpenLoginModal(!openLoginModal);
            Logic.eventLogging(userInfo, eventCode.openLoginModalOnWorkDetail, creatorId, "");
        }
        Logic.eventLogging(userInfo, eventCode.tapFollow, creatorId, "");
    }    

    const FollowButton = () => {
        // 再代入可能
        let buttonDom;
        if(!isFollowed){
            buttonDom = <div className={profile.profile_follow_off} onClick={handleFollow}><p>{t('follow')}</p></div>
        }else{
            buttonDom = <div className={profile.profile_follow_on} onClick={handleFollow}><p>{t('following')}</p></div>
        }
        return buttonDom;
    }

    // ログインモーダルセット
    const handleLoginModal = () => {
        setOpenLoginModal(!openLoginModal);
        Logic.eventLogging(userInfo, eventCode.openLoginModalOnWorkDetail, "", "");
    }  

    const loginModal = () => {
        // 再代入可能
        let modalDom;
        if(!openLoginModal){
            modalDom = <div></div>;
        }else{
            modalDom = <LoginModal openLoginModal={openLoginModal} closeLoginModal={closeLoginModal} userInfo={userInfo}/>;
        }
        return modalDom;
    }
    const closeLoginModal = (flg) =>{
        setOpenLoginModal(flg);
    }

    const handleDailogOpenForRequestCreate = () => {
        setDialogOpenForRequestCreate(true);
        Logic.eventLogging(userInfo, eventCode.tapRequestCreateButton, "", "");
    };
        
    const handleDailogCloseForRequestCreate = () => {
        setDialogOpenForRequestCreate(false);
    };

    const handleDailogOpenForRequestUse = () => {
        setDialogOpenForRequestUse(true);
        Logic.eventLogging(userInfo, eventCode.tapRequestUseButton, "", "");
    };
        
    const handleDailogCloseForRequestUse = () => {
        setDialogOpenForRequestUse(false);
    };
    

    const confirmationDialogComponentForRequestCreate = () => {
        return(
            <div>
                <Dialog
                    open={dialogOpenForRequestCreate}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDailogCloseForRequestCreate}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{t('dialog_confirmation_before_request_create_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t('dialog_confirmation_before_request_create_body1')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={confirmationdialog.secondary_option} onClick={handleDailogCloseForRequestCreate}>{t('dialog_confirmation_before_reuqest_create_secondary_option')}</Button>
                        <Link to={toRequestCreate} className={confirmationdialog.primary_option_before_request} onClick={() => addReuqestCreateEventLog()}>{t('dialog_confirmation_before_reuqest_create_primary_option')}</Link>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    const confirmationDialogComponentForRequestUse = () => {
        return(
            <div>
                <Dialog
                    open={dialogOpenForRequestUse}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDailogCloseForRequestUse}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{t('dialog_confirmation_before_request_use_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t('dialog_confirmation_before_request_use_body1')}
                            <br />
                            <br />
                            {t('dialog_confirmation_before_request_use_body2')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={confirmationdialog.secondary_option} onClick={handleDailogCloseForRequestUse}>{t('dialog_confirmation_before_reuqest_use_secondary_option')}</Button>
                        <Link to={toRequestUse} className={confirmationdialog.primary_option_before_request} onClick={() => createReuqestUse()}>{t('dialog_confirmation_before_reuqest_use_primary_option')}</Link>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }


    const requestCreateButton = () => {
        if(creatorProfile.isRequestAcceptable > 0){
            if(requestorId !== ""){
                if(requestorId !== creatorProfile.creatorId){
                    return(
                        <div className={workdetail.btn_area}>
                            <a herf="#" onClick={handleDailogOpenForRequestCreate} className={main.primary_btn}>{t('request_create')}</a>
                        </div>
                    )   
                } else {
                    // 自分には依頼できない
                    return(
                        <div className={workdetail.btn_area}>
                            <button className={main.disabled_btn}>ご自身の作品には依頼できません</button>
                        </div>
                    )            
                }
            } else {
                return(
                    <div className={workdetail.btn_area}>
                        <button className={main.primary_btn} onClick={handleLoginModal}>{t('request_create')}</button>
                    </div>
                )    
            }
        } else {
            return(
                <div className={workdetail.btn_area}>
                    <button className={main.disabled_btn}>{t('request_create_disbaled')}</button>
                </div>
            )
        }
    }

    const requestUseButton = () => {
        if(work.requestUsePrice > 0){
            if(requestorId !== ""){
                if(requestorId !== creatorProfile.creatorId){
                    return(
                        <div className={workdetail.bottom_btn_area}>
                            <a href="#" className={main.primary_btn} onClick={handleDailogOpenForRequestUse}>{t('request_use')}</a>
                        </div>
                    )
                }else{
                    return(
                        <div className={workdetail.bottom_btn_area}>
                            <button className={main.disabled_btn}>ご自身の作品は購入できません</button>
                        </div>  
                    )  
                }
            }else{
                return(
                    <div className={workdetail.bottom_btn_area}>
                        <button className={main.primary_btn} onClick={handleLoginModal}>{t('request_use')}</button>
                    </div>
                )
            }
        }else{
            return(
                <div className={workdetail.bottom_btn_area}>
                    <button className={main.disabled_btn}>{t('request_use_disbaled')}</button>
                </div>
            )
        }
    }

    const addReuqestCreateEventLog = () =>{
        // 創作依頼のときは画面遷移なので、ログ足すだけ
        //イベントログ
        Logic.eventLogging(userInfo, eventCode.agreeRequestCreate, "", "");
    }



    // requestIdの生成
    //ランダム文字列の末尾につける日付
    var now = new Date();
    var timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString()+now.getSeconds().toString();
    var requestId = Math.random().toString(32).substring(2) + timestr;
    

    // 使用権の購入は、この後即購入なので、ここでrequestのrecordを作る
    const createReuqestUse = () => {
        //イベントログ
        Logic.eventLogging(userInfo, eventCode.agreeRequestUse, "", "");

        // 決済用
        const originalPrice = work.requestUsePrice;
        const businessFee = Math.ceil(originalPrice*feeRate.businessFee);
        const tax = Math.ceil(originalPrice*(1+feeRate.businessFee)*feeRate.tax);
        const paymentFee = Math.ceil(originalPrice*(1+feeRate.businessFee)*(1+feeRate.tax)*feeRate.paymentFee);

        if(dig(userInfo, "userInfo", "profileImageUrl")){
            if(dig(userInfo, "userInfo", "displayName")){
                if(dig(userInfo, "userInfo", "email")){
                    Api.createRequest(
                        requestorId,
                        requestId, 
                        creatorProfile.creatorId, 
                        requestStatus.waitPayment,
                        requestClass.existUse, 
                        "", 
                        "", 
                        "", 
                        "",
                        false, 
                        false, 
                        "",
                        userInfo.userInfo.langCode,
                        userInfo.userInfo.profileImageUrl,
                        creatorProfile.profileImageUrl,
                        userInfo.userInfo.displayName,
                        creatorProfile.displayName,
                        userInfo.userInfo.email,
                        creatorProfile.email,
                        workId,
                        work.imageUrl,
                        originalPrice, 
                        tax,
                        businessFee,
                        paymentFee,
                        false
                    );
                }
            }
        }
    }
    const countNumberComponent = () => {
        if(typeof work.likeNumber !== "undefined" && workViewCount > 0){
            return(
                <div className={workdetail.countnumber_area}>
                    <h3><FontAwesomeIcon icon={['far', 'chart-line']}/>&nbsp;{t('workdetail_countnumber_title')}</h3>
                    <div className={workdetail.countnumber_content}>
                        <FontAwesomeIcon icon={['fas', 'star']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>{work.likeNumber}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={['fas', 'eye']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>{workViewCount}</span>
                    </div>
                </div>
            )
        } else if(typeof work.likeNumber === "undefined" && workViewCount > 0){
            return(
                <div className={workdetail.countnumber_area}>
                    <h3><FontAwesomeIcon icon={['far', 'chart-line']}/>&nbsp;{t('workdetail_countnumber_title')}</h3>
                    <div className={workdetail.countnumber_content}>
                        <FontAwesomeIcon icon={['fas', 'star']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>??</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={['fas', 'eye']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>{workViewCount}</span>
                    </div>
                </div>
            )
        } else if(typeof work.likeNumber !== "undefined" && workViewCount === 0){
            return(
                <div className={workdetail.countnumber_area}>
                    <h3><FontAwesomeIcon icon={['far', 'chart-line']}/>&nbsp;{t('workdetail_countnumber_title')}</h3>
                    <div className={workdetail.countnumber_content}>
                        <FontAwesomeIcon icon={['fas', 'star']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>{work.likeNumber}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={['fas', 'eye']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>??</span>
                    </div>
                </div>
            )
        } else if(typeof work.likeNumber === "undefined" && workViewCount === 0){
            return(
                <div className={workdetail.countnumber_area}>
                    <h3><FontAwesomeIcon icon={['far', 'chart-line']}/>&nbsp;{t('workdetail_countnumber_title')}</h3>
                    <div className={workdetail.countnumber_content}>
                        <FontAwesomeIcon icon={['fas', 'star']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>??</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={['fas', 'eye']} className={workdetail.count_icon}/>
                        &nbsp;
                        <span className={workdetail.count_number}>??</span>
                    </div>
                </div>
            )
        } 
    }

    const workTitleComponent = () => {
        if(typeof work.title !== 'undefined'){
            if(work.title !== ""){
                if(langCode === "ja"){
                    return(
                        <div className={workdetail.title_area}>
                            <h3><FontAwesomeIcon icon={['far', 'tag']}/>&nbsp;{t('workdetail_work_title')}</h3>
                            <div className={workdetail.price}>
                                <p>
                                    {work.title}
                                </p>
                            </div>
                        </div>
                    )    
                }else{
                    if(typeof work.translated !== 'undefined'){
                        if(work.translated.en !==""){
                            return(
                                <div className={workdetail.title_area}>
                                    <h3><FontAwesomeIcon icon={['far', 'tag']}/>&nbsp;{t('workdetail_work_title')}</h3>
                                    <div className={workdetail.price}>
                                        <p>
                                            {work.translated.en}
                                        </p>
                                    </div>
                                </div>
                            )            
                        }else{
                            return(
                                <div className={workdetail.title_area}>
                                    <h3><FontAwesomeIcon icon={['far', 'tag']}/>&nbsp;{t('workdetail_work_title')}</h3>
                                    <div className={workdetail.price}>
                                        <p>
                                            {work.title}
                                        </p>
                                    </div>
                                </div>
                            )            
                        }
                    }
                }
            }else{
                return(
                    <div className={workdetail.title_area}>
                        <h3><FontAwesomeIcon icon={['far', 'tag']}/>&nbsp;{t('workdetail_work_title')}</h3>
                        <div className={workdetail.price}>
                            <p>
                                {t('workdetail_work_no_title')}
                            </p>
                        </div>
                    </div>
                )    
            }
        }else{
            return(
                <div className={workdetail.title_area}>
                    <h3><FontAwesomeIcon icon={['far', 'tag']}/>&nbsp;{t('workdetail_work_title')}</h3>
                    <div className={workdetail.price}>
                        <p>
                            {t('workdetail_work_no_title')}
                        </p>
                    </div>
                </div>
            )    
        }
    }

    const otherWorksOfCreatorComponent = () => {
        if(otherWorks.length > 0){
            return(
                <div className={workdetail.otherworks_area}>
                    <h3><FontAwesomeIcon icon={['far', 'search']}/>&nbsp;{t('workdetail_otherworks_title')}</h3>
                    <div>
                        <SinglSquareImageListForHorizontalScroll images={otherWorks}/>
                    </div>
                </div>
            )
        }
    }

    const popularWorksComponent = () => {
        return(
                <div className={workdetail.popularworks_area}>
                    <h3><FontAwesomeIcon icon={['far', 'star']}/>&nbsp;{t('workdetail_popularworks_title')}</h3>
                    <div>
                        <SinglSquareImageListForHorizontalScroll images={popularWorks}/>
                    </div>
                </div>
        )
    }

    const requestPriceComponent = (originalPrice) => {
        const businessFee = Math.ceil(originalPrice*feeRate.businessFee);
        const tax = Math.ceil(originalPrice*(1+feeRate.businessFee)*feeRate.tax);
        const paymentFee = Math.ceil(originalPrice*(1+feeRate.businessFee)*(1+feeRate.tax)*feeRate.paymentFee);
        const totalPrice = originalPrice + businessFee + tax + paymentFee;
        console.log("businessFee:"+ businessFee);
        console.log("tax:"+tax);
        console.log("paymentFee:"+paymentFee);
        console.log("totalPrice:"+totalPrice);

        if(originalPrice > 0){
            return(
                <div className={workdetail.price}>
                    <p>
                        {/* <FontAwesomeIcon icon={['far', 'yen-sign']}/> */}
                        {totalPrice}&nbsp;JPY&nbsp;<span>({t('workdetail_reuqest_all_included')})</span>
                    </p>
                </div>
            )
        }else{
            return(
                <div className={workdetail.price}>
                    <p>{t('workdetail_reuqestuse_price_undefined')}</p>
                </div>
            )
        }
    }


    const toRequestCreate = {
        pathname: '/requestcreate',
        search: '?creatorId='+creatorProfile.creatorId+'&workId='+workId,
    };
    const toRequestUse = {
        pathname: '/requestuse',
        search: '?requestId='+requestId,
    };
    const toCreatorDetail = {
        pathname: '/profile/'+creatorProfile.roomId,
    };

    return(
        <div>
            <GoBack />
            {/* <h2>{t('workdetail_title')}</h2> */}
            <div className={workdetail.item_detail}>
                <div className={main.work_img}>
                    <img src={work.imageUrl} alt=""></img>
                    {likeButton()}
                </div>
                {workTitleComponent()}
                {countNumberComponent()}
                <div className={profile.profile_wrap}>
                    <h3><FontAwesomeIcon icon={['far', 'paint-brush']}/>&nbsp;{t('workdetail_author_title')}</h3>
                    <div className={profile.profile_head}>
                        <div className={profile.profile_photo}>
                            <img src={creatorProfile.profileImageUrl} alt=""></img>
                        </div>
                        <Link to={toCreatorDetail} className={main.link_decoration}>
                            <div className={profile.profile_name}>
                                <p>{creatorProfile.displayName}</p>
                            </div>
                        </Link>
                        <div className={profile.profile_follow_area}>
                            {FollowButton()}
                        </div>
                    </div>
                    <div>
                        {intorductionComponent()}
                    </div>
                </div>
                
                <div className={workdetail.request_area}>
                    <h3><FontAwesomeIcon icon={['far', 'palette']}/>&nbsp;{t('workdetail_reuqestcreate_title')}</h3>
                    {requestPriceComponent(creatorProfile.requestCreatePrice)}
                    {requestCreateButton()}
                    {confirmationDialogComponentForRequestCreate()}
                </div>
                <div className={workdetail.request_area}>
                    <h3><FontAwesomeIcon icon={['far', 'credit-card']}/>&nbsp;{t('workdetail_reuqestuse_title')}</h3>
                    {requestPriceComponent(work.requestUsePrice)}
                    <div className={workdetail.caveat_before_purchase}>{t('workdetail_caveat_before_purchase')}</div>
                    {requestUseButton()}
                    {confirmationDialogComponentForRequestUse()}
                </div>
                {otherWorksOfCreatorComponent()}
                {popularWorksComponent()}
            </div>
            {loginModal()}
        </div>

    )
}

export default WorkDetail;