import React , { useState, useEffect, useContext } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";


import * as Api from "../service/api";
import * as Logic from "../service/logic";
import SinglSquareImageListForMainContents from "./SinglSquareImageListForMainContents";

import main from '../css/main.module.css';
import { Link, useLocation } from 'react-router-dom';
import { languageCode, queryParameterKeys, eventCode } from '../service/constants';

// import keyvisual_tokyo_joku_ja from '../img/keyvisual_tokyo_joku_ja.png';
// import keyvisual_tokyo_joku_en from '../img/keyvisual_tokyo_joku_en.png';
// import keyvisual_yu_inami_ja from '../img/keyvisual_yu_inami_ja.jpg'
// import keyvisual_yu_inami_en from '../img/keyvisual_yu_inami_en.jpg'


import keyvisual_yuinami_noletter from '../img/keyvisual_yuinami_noletter.png'
import keyvisual_tokyojoku_noletter from '../img/keyvisual_tokyojoku_noletter.png'
import keyvisual_poki_noletter from '../img/keyvisual_poki_noletter.png'
import keyvisual_ronzaemon_noletter from '../img/keyvisual_ronzaemon_noletter.jpg'

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


//言語切替
import { useTranslation } from 'react-i18next';

const MainContent = (props) => {

    const [t] = useTranslation();
    const userInfo = useContext(UserInfoContext);


    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAlldisplayed, setIsAlldisplayed] = useState(false);


    // inputタグに初期値を与えてないとエラーになる
    const [avoidErrorDummy, setAvoidErrorDummy] = useState("");

    let tmpAllWorksListForRandomized = [];
    
    const diplayLimitNumberForRandomized = 16;
    const diplayLimitNumberForLatestOrder = 16;

    const location = useLocation();
    let kv_path = "";
    let queryParams = {};
    queryParams = Logic.convertToAssociatvieArray(location);
    // for (let key in queryParams) {
    //     console.log('key_result:' + key + ' value_result:' + queryParams[key]);
    // }
    if(Object.keys(queryParams).length > 0){
        if(typeof queryParams[queryParameterKeys.kv_path] !== "undefined"){
            kv_path = queryParams[queryParameterKeys.kv_path]
        }
    }

    useEffect(()=>{
        let unmounted = false;
        fetch();
        props.updateKeyvisualPath(kv_path);
        return () => {
            unmounted = true
        }
    }, [props.onRecommendTab]);

    const fetch = async() =>{
        if(props.lastIndexForRandomized < diplayLimitNumberForRandomized){
            props.updateLastIndexForRandomized(diplayLimitNumberForRandomized);
        }
        if(props.lastIndexForLatestOrder < diplayLimitNumberForLatestOrder){
            props.updateLastIndexForLatestOrder(diplayLimitNumberForLatestOrder);
        }
        if(props.allWorksListRadomized.length === 0 && props.allWorksListLatestOrder.length === 0){
            const data = await Api.getWorks()
            tmpAllWorksListForRandomized = randomizedData(data);
            props.updateAllWorksListRandomized(tmpAllWorksListForRandomized);
            props.updateAllWorksListLatestOrder(data);
            if(props.onRecommendTab){
                if(props.lastIndexForRandomized > 0){
                    setRadmizedDataForDisplay(tmpAllWorksListForRandomized, props.lastIndexForRandomized);
                }else{
                    setRadmizedDataForDisplay(tmpAllWorksListForRandomized, diplayLimitNumberForRandomized);
                }
            }else{
                if(props.lastIndexForLatestOrder > 0){
                    setLatestOrderDataForDisplay(data, props.lastIndexForLatestOrder);
                }else{
                    setLatestOrderDataForDisplay(data, diplayLimitNumberForLatestOrder);
                }
            }
        }else{
            // 論理的には正しくないが、一方リストだけが空の状況があり得ないので、この分岐でいく
            if(props.onRecommendTab){
                if(props.lastIndexForRandomized > 0){
                    setRadmizedDataForDisplay(props.allWorksListRadomized, props.lastIndexForRandomized);
                }else{
                    setRadmizedDataForDisplay(props.allWorksListRadomized, diplayLimitNumberForRandomized);
                }
            }else{
                if(props.lastIndexForLatestOrder > 0){
                    setLatestOrderDataForDisplay(props.allWorksListLatestOrder, props.lastIndexForLatestOrder);
                }else{
                    setLatestOrderDataForDisplay(props.allWorksListLatestOrder, diplayLimitNumberForLatestOrder);
                }
            }
        }
    }

    const fetchMore = async() =>{
        setIsLoading(true);
        if(props.onRecommendTab){
            const tmpDisplayNumberForRandomized = props.lastIndexForRandomized+diplayLimitNumberForRandomized;    
            if(props.allWorksListRadomized.length > tmpDisplayNumberForRandomized){
                setRadmizedDataForDisplay(props.allWorksListRadomized, tmpDisplayNumberForRandomized);
                props.updateLastIndexForRandomized(tmpDisplayNumberForRandomized);
                setIsLoading(false);
            }else{
                setIsAlldisplayed(true);
            }
        }else{
            const tmpDisplayNumberLatestOrder = props.lastIndexForLatestOrder+diplayLimitNumberForLatestOrder;    
            if(props.allWorksListLatestOrder.length > tmpDisplayNumberLatestOrder){
                setLatestOrderDataForDisplay(props.allWorksListLatestOrder, tmpDisplayNumberLatestOrder);
                props.updateLastIndexForLatestOrder(tmpDisplayNumberLatestOrder);
                setIsLoading(false);
            }else{
                setIsAlldisplayed(true);
            }
        }
    }
    
    // 全件ランダマイズ
    const randomizedData = (array) =>{
        const cloneArray = [...array]

        for (let i = cloneArray.length - 1; i >= 0; i--) {
          let rand = Math.floor(Math.random() * (i + 1))
          // 配列の要素の順番を入れ替える
          let tmpStorage = cloneArray[i];
          cloneArray[i] = cloneArray[rand];
          cloneArray[rand] = tmpStorage;
        }

        return cloneArray;
    }

    const setRadmizedDataForDisplay = (allWorks, displayNumber) =>{
        const displayListForRandomized = allWorks.slice(0, displayNumber);
        setImages(displayListForRandomized);
    }
    
    const setLatestOrderDataForDisplay = (allWorks, displayNumber) =>{
        const displayListForLatestOrder = allWorks.slice(0, displayNumber);
        setImages(displayListForLatestOrder);
    }

    const switchTab = async () =>{
        props.updateOnRecommendTab(!props.onRecommendTab);
        //スイッチするたびに空にする。同じworkIdの作品が表示されてたらスクロールしてしまうため
        props.updateLastViewItemIndex("")
        setIsAlldisplayed(false);
        setIsLoading(false);
    }

    const seeMoreButtonComponent = () =>{
        if(isAlldisplayed){
            return(
                <div className={main.alreadydisplayed}>
                    No more works.
                </div>
            )
        }else{
            if(isLoading){
                return(
                    <div className={main.loading}>
                        Loading...
                    </div>
                )
            }else{
                return(
                    <div className={main.secondary_btn} onClick={fetchMore}>
                        {t('main_seemore_button')}
                    </div>
                )    
            }
        }
    }
    const switchSortComponent = () =>{
        if(props.onRecommendTab){
            return(
                <div className={main.tabs}>
                    <div>
                        <input id="recommend" type="radio" name="tab_item" value={avoidErrorDummy}/>
                        <label className={main.tab_item_recommend_on} htmlFor="recommend" onClick={switchTab}>{t('main_sort_tab_for_recommend')}</label>
                        <input id="newarrival" type="radio" name="tab_item" value={avoidErrorDummy}/>
                        <label className={main.tab_item_newarrival_off} htmlFor="newarrival" onClick={switchTab}>{t('main_sort_tab_for_newarrival')}</label>
                    </div>
                </div>
            )
        } else {
            return(
                <div className={main.tabs}>
                    <div>
                        <input id="recommend" type="radio" name="tab_item" value={avoidErrorDummy}/>
                        <label className={main.tab_item_recommend_off} htmlFor="recommend" onClick={switchTab}>{t('main_sort_tab_for_recommend')}</label>
                        <input id="newarrival" type="radio" name="tab_item" value={avoidErrorDummy}/>
                        <label className={main.tab_item_newarrival_on} htmlFor="newarrival" onClick={switchTab}>{t('main_sort_tab_for_newarrival')}</label>
                    </div>
                </div>
            )
        }

    }


    const toYuInamiProfile = {
        pathname: '/profile/yuuinami',
    };
    const toTokyoJokuProfile = {
        pathname: '/profile/tokyo_joku',
    };
    const toPokiProfile = {
        pathname: '/profile/Poki_Sasaki',
    };
    const toRonzaemonProfile = {
        pathname: '/profile/ronzaemon',
    };


    // max=3のとき、expected output: 0, 1 or 2
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const viewKeyvisualComponent = () =>{
        if(props.keyvisualPath !== ""){
            if(props.keyvisualPath.indexOf('tokyojoku') != -1){
                props.updateKeyvisualPath(keyvisual_tokyojoku_noletter);
                return(
                    <Link to={toTokyoJokuProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                                <img src={keyvisual_tokyojoku_noletter} alt="keyvisual_tokyojoku_noletter" />
                        </div>
                    </Link>
                )
            }else if(props.keyvisualPath.indexOf('yuinami') != -1){
                props.updateKeyvisualPath(keyvisual_yuinami_noletter);
                return(
                    <Link to={toYuInamiProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_yuinami_noletter} alt="keyvisual_yuinami_noletter" />
                        </div>
                    </Link>
                )
            } else if(props.keyvisualPath.indexOf('poki') != -1) {
                props.updateKeyvisualPath(keyvisual_poki_noletter);
                return(
                    <Link to={toPokiProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_poki_noletter} alt="keyvisual_poki_noletter" />
                        </div>
                    </Link>
                )
            } else {
                props.updateKeyvisualPath(keyvisual_ronzaemon_noletter);
                return(
                    <Link to={toRonzaemonProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_ronzaemon_noletter} alt="keyvisual_ronzaemon_noletter" />
                        </div>
                    </Link>
                )
            }
        }else{
            const max = 4;
            if(getRandomInt(max) === 0){
                props.updateKeyvisualPath(keyvisual_tokyojoku_noletter);
                return(
                    <Link to={toTokyoJokuProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_tokyojoku_noletter} alt="keyvisual_tokyojoku_noletter" />
                        </div>
                    </Link>
                )
            } else if(getRandomInt(max) === 1){
                props.updateKeyvisualPath(keyvisual_yuinami_noletter);
                return(
                    <Link to={toYuInamiProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_yuinami_noletter} alt="keyvisual_yuinami_noletter" />
                        </div>
                    </Link>
                )
            } else if(getRandomInt(max) === 2){
                props.updateKeyvisualPath(keyvisual_poki_noletter);
                return(
                    <Link to={toPokiProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_poki_noletter} alt="keyvisual_poki_noletter" />
                        </div>
                    </Link>
                )
            } else if(getRandomInt(max) === 3){
                props.updateKeyvisualPath(keyvisual_ronzaemon_noletter);
                return(
                    <Link to={toRonzaemonProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_ronzaemon_noletter} alt="keyvisual_ronzaemon_noletter" />
                        </div>
                    </Link>
                )
            } else {
                props.updateKeyvisualPath(keyvisual_yuinami_noletter);
                return(
                    <Link to={toYuInamiProfile} className={main.to_profile_link}>
                        <div className={main.keyvisual}>
                            <img src={keyvisual_yuinami_noletter} alt="keyvisual_yuinami_noletter" />
                        </div>
                    </Link>
                )
            }
        }
    }

    const viewExplainSerivce = () =>{
        return(
            <div>
                <h2>{t('main_about_service_title')}</h2>
                <div className={main.caveat}>
                    <div className={main.aboutservice}>
                        <div className={main.aboutservice_list}>
                            <ol>
                                <li className={main.aboutservice_list_content}>{t('main_about_service_senetnce1')}</li>
                                <li className={main.aboutservice_list_content}>{t('main_about_service_senetnce2')}</li>
                                <li className={main.aboutservice_list_content}>{t('main_about_service_senetnce3')}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const toHowToUse = {
        pathname: '/howtouse',
    };

    const viewExplainSerivceConcept = () =>{
        return(
            <div className={main.explain_service_section}>
                <br />
                <div className={main.explain_service_header}>
                    <h2>{t('main_about_service_concept_title')}</h2>
                </div>
                <div className={main.explain_service_content}>
                    <p>{t('main_about_service_concept_sentence1')}</p>
                    <p>{t('main_about_service_concept_sentence2')}</p>
                    <p>{t('main_about_service_concept_sentence3')}</p>
                </div>
                <div className={main.link_how_to_use}>
                    <Link to={toHowToUse} className={main.link_how_to_use_btn}>
                        {t('main_about_service_concept_link_to_how_to_use')}
                    </Link>
                </div>
            </div>
        )
    }

    const tapSearchCategory = () =>{
        Logic.eventLogging(userInfo, eventCode.tapSearchCategory, "", "");
    }

    return(
        <React.Fragment>
            {viewKeyvisualComponent()}
            {viewExplainSerivceConcept()}
            {/* {viewExplainSerivce()} */}
            <div className={main.main_content_header}>
                <div className={main.main_content_title}>
                    <h2>{t('main_content_title')}</h2>
                </div>
                {/* <div className={main.search_filter_button}>
                    <Link to='/selectcategory' onClick={tapSearchCategory}>
                        <FontAwesomeIcon icon={['far', 'search']} />
                        &nbsp;&nbsp;{t('main_filtering_by_category_button')}
                    </Link>
                </div> */}
            </div>
            <div className={main.filter_serach_button_section}>
                <ul className={main.line_button_display}>
                    <li className={main.line_button_display_item}>
                        <Link to='/creators'>
                            {t('main_filtering_by_creator_button')}
                        </Link>
                    </li>
                    <li className={main.line_button_display_item}>
                        <Link to='/selectcategory' onClick={tapSearchCategory}>
                            {t('main_filtering_by_category_button')}
                        </Link>
                    </li>
                </ul>
            </div>

            <div>
                {switchSortComponent()}
            </div>
            <div>
                {images.length > 0 ?
                    <SinglSquareImageListForMainContents 
                        images={images} 
                        updateLastViewItemIndex={props.updateLastViewItemIndex}
                        lastViewItemIndex={props.lastViewItemIndex}
                    />
                :
                    <div></div>
                }
            </div>
            {seeMoreButtonComponent()}
            <br />
        </React.Fragment>
    )
}

export default MainContent;