import React from "react";
//言語切替
import { useTranslation } from 'react-i18next';


function About() {
    const [t] = useTranslation();

    return(
        <div>
            <h2>{t('about_us_title')}</h2>
            <div>
                hogehoge
            </div>
        </div>
    )
}

export default About;