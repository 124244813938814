import React , { useEffect, useState, useContext } from "react";
import admin from '../css/admin.module.css';
import { AuthContext } from "../providers/AuthProvider";
import * as Api from "../service/api";
import dig from "object-dig";
import { adminUsers } from '../service/constants';
const moment = require('moment');





// リンクはどこにもない
// URLを直たたきし、かつ管理者アカウントの場合しか見えない
function AdminSalesManage() {
    const currentUser = useContext(AuthContext);
    const [userId, setUserId] = useState("");
    const [sendSalesList, setSendSalesList] = useState([]);
    //ランダム文字列の末尾につける日付
    var now = new Date();

    var startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    var endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    var endOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);
    
    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                if(currentUser.currentUser.uid === adminUsers.admin_userId){
                    setUserId(currentUser.currentUser.uid);
                    // 処理をここに書く
                    const data = await Api.getSendSalesListInThisMonth(startOfMonth, endOfMonth);
                    setSendSalesList(data);
                    Api.getSendSalesListInThisMonthGroupBy(startOfMonth, endOfMonth);
                }
            }
        }
        fetch();
    }, [currentUser]);  

    const totalAmount = (salesRow) => {
        const sum = Number(salesRow.originalPrice)+Number(salesRow.creatorTax);
        return(
            <div>{sum}</div>
        )
    }

    return(
        <div>
            <h2>月次売上管理</h2>
            {userId === adminUsers.admin_userId?
                <div>
                    <div>誰にいついくら振り込む必要があるかを確認できる画面です</div>
                    <div>
                    <table className={admin.table}>
                        <thead>
                            <tr className={admin.tr}>
                                <th>クリエイター名</th>
                                <th>振込人名義</th>
                                <th>銀行</th>
                                <th>種別</th>
                                <th>支店番号</th>
                                <th>口座番号</th>
                                <th>金額</th>
                                <th>振込日</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sendSalesList.map((salesRow) => {
                                return (
                                    <tr className={admin.tr} key={salesRow.id}>
                                        <td>{salesRow.creatorDisplayName}</td>
                                        <td>{salesRow.familyName}&nbsp;{salesRow.firstName}</td>
                                        <td>{salesRow.bankName}</td>
                                        <td>{salesRow.accountType}</td>
                                        <td>{salesRow.branchNumber}</td>
                                        <td>{salesRow.accountNumber}</td>
                                        <td>{totalAmount(salesRow)}</td>
                                        <td>{moment(endOfNextMonth).format('YYYY-MM-DD')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        </table>
                    </div>
                </div>
            :
            <div>
                このページを見る権限がありません。
            </div>
            }
        </div>
    )
}

export default AdminSalesManage;