import React , { useEffect, useState, useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import * as Api from "../service/api";
import dig from "object-dig";
import { useLocation } from 'react-router-dom';
import main from '../css/main.module.css';
import requestdetail from '../css/requestdetail.module.css';
import requestmanage from '../css/requestmanage.module.css';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RequestViewComponent from "./RequestViewComponent";
import { requestStatus, notificationType, serviceUrl, requestClass, feeRate } from '../service/constants';
import toast, { Toaster } from 'react-hot-toast';
import * as mail from "./SendEventTriggerMail";
import Checkout from "./Checkout";
import DeliverWork from "./DeliverWork";
import ConfirmDeliverWork from "./ConfirmDeliverWork";
import ReceiveDeliverWork from "./ReceiveDeliverWork";
import GoBack from "./GoBack";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

//言語切替
import { useTranslation } from 'react-i18next';


const moment = require('moment');


// リクエストキャンセル時の確認ポップアップのアニメーション
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});  


function RequestDetail(props) {
    const currentUser = useContext(AuthContext);

    const [t] = useTranslation();

    const [userId, setUserId] = useState("");
    const [requests, setRequests] = useState([]);
    const [request, setRequest] = useState({});
    const [acceptFlg, setAcceptFlg] = useState(false);
    const [deliverFlg, setDeliverFlg] = useState(false);
    const [confirmFlg, setConfirmFlg] = useState(false);



    const location = useLocation();
    let requestId = "";
    if(location.search !== ""){
        //あんまりよろしくないですが
        var urlParam = location.search.substring(1);
        requestId = urlParam.substring(urlParam.indexOf('=') + 1);
    }

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                setUserId(currentUser.currentUser.uid);
                const data = await Api.getRequest(requestId);
                if(data.length > 0){
                    // timestampが落ちるのでview用
                    setRequests(data);   

                    // マジでよくないけど、stripeからキャンセルで戻るときに、requestUseに返すか、reqesutCreate(requestdetail)に返すかを渡す必要がある
                    data[0].path = "requestdetail";

                    // backendの処理用
                    setRequest(data[0]); 
                }          
            }
        }
        fetch();
    }, [currentUser]);  


    const fixRequestAccept = () => {
        Api.updateRequestStatusWhenApproval(request.id, requestStatus.waitPayment, deliveryDate);
        Api.createNotificationForConsumer(
            request.requestId, 
            request.requestorId, 
            request.creatorId, 
            request.requestType, 
            notificationType.payment, 
            request.requestorDisplayName, 
            request.creatorDisplayName, 
            request.creatorProfileImageUrl
        );
        // 依頼者にメール
        let title = request.creatorDisplayName+"さんへの依頼が承認されました/"+"The creator accepted your request";
        let content = "▼Japanese\n(English is below)\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
                    +request.creatorDisplayName
                    +"さんへの依頼が承認されました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
                    +serviceUrl.urlToRequestDetail+"/?reuqestId="+request.requestId
                    +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-\n\n\n\n▼English\n\nThank you for always using artless.\n\n"
                    +request.creatorDisplayName
                    +" accepted your request.\nPlease access the website from this URL and check the details.\n\n"
                    +serviceUrl.urlToRequestDetail+"/?reuqestId="+request.requestId
                    +"\n\nIf you have any questions or don't know what this mail says, please contact us at the email address below.\n\nartless.gallery.contact@gmail.com\n\n—————————-\nAdministrator of artless\n—————————-"
                    ;

        mail.SendEventTriggerMail(request.requestorMailAddress, title, content) ;
    }

    const toAcceptThanks = {
        pathname: '/thanks',
        search: '?from=fixRequestAccpet',
    };

    const toCancel = {
        pathname: '/',
    };


    // 納期の管理
    const initialDate = new Date()
    const [deliveryDate, setDeliveryDate] = useState(initialDate)
    const handleChange = (date) => {
        setDeliveryDate(date)
    }


    const acceptConponent = () => {
        return(
            <div>
                <h2>リクエスト承諾確定画面</h2>
                <h3>納期の目安</h3>
                <DatePicker
                    selected={deliveryDate}
                    onChange={handleChange}
                />
                <div className={requestdetail.accept_btn_area}>
                    <Link to={toAcceptThanks} className={main.primary_btn} onClick={fixRequestAccept}>承諾を確定する</Link>
                </div>
                <div className={requestdetail.cancel_btn_area}>
                    <button className={main.tertiary_btn} onClick={() => setAcceptFlg(false)}>戻る</button>
                </div>
            </div>
        )
    }

    const statusComponet = () => {
        if(request.status === requestStatus.beforeConfirm){
            return(
                <div className={requestmanage.status_label_todo}>
                    <span>{t('request_detail_status_before_confirm')}</span>
                </div>
            );
        } else if (request.status === requestStatus.cancel){
            return(
                <div className={requestmanage.status_label_done}>
                    <span>{t('request_detail_status_cancel')}</span>
                </div>
            );
        } else if (request.status === requestStatus.waitPayment){
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>{t('request_detail_status_wait_payment')}</span>
                </div>
            );
        } else if (request.status === requestStatus.inCreating){
            return(
                <div className={requestmanage.status_label_todo}>
                    <span>{t('request_detail_status_in_creating')}</span>
                </div>
            );
        } else if (request.status === requestStatus.waitReceive){
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>{t('request_detail_status_wait_receiving')}</span>
                </div>
            );
        } else if (request.status === requestStatus.done){
            return(
                <div className={requestmanage.status_label_done}>
                    <span>{t('request_detail_status_done')}</span>
                </div>
            );
        } else {
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>Undefined</span>
                </div>
            );
        }
    }

    const requestorProfileComponet = () => {
        const originalPrice = request.originalPrice
        const businessFee = Math.ceil(originalPrice*feeRate.businessFee);
        const tax = Math.ceil(originalPrice*(1+feeRate.businessFee)*feeRate.tax);
        const paymentFee = Math.ceil(originalPrice*(1+feeRate.businessFee)*(1+feeRate.tax)*feeRate.paymentFee);
        const totalPrice = originalPrice + businessFee + tax + paymentFee;

        return(
            <div className={requestdetail.requestor_profile}>
                <div className={requestdetail.status_label_area}>
                    {statusComponet()}
                </div>
                <div>
                    <div className={requestdetail.price}>
                        {/* <FontAwesomeIcon icon={['far', 'yen-sign']}/> */}
                        {/* {request.originalPrice}&nbsp;JPY&nbsp;<span>({t('workdetail_reuqest_tax_excluded')})</span> */}
                        {totalPrice}&nbsp;JPY&nbsp;<span>({t('workdetail_reuqest_all_included')})</span>
                    </div>
                </div>
                <div>
                    <div className={requestdetail.profile_head}>
                        <div className={requestdetail.profile_photo}>
                            <img src={request.requestorProfileImageUrl} alt=""></img>
                        </div>
                        <div className={requestdetail.profile_name}>
                            <p>{request.requestorDisplayName}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //リクエストキャンセル時の確認ポップアップ
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    
    const notify = () => toast.success('依頼をお断りしました',
                                {
                                    style: {
                                        border: '1px solid #94ABFF',
                                        borderRadius: '10px',
                                        background: '#ffffff',
                                        color: '#94ABFF',
                                    },
                                duration: 2000,
                                }
    );

    const history = useHistory();

    const cancelRequest = () => {
        Api.updateRequestStatusOnlyStatus(request.id, requestStatus.cancel);
        Api.createNotificationForConsumer(
            request.requestId, 
            request.requestorId, 
            request.creatorId, 
            request.requestType, 
            notificationType.cancel, 
            request.requestorDisplayName, 
            request.creatorDisplayName, 
            request.creatorProfileImageUrl
        ); 
        notify();
        history.push('/');
    };

    const toSalesManage = {
        pathname: '/salesmanage',
    };



    // onClickで、フォームのコンポーネントを出し分ける
    // 画面遷移すると、都度DBを引くハメになるため
    return(
        <div>
            <Toaster />
            <GoBack />
            <h2>{t('request_detail_title')}</h2>
            {
                (() => {
                    if(request.status === requestStatus.beforeConfirm){
                        if(userId === request.creatorId){
                            return(
                                <div>
                                    <div className={requestdetail.caveat}>
                                        <h4>依頼内容のご確認をお願いします</h4>
                                        <p>
                                            {request.requestorDisplayName}さんから依頼がありました。依頼内容をご確認の上、承諾もしくはキャンセルをお願いします。
                                        </p>
                                    </div>
                                    <div>
                                        {!acceptFlg
                                        ?
                                            <div>
                                                <div className={requestdetail.accept_btn_area}>
                                                    <button className={main.primary_btn} onClick={() => setAcceptFlg(true)}>承諾したいので、納品期限を入力する</button>
                                                </div>
                                                <div className={requestdetail.cancel_btn_area}>
                                                    <button className={main.tertiary_btn} onClick={handleClickOpen}>キャンセルする</button>
                                                </div>
                                                <div>
                                                    <Dialog
                                                        open={open}
                                                        TransitionComponent={Transition}
                                                        keepMounted
                                                        onClose={handleClose}
                                                        aria-describedby="alert-dialog-slide-description"
                                                    >
                                                        <DialogTitle>{"キャンセルの確認"}</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-slide-description">
                                                                依頼をキャンセルすると、あとで再度受けつるけることはできません。
                                                                本当にキャンセルしますか？
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleClose}>やめる</Button>
                                                            <Link to={toCancel} className={main.tertiary_btn} onClick={() => cancelRequest()}>確認の上、キャンセルする</Link>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                            </div>
                                        :
                                            <div>
                                                {acceptConponent()}
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        } else if(userId === request.requestorId) {
                            return (
                                <div className={requestdetail.caveat}>
                                    <h4>{t('request_detail_caveat_title_before_confirm_for_requestor')}</h4>
                                    <p>{t('request_detail_caveat_text_before_confirm_for_requestor')}</p>
                                </div>
                            )
                        } else {
                            if(userId !== ""){
                                //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                return (
                                    <div className={requestdetail.caveat}>
                                        <p>{t('request_detail_no_result')}</p>
                                    </div>
                                )
                            } else {
                                // ログインしてない状態で来たらログインを促す
                                return (
                                    <div className={requestdetail.caveat}>
                                        <p>{t('request_detail_need_login')}</p>
                                    </div>
                                )
                            }
                        }

                    } else if (request.status === requestStatus.waitPayment ){
                        if(request.requestType !== requestClass.existUse){
                            if(userId === request.creatorId){
                                return(
                                    <div className={requestdetail.caveat}>
                                        <h4>しばらくお待ちください</h4>
                                        <p>現在依頼者の支払い待ちです。支払いが完了しましたら、メールでお知らせしますので、しばらくお待ち下さい。</p>
                                    </div>
                                )
                            } else if(userId === request.requestorId) {
                                return(
                                    <React.Fragment>
                                        <div className={requestdetail.caveat}>
                                            <h4>{t('request_detail_caveat_title_wait_payment_for_requestor')}</h4>
                                            <p>{t('request_detail_caveat_text_wait_payment_for_requestor')}</p>
                                        </div>
                                        <div>
                                            <h3>{t('request_detail_payment_title_for_requestor')}</h3>
                                                <div className={requestdetail.accept_btn_area}>
                                                    <Checkout request={request}/>
                                                </div>
                                        </div>
                                    </React.Fragment>
                                )    
                            } else {
                                if(userId !== ""){
                                    //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_no_result')}</p>
                                        </div>
                                    )
                                } else {
                                    // ログインしてない状態で来たらログインを促す
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_need_login')}</p>
                                        </div>
                                    )
                                }
                            }
                        } else {
                            // 使用権購入時のDetail
                            if(userId === request.creatorId){
                                return(
                                    <div className={requestdetail.caveat}>
                                        <h4>データの購入依頼がありました</h4>
                                        <p>依頼者から使用権購入リクエストがありました。現在依頼者の支払い待ちです。メールでお知らせしますので、しばらくお待ち下さい。</p>
                                    </div>
                                )
                            } else if(userId === request.requestorId) {
                                return(
                                    <React.Fragment>
                                        <div className={requestdetail.caveat}>
                                            <h4>{t('request_detail_caveat_title_wait_payment_for_requestor')}</h4>
                                            <p>{t('request_detail_use_caveat_text_wait_payment_for_requestor')}</p>
                                        </div>
                                        <div>
                                            <h3>{t('request_detail_payment_title_for_requestor')}</h3>
                                                <div className={requestdetail.accept_btn_area}>
                                                    <Checkout request={request}/>
                                                </div>
                                        </div>
                                    </React.Fragment>
                                )    
                            } else {
                                //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                return (
                                    <div className={requestdetail.caveat}>
                                        <p>{t('request_detail_no_result')}</p>
                                    </div>
                                )
                            }
                        }
                    } else if (request.status === requestStatus.inCreating ){
                        if(request.requestType !== requestClass.existUse){
                            if(userId === request.creatorId){
                                return(
                                    <div>
                                        {!deliverFlg?
                                        <React.Fragment>
                                            <div className={requestdetail.caveat}>
                                                <h4>創作を開始してください</h4>
                                                <p>{request.requestorDisplayName}さんからのお支払いが完了しました。創作を開始してください。</p>
                                                <p>創作が完了後、納品をお願いします。</p>
                                            </div>
                                            <div>
                                                <div className={requestdetail.accept_btn_area}>
                                                    <button className={main.primary_btn} onClick={() => setDeliverFlg(true)}>納品する</button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <div>
                                            <DeliverWork request={request}/>
                                        </div>
                                        }
                                    </div>
                                )
                            } else if(userId === request.requestorId) {
                                return(
                                    <div className={requestdetail.caveat}>
                                        <h4>{t('request_detail_caveat_title_in_creating_for_requestor')}</h4>
                                        <p>{t('request_detail_caveat_text_in_creating_for_requestor')}</p>
                                        <br />
                                        <p>{t('request_detail_caveat_deliverdate_in_creating_for_requestor')}:&nbsp;{moment(request.deliveryDate.seconds * 1000).format('YYYY-MM-DD')}</p>
                                    </div>
                                )    
                            } else {
                                if(userId !== ""){
                                    //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_no_result')}</p>
                                        </div>
                                    )
                                } else {
                                    // ログインしてない状態で来たらログインを促す
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_need_login')}</p>
                                        </div>
                                    )
                                }
                            }
                        } else {
                            if(userId === request.creatorId){
                                return(
                                    <div>
                                        {!deliverFlg?
                                        <React.Fragment>
                                            <div className={requestdetail.caveat}>
                                                <h4>作品のアップロードをお願いします</h4>
                                                <p>{request.requestorDisplayName}さんからのお支払いが完了しました。該当の作品をアップロードしてください。</p>
                                            </div>
                                            <div>
                                                <div className={requestdetail.accept_btn_area}>
                                                    <button className={main.primary_btn} onClick={() => setDeliverFlg(true)}>納品する</button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <div>
                                            <DeliverWork request={request}/>
                                        </div>
                                        }
                                    </div>
                                )
                            } else if(userId === request.requestorId) {
                                return(
                                    <div className={requestdetail.caveat}>
                                        <h4>{t('request_detail_use_caveat_title_wait_deliver_for_requestor')}</h4>
                                        <p>{t('request_detail_use_caveat_text_wait_deliver_for_requestor')}</p>
                                    </div>
                                )    
                            } else {
                                if(userId !== ""){
                                    //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_no_result')}</p>
                                        </div>
                                    )
                                } else {
                                    // ログインしてない状態で来たらログインを促す
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_need_login')}</p>
                                        </div>
                                    )
                                }
                            }
                        }
                    } else if (request.status === requestStatus.waitReceive ){
                        if(request.requestType !== requestClass.existUse){
                            if(userId === request.creatorId){
                                return(
                                    <div>
                                        {!confirmFlg?
                                        <React.Fragment>
                                            <div className={requestdetail.caveat}>
                                                <h4>依頼者の受け取り待ちです</h4>
                                                <p>創作〜納品、おつかれさまでした！</p>
                                                <p>依頼者の受け取りをお待ちください。受け取り完了しましたら、依頼は完了になります。</p>
                                            </div>
                                            <div>
                                                <div className={requestdetail.accept_btn_area}>
                                                    <button className={main.primary_btn} onClick={() => setConfirmFlg(true)}>納品した作品を確認する</button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <div>
                                            <ConfirmDeliverWork request={request}/>
                                        </div>
                                        }
                                    </div>
                                )
                            } else if(userId === request.requestorId) {
                                return(
                                    <React.Fragment>
                                        <div className={requestdetail.caveat}>
                                            <h4>{t('request_detail_caveat_title_delivered_for_requestor')}</h4>
                                            <p>{t('request_detail_caveat_text_delivered_for_requestor')}</p>
                                        </div>
                                        <div>
                                            <ReceiveDeliverWork request={request}/>
                                        </div>
                                    </React.Fragment>
                                )    
                            } else {
                                if(userId !== ""){
                                    //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_no_result')}</p>
                                        </div>
                                    )
                                } else {
                                    // ログインしてない状態で来たらログインを促す
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_need_login')}</p>
                                        </div>
                                    )
                                }
                            }
                        } else {
                            if(userId === request.creatorId){
                                return(
                                    <div>
                                        {!confirmFlg?
                                        <React.Fragment>
                                            <div className={requestdetail.caveat}>
                                                <h4>依頼者の受け取り待ちです</h4>
                                                <p>納品、おつかれさまでした！</p>
                                                <p>依頼者の受け取りをお待ちください。受け取り完了しましたら、依頼は完了になります。</p>
                                            </div>
                                            <div>
                                                <div className={requestdetail.accept_btn_area}>
                                                    <button className={main.primary_btn} onClick={() => setConfirmFlg(true)}>納品した作品を確認する</button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <div>
                                            <ConfirmDeliverWork request={request}/>
                                        </div>
                                        }
                                    </div>
                                )
                            } else if(userId === request.requestorId) {
                                return(
                                    <React.Fragment>
                                        <div className={requestdetail.caveat}>
                                            <h4>{t('request_detail_use_caveat_title_delivered_for_requestor')}</h4>
                                            <p>{t('request_detail_use_caveat_text_delivered_for_requestor')}</p>
                                        </div>
                                        <div>
                                            <ReceiveDeliverWork request={request}/>
                                        </div>
                                    </React.Fragment>
                                )    
                            } else {
                                if(userId !== ""){
                                    //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_no_result')}</p>
                                        </div>
                                    )
                                } else {
                                    // ログインしてない状態で来たらログインを促す
                                    return (
                                        <div className={requestdetail.caveat}>
                                            <p>{t('request_detail_need_login')}</p>
                                        </div>
                                    )
                                }
                            }    
                        }
                    } else if (request.status === requestStatus.done ){
                        if(userId === request.creatorId){
                            return(
                                <div>
                                    {!confirmFlg?
                                    <React.Fragment>
                                        <div className={requestdetail.caveat}>
                                            <h4>依頼者の受け取りが完了しました</h4>
                                            <p>納品、おつかれさまでした！</p>
                                            <p>依頼者の受け取りが完了しました。</p>
                                            <p>代金の振り込みの日付は
                                                <Link to={toSalesManage}>こちら</Link>
                                                でご確認ください。
                                            </p>
                                        </div>
                                        <div>
                                            <div className={requestdetail.accept_btn_area}>
                                                <button className={main.primary_btn} onClick={() => setConfirmFlg(true)}>納品した作品を確認する</button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div>
                                        <ConfirmDeliverWork request={request}/>
                                    </div>
                                    }
                                </div>
                            )
                        } else if(userId === request.requestorId) {
                            return(
                                <React.Fragment>
                                    <div className={requestdetail.caveat}>
                                        <h4>{t('request_detail_caveat_title_done_for_requestor')}</h4>
                                        <p>{t('request_detail_caveat_text_done_for_requestor')}</p>
                                    </div>
                                    <div>
                                        <ReceiveDeliverWork request={request}/>
                                    </div>
                                </React.Fragment>
                            )    
                        } else {
                            if(userId !== ""){
                                //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                return (
                                    <div className={requestdetail.caveat}>
                                        <p>{t('request_detail_no_result')}</p>
                                    </div>
                                )
                            } else {
                                // ログインしてない状態で来たらログインを促す
                                return (
                                    <div className={requestdetail.caveat}>
                                        <p>{t('request_detail_need_login')}</p>
                                    </div>
                                )
                            }
                        }
                    } else if (request.status === requestStatus.cancel ){
                        if(userId === request.creatorId){
                            return(
                                <div>
                                    <div className={requestdetail.caveat}>
                                        <h4>この依頼はキャンセル済みです</h4>
                                        <p>この依頼はキャンセル済みされました。</p>
                                    </div>
                                </div>
                            )
                        } else if(userId === request.requestorId) {
                            return(
                                <React.Fragment>
                                    <div className={requestdetail.caveat}>
                                        <h4>{t('request_detail_caveat_title_cancel_for_requestor')}</h4>
                                        <p>{t('request_detail_caveat_text_cancel_for_requestor')}</p>
                                    </div>
                                </React.Fragment>
                            )    
                        } else {
                            if(userId !== ""){
                                //クリエイターでも依頼者でもない人が来た場合は、該当のリクエストは存在しないかリダイレクト？
                                return (
                                    <div className={requestdetail.caveat}>
                                        <p>{t('request_detail_no_result')}</p>
                                    </div>
                                )
                            } else {
                                // ログインしてない状態で来たらログインを促す
                                return (
                                    <div className={requestdetail.caveat}>
                                        <p>{t('request_detail_need_login')}</p>
                                    </div>
                                )
                            }
                        }
                    } else {
                        <div className={requestdetail.caveat}>
                            <p>{t('request_detail_no_result')}</p>
                        </div>
                    }
                })()
            }
            <br />
            {requestorProfileComponet()}
            <div>
                {/* requestかuseかはこの中で分ければいい */}
                {userId === request.creatorId || userId === request.requestorId ?
                <RequestViewComponent requests={requests}></RequestViewComponent>
                :
                <div>
                </div>  
                }
            </div>  
        </div>
    )
}

export default RequestDetail;