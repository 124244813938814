import React , { useState, useEffect, useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { Link } from 'react-router-dom';
import * as Api from "../service/api";
// import * as cons from "../service/constants";
import dig from "object-dig";
import requestmanage from '../css/requestmanage.module.css';
import { requestStatus, requestClass } from '../service/constants';
import GoBackMarginLess from "./GoBackMarginLess";


//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const moment = require('moment');




function RequestManage() {
    const currentUser = useContext(AuthContext);
    const [requests, setRequests] = useState([]);

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                const data = await Api.getRequestsByCreatorId(currentUser.currentUser.uid);
                setRequests(data);
            }
        }
        fetch();
    }, [currentUser]);

    const statusComponet = (request) => {
        let statusLabel = "";
        if(request.status === requestStatus.beforeConfirm){
            statusLabel="確認前";
            return(
                <div className={requestmanage.status_label_todo}>
                    <span>{statusLabel}</span>
                </div>
            );
        } else if (request.status === requestStatus.cancel){
            statusLabel="キャンセル";
            return(
                <div className={requestmanage.status_label_done}>
                    <span>{statusLabel}</span>
                </div>
            );
        } else if (request.status === requestStatus.waitPayment){
            statusLabel="支払い待ち";
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>{statusLabel}</span>
                </div>
            );
        } else if (request.status === requestStatus.inCreating){
            statusLabel="準備中"
            return(
                <div className={requestmanage.status_label_todo}>
                    <span>{statusLabel}</span>
                </div>
            );
        } else if (request.status === requestStatus.waitReceive){
            statusLabel="受け取り待ち";
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>{statusLabel}</span>
                </div>
            );
        } else if (request.status === requestStatus.done){
            statusLabel="完了"
            return(
                <div className={requestmanage.status_label_done}>
                    <span>{statusLabel}</span>
                </div>
            );
        } else {
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>undefined</span>
                </div>
            );
        }
    }

    const reuqestViewList = requests.map((request) => {
        return (
            <div key={request.id}>
                {/* 購入手続きの途中でユーザーがやめたときのrequestは見せない */}
                {request.status === requestStatus.waitPayment && request.requestType === requestClass.existUse?
                <React.Fragment></React.Fragment>
                :
                <div className={requestmanage.item}>
                    <Link to={"/requestdetail?reuqestId="+request.requestId} className={requestmanage.linktag}>
                        <div className={requestmanage.flex}>
                            <div className={requestmanage.requstorImage}>
                                <img src={request.requestorProfileImageUrl} alt="" />
                            </div>
                            <div className={requestmanage.content}>
                                <div>{request.requestorDisplayName}</div>
                                <div className={requestmanage.date}>
                                    {moment(request.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                </div>
                            </div>
                            <div>
                                {statusComponet(request)}
                            </div>
                            <div className={requestmanage.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                    </Link>
                </div>
                }
            </div>
        );
    });
    

    return(
        <div className={requestmanage.purchase_history_wrap}>
            <GoBackMarginLess />
            <div className={requestmanage.purchase_history_header}>
                <div className={requestmanage.purchase_history_header_child}>
                    <h2>受注依頼管理</h2>
                </div>
                <div className={requestmanage.to_contact}>
                    <Link to="/contact">事務局に問い合わせ</Link>
                </div>
            </div>  
            <div>
                {reuqestViewList}
            </div>
        </div>
    )
}

export default RequestManage;