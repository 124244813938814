import React from "react";
import ourstory from '../css/ourstory.module.css';
import ourstorylogo from '../img/ourstory.png';


//言語切替
import { useTranslation } from 'react-i18next';


function OurStory() {
    const [t] = useTranslation();

    return(
        <div className={ourstory.section}>
            <div>
                <div className={ourstory.top_logo_area_img}>
                    <img src={ourstorylogo} alt="logo"/>
                </div>
            </div>
            <h2 className={ourstory.title}>{t('ourstory_title')}</h2>
            <div className={ourstory.body}>
                <p>{t('ourstory_body_paragraph_1')}</p>
                <p>{t('ourstory_body_paragraph_2')}</p>
                <br />
                <p>{t('ourstory_body_paragraph_3')}</p>
                <p>{t('ourstory_body_paragraph_4')}</p>
                <p>{t('ourstory_body_paragraph_5')}</p>
                <br />
                <p>{t('ourstory_body_paragraph_6')}</p>
                <p>{t('ourstory_body_paragraph_7')}</p>
                <br />
                <p>{t('ourstory_body_paragraph_8')}</p>
                <p>{t('ourstory_body_paragraph_9')}</p>
                <p>{t('ourstory_body_paragraph_10')}</p>
                <br />
                <p>{t('ourstory_body_paragraph_11')}</p>
                <br />
                <p>{t('ourstory_body_paragraph_12')}</p>
            </div>
        </div>
    )
}

export default OurStory;