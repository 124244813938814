import React , { useEffect, useState, useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import * as Api from "../service/api";
import dig from "object-dig";
import { Link } from 'react-router-dom';
import salesmanage from '../css/salesmanage.module.css';
import { feeRate } from '../service/constants';
import GoBackMarginLess from "./GoBackMarginLess";


const moment = require('moment');






function SalesManage() {
    const currentUser = useContext(AuthContext);
    const [salesList, setSalesList] = useState([]);

    //ランダム文字列の末尾につける日付
    var now = new Date();

    var startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    var endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    var endOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);
        
    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                const data = await Api.getSalesByUser(currentUser.currentUser.uid, startOfMonth, endOfMonth);                
                setSalesList(data);
            }
        }
        fetch();
    }, [currentUser]);  

    const totalAmount = (salesRow) => {
        const sum = Number(salesRow.originalPrice)+Number(salesRow.originalPrice*feeRate.tax);
        return(
            <div>{sum}</div>
        )
    }

    
    return(
        <div>
            <GoBackMarginLess />
            <div className={salesmanage.purchase_history_header}>
                <div className={salesmanage.purchase_history_header_child}>
                    <h2>売上管理</h2>
                </div>
                <div className={salesmanage.to_contact}>
                        <Link to="/contact">事務局に問い合わせ</Link>
                </div>
            </div>  
            <div>
                <div></div>
                    <div>
                    <table className={salesmanage.table}>
                        <thead>
                            <tr className={salesmanage.tr}>
                                <th>依頼完了日</th>
                                <th>依頼者名</th>
                                <th>金額</th>
                                <th>振込日</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salesList.map((salesRow) => {
                                return (                                   
                                    
                                        <tr className={salesmanage.tr} key={salesRow.id}>
                                            <td>
                                                <Link to={"/requestdetail?reuqestId="+salesRow.requestId} className={salesmanage.link}>
                                                    {moment(salesRow.updatedAt.seconds*1000).format('YYYY-MM-DD')}
                                                </Link>
                                            </td>
                                            <td>{salesRow.requestorDisplayName}</td>
                                            <td>{totalAmount(salesRow)}</td>
                                            <td>{moment(endOfNextMonth).format('YYYY-MM-DD')}</td>
                                        </tr>
                                );
                            })}
                        </tbody>
                        </table>
                    </div>
                </div>
        </div>
    )
}

export default SalesManage;