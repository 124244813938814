import React , { useState, useContext, useEffect, useRef} from "react";
import { AuthContext } from "../providers/AuthProvider";
import { UserInfoContext } from "../providers/UserInfoProvider";
import firebase, { storage } from "../service/firebase";
import * as Api from "../service/api";
import * as Validation from "../service/validation";

import dig from "object-dig";
import { useLocation, useHistory } from 'react-router-dom';
import main from '../css/main.module.css';
import myaccount from '../css/myaccount.module.css';
import TextField from '@material-ui/core/TextField';
import { DragSwitch } from 'react-dragswitch'
import 'react-dragswitch/dist/index.css'
import toast, { Toaster } from 'react-hot-toast';
import GoBack from "./GoBack";
import loadImage from 'blueimp-load-image';



//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//言語切替
import { useTranslation } from 'react-i18next';



function MyAccount() {
    const currentUser = useContext(AuthContext);
    const userInfo = useContext(UserInfoContext);

    const [t] = useTranslation();

    const history = useHistory();

    const [profileImage, setProfileImage] = useState("");
    const [profileImagePreviewUrl, setProfileImagePreviewUrl] = useState("");
    let randomProfileImageName = "";

    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    const [roomId, setRoomId] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [introduction, setIntroduction] = useState("");
    const [twitterUrl, setTwitterUrl] = useState("");
    const [instagramUrl, setInstagramUrl] = useState("");
    const [requestCreatePrice, setRequestCreatePrice] = useState(0);
    const [isRequestAcceptable, setIsRequestAcceptable] = useState(true);
    const [userDocId, setUserDocId] = useState("");
    const [isCreator, setIsCreator] = useState(false);
    const [langCode, setLangCode] = useState("");
    const [isFirstRoomIdUpdate, setIsFirstRoomIdUpdate] = useState(true);

    // validation
    const [isValidRoomId, setIsValidRoomId] = useState(true);
    const [isValidPrice, setIsValidPrice] = useState(true);

    // Room IDの重複チェックフラグ
    const [isAlreadyExistRoomId, setIsAlreadyExistRoomId] = useState(false);


    // どこから来たか確認
    const location = useLocation();

    let from = "";
    if(location.search !== ""){
        var queryString = location.search.substring(1);
        from = queryString.substring(queryString.indexOf('=') + 1);
    }

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(userInfo, "userInfo", "uid")){       
                setRoomId(userInfo.userInfo.roomId);
                setDisplayName(userInfo.userInfo.displayName);
                setIntroduction(userInfo.userInfo.introductionJapanese);
                setRequestCreatePrice(userInfo.userInfo.requestCreatePrice);
                setIsRequestAcceptable(userInfo.userInfo.isRequestAcceptable);
                setProfileImagePreviewUrl(userInfo.userInfo.profileImageUrl);
                setUserDocId(userInfo.userInfo.docId);
                setIsCreator(userInfo.userInfo.isCreator);    
                setLangCode(userInfo.userInfo.langCode);  
                setIsFirstRoomIdUpdate(userInfo.userInfo.isFirstRoomIdUpdate);  
                if(typeof userInfo.userInfo.twitterUrl !== 'undefined'){
                    setTwitterUrl(userInfo.userInfo.twitterUrl);
                }
                if(typeof userInfo.userInfo.instagramUrl !== 'undefined'){
                    setInstagramUrl(userInfo.userInfo.instagramUrl);
                }
            }                        
        } 
        fetch();
    },[userInfo]);  

    // validation check
    const checkInputRoomId = async (value) =>{
        // 通常のバリデーション
        var isValid = Validation.checkValidRoomId(value);
        setIsValidRoomId(isValid);
        
        // 重複チェック(空じゃないときだけ)
        if(value !== ""){
            const flg = await Api.isAlreadyExistRoomId(value);
            setIsAlreadyExistRoomId(flg);
        }
    }

    const checkInputPrice = (value) =>{
        var isValid = Validation.checkValidPrice(value);
        setIsValidPrice(isValid);
    }    

    // 先にアカウントのProfile写真を更新してたら、それを先に出す?
    // creatorの写真と個人アカウントの写真は別でもいいかも
    const handleProfileImage = event => {
        const image = event.target.files[0];
        setProfileImage(image);
        setProfileImagePreviewUrl(createObjectURL(image));
    };

    const toReturn = {
        pathname: '/myaccount',
    };

    const toWorkRegister = {
        pathname: '/workregister',
        //Steo 2/4以降が画面が使いまわしになるので、初期登録の動線から来た場合は明示する
        search: '?from=initialregister&workId=',
    };

    const submitComponent = () =>{
        if(from === "initialregister"){
            if(isValidPrice && isValidRoomId && !isAlreadyExistRoomId){
                return(
                    <button variant="contained" color="primary" type="submit" className={main.primary_btn} >
                        {t('myaccount_submit_initialregister')}
                    </button>
                )
            } else {
                return(
                    <div className={main.disabled_btn} >
                        {t('myaccount_submit_initialregister')}
                    </div>
                )
            }
        } else {
            if(isValidPrice && isValidRoomId && !isAlreadyExistRoomId){
                return(
                    <button variant="contained" color="primary" type="submit" className={main.primary_btn} >
                        {t('myaccount_submit')}
                    </button>
                )
            } else {
                return(
                    <div className={main.disabled_btn} >
                        {t('myaccount_submit')}
                    </div>
                )
            }
        }
    }

    const successMessage = langCode === "ja" ? "プロフィールの更新が完了しました":"Update Successfully";
    const notifyUpdate = () => toast.success(successMessage,
        {
            style: {
                border: '1px solid #94ABFF',
                borderRadius: '10px',
                background: '#ffffff',
                color: '#94ABFF',
            },
        duration: 2000,
        }
    );

    const processing = useRef(false);

    const createOrUpdateProfile = async (event) => {
        event.preventDefault();

        //処理中(true)なら処理せずに抜ける
        if (processing.current) {
            return
        };
        
        //処理中フラグを上げる
        processing.current = true;
        
        
        if (profileImage === "") {
            // 画像はアップロードしない
            console.log("ファイルが選択されていません");
            completeProfile();
        } else {
            if(dig(currentUser,"currentUser", "uid")){
                //ランダム文字列の末尾につける日付
                var now = new Date();
                var timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString();
        
                // アップロード前にファイル名をランダム文字列に書き換える
                // 同じファイル名のファイルはcloudstorageが受け付けない
                // あともし同じ文字列があった場合に再度ランダマイズ？
                randomProfileImageName = Math.random().toString(32).substring(2)+timestr;

                // プロフィール画像のアップロード処理
                const canvas = await loadImage(profileImage, {
                    maxWidth: 1200,
                    canvas: true,
                });
                canvas.image.toBlob((blob) => {
                    const uploadProfTask = storage.ref(`/images/${randomProfileImageName}`).put(blob);
                    uploadProfTask.on(
                        firebase.storage.TaskEvent.STATE_CHANGED,
                        next,
                        error,
                        completeProfile
                        );        
                }, profileImage.type);

        
                // プロフィール画像のアップロード処理
                // const uploadProfTask = storage.ref(`/images/${randomProfileImageName}`).put(profileImage);
                // uploadProfTask.on(
                // firebase.storage.TaskEvent.STATE_CHANGED,
                // next,
                // error,
                // completeProfile
                // );
            }else{
                console.log("uidがないため、失敗");
            }
        }
        //処理中フラグを下げる
        processing.current = false;
        
        // ここで遷移処理
        if(from === "initialregister"){
            history.push(toWorkRegister);
        }else{
            history.push(toReturn);
        }
    };
    const next = snapshot => {
        // 進行中のsnapshotを得る
        // アップロードの進行度を表示
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        console.log(snapshot);
    };
    const error = error => {
        // エラーハンドリング
        console.log(error);
    };
    const completeProfile = async () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        let profUrl = "";
        if(profileImage !== ""){
            profUrl = await storage.ref("images").child(randomProfileImageName).getDownloadURL();
        }
        // Api呼び出し
        if(userDocId !== "" && langCode !== ""){
            Api.addOrUpdateUserProfile(userDocId, displayName, introduction, requestCreatePrice, isRequestAcceptable, profUrl, langCode, roomId, false, twitterUrl, instagramUrl);
            notifyUpdate();
        }else{
            //この2つが空のときは何も起こらない
        }
        // これが許されんのか知らんが、、、
        userInfo.userInfo.roomId = roomId;
        userInfo.userInfo.displayName = displayName;
        userInfo.userInfo.introductionJapanese = introduction;
        userInfo.userInfo.requestCreatePrice = requestCreatePrice;
        userInfo.userInfo.isRequestAcceptable = isRequestAcceptable;
        userInfo.userInfo.profileImageUrl = profileImagePreviewUrl;
        userInfo.userInfo.twitterUrl = twitterUrl;
        userInfo.userInfo.instagramUrl = instagramUrl;

        if(profUrl !==""){
            setProfileImagePreviewUrl(profUrl);
        }
    };
    
    const createOrUpdateProfileComponent = () => {
        if(dig(currentUser, "currentUser", "uid")){
            return(
            <div>
                <div>
                    <form onSubmit={createOrUpdateProfile}>
                        <div>
                            {profileImagePreviewUrl === ""?
                                <div>
                                    <label htmlFor="file">
                                        <div className={myaccount.filearea}>
                                            <FontAwesomeIcon icon={['fal', 'plus-circle']} className={myaccount.addicon}/>
                                            <div className={myaccount.filelabel}>
                                                {t('myaccount_upload_image')}
                                            </div>
                                        </div>
                                    </label>
                                    <input type="file" onChange={handleProfileImage} className={myaccount.inputButton} id="file" />
                                </div>
                            :
                                <div>
                                    <label htmlFor="file">
                                        <div className={myaccount.previewarea}>
                                            <img src={profileImagePreviewUrl} alt="uploaded" className={myaccount.profile_img}/>
                                        </div>
                                    </label>
                                    <input type="file" onChange={handleProfileImage} className={myaccount.inputButton} id="file" />
                                </div>                    
                            }
                        </div>
                        <div className={myaccount.profileimg_description}>
                            タップするとプロフィール画像が編集できます
                        </div>
                        {!isFirstRoomIdUpdate?
                            <div className={myaccount.room_id_area}>
                                <div className={myaccount.label_room_id}>RoomID</div>
                                <div className={myaccount.room_id}>{roomId}</div>
                            </div>
                        :
                            <React.Fragment>
                                <div className={myaccount.inputarea}>
                                    <TextField 
                                        className={myaccount.inputtext} 
                                        label="Room ID (例)test_taro" 
                                        type="text" 
                                        required 
                                        error={!isValidRoomId}
                                        value={roomId} 
                                        onChange={(event)=> setRoomId(event.currentTarget.value)} 
                                        onBlur={(event)=> checkInputRoomId(event.currentTarget.value)}
                                    />                        
                                </div>
                                {!isValidRoomId?
                                    <div className={main.validation_error}>
                                        ！半角英数字・記号を入力してください
                                    </div>
                                :
                                    <div></div>
                                }
                                {isAlreadyExistRoomId?
                                    <div className={main.validation_error}>
                                        ！このRoom IDはすでに利用されています
                                    </div>
                                :
                                    <div></div>
                                }
                            </React.Fragment>
                        }

                        <div className={myaccount.room_id_attention_area}>
                            {t('myaccount_room_id_attention')}
                        </div>
                        <div className={myaccount.inputarea}>
                            <TextField 
                                className={myaccount.inputtext} 
                                label={t('myaccount_account_name')}
                                type="text" 
                                required 
                                value={displayName} 
                                onChange={(event)=> setDisplayName(event.currentTarget.value)} 
                            />
                        </div>
                        <div className={myaccount.inputarea}>
                            <TextField 
                                multiline
                                rows="8"
                                margin="normal"
                                variant="outlined"                            
                                className={myaccount.inputtext} 
                                label={t('myaccount_introduction')}
                                type="text" 
                                required 
                                value={introduction} 
                                onChange={(event)=> setIntroduction(event.currentTarget.value)} 
                            />
                        </div>
                        {isCreator || from === "initialregister"?
                        <React.Fragment>
                            <div className={myaccount.inputarea}>
                                <TextField 
                                    className={myaccount.inputtext} 
                                    label={t('myaccount_account_twitter')}
                                    type="text" 
                                    value={twitterUrl} 
                                    onChange={(event)=> setTwitterUrl(event.currentTarget.value)} 
                                />
                            </div>
                            <div className={myaccount.inputarea}>
                                <TextField 
                                    className={myaccount.inputtext} 
                                    label={t('myaccount_account_instagram')}
                                    type="text" 
                                    value={instagramUrl} 
                                    onChange={(event)=> setInstagramUrl(event.currentTarget.value)} 
                                />
                            </div>

                            <div className={myaccount.inputarea}>
                                <TextField 
                                    className={myaccount.inputtext} 
                                    label={t('myaccount_request_price')}
                                    type="number" 
                                    required 
                                    error={!isValidPrice}
                                    value={requestCreatePrice} 
                                    onChange={(event)=> setRequestCreatePrice(event.currentTarget.value)} 
                                    onBlur={(event)=> checkInputPrice(event.currentTarget.value)}
                                />
                            </div>
                            {!isValidPrice?
                                <div className={main.validation_error}>
                                    ！数字を入力してください
                                </div>
                            :
                                <div></div>
                            }
                            <div className={myaccount.request_acceptable}>
                                &nbsp;&nbsp;{t('myaccount_request_acceptable')}:&nbsp;&nbsp;
                                <DragSwitch checked={isRequestAcceptable} onChange={(e) => {
                                setIsRequestAcceptable(e)
                                }}>
                                </DragSwitch>
                            </div>
                        </React.Fragment>
                        :
                        <div></div>
                        }
                        <div className={myaccount.agree_btn_area}>
                            {submitComponent()}
                        </div>
                    </form>
                </div>
            </div>
            )
        }else{
            return(
                <div>ログインの上、ご確認ください。</div>
            )
        }
    }


    return(
        <div>
            <Toaster />
            <GoBack />
            {from === "initialregister"?
            <div>
                <h2>クリエイター情報登録</h2>
                <h3 className={main.step}>STEP2/4</h3>
                <div>ここで基本情報を登録してください。短めの説明文が入ります。</div>
            </div>
            :
                <h2>{t('myaccount_title')}</h2>
            }
            {createOrUpdateProfileComponent()}
        </div>
    )
}

export default MyAccount;