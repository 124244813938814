import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const useStyles = makeStyles(
  theme => ({
    menuItemSelected: {
      "&:hover": {
        backgroundColor: "#4868cf",
        color: "white"
      }
    },
    menuItem: {
      fontSize: 12,
      paddingTop: "4px",
      paddingBottom: "4px",
      "&:hover, &:focus": {
        backgroundColor: "#4868cf",
        color: "white"
      }
    }
  }),
  { name: "SimpleListMenu" }
);

const options = [
  "Show some love to Material-UI",
  "Show all notification content",
  "Hide sensitive notification content",
  "Hide all notification content"
];

export default function SimpleListMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [open, setOpen] = useState(false);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className={classes.root}>
      <Button
        aria-haspopup="true"
        aria-controls="lock-menu"
        aria-label="when device is locked"
        onClick={handleClickListItem}
      >
        Click This
      </Button>

        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              dense
              classes={{
                dense: classes.menuItem,
                selected: classes.menuItemSelected
              }}
              key={option}
              selected={index === selectedIndex}
              onClick={event => handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
    </div>
  );
}
