import React , { useState, useEffect, useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { useLocation } from 'react-router-dom';
import dig from "object-dig";
import * as Api from "../service/api";

import requestdetail from '../css/requestdetail.module.css';
import Checkout from "./Checkout";

//言語切替
import { useTranslation } from 'react-i18next';

function RequestUse() {
    const currentUser = useContext(AuthContext);

    const [t] = useTranslation();

    const [request, setRequest] = useState({});

    const location = useLocation();
    let requestId = "";
    if(location.search !== ""){
        //あんまりよろしくないですが
        var urlParam = location.search.substring(1);
        requestId = urlParam.substring(urlParam.indexOf('=') + 1);
    }

    

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                const data = await Api.getRequest(requestId);
                if(data.length > 0){
                    // マジでよくないけど、stripeからキャンセルで戻るときに、requestUseに返すか、reqesutCreateに返すかを渡す必要がある
                    data[0].path = "requestuse";
                    // backendの処理用
                    setRequest(data[0]); 
                }          
            }
        }
        fetch();
    }, [currentUser]);  

    const requestUseMainCompomnent = () =>{
        if(dig(currentUser,"currentUser", "uid")){
            return(
                <React.Fragment>
                    <div>
                        <h2>{t('request_use_checkout_title')}</h2>
                        <div className={requestdetail.accept_btn_area}>
                            <Checkout request={request}/>
                        </div>
                    </div>
                </React.Fragment>
            )
        }else{
            return(
                <div>
                    <br />
                    {t('confirm_after_login')}
                </div>
            )
        }

    }


    return(
        <div>
            {requestUseMainCompomnent()}
        </div>
    )
}

export default RequestUse;