import React , { useEffect, useContext, useState }from "react";
import { AuthContext } from "../providers/AuthProvider";
import * as Api from "../service/api";
import dig from "object-dig";
import { Link, useLocation } from 'react-router-dom';
import {requestStatus, notificationType, feeRate, dataStatus, serviceUrl, requestClass} from "../service/constants";
import * as mail from "./SendEventTriggerMail";
import main from '../css/main.module.css';
import thanks from '../css/thanks.module.css';

//言語切替
import { useTranslation } from 'react-i18next';



function PaymentSuccess() {
    const currentUser = useContext(AuthContext);

    const location = useLocation();

    const [t] = useTranslation();

    const [isSent, setIsSent] = useState(false);


    let price = "";
    let requestId = "";
    let requestDcoumentId = "";
    if(location.search !== ""){
        //先頭の?を除いて、split
        var urlParam = location.search.substring(1).split('&');
        //あんまりよろしくないですが
        price = urlParam[0].substring(urlParam[0].indexOf('=') + 1);
        requestId = urlParam[1].substring(urlParam[1].indexOf('=') + 1);
        requestDcoumentId = urlParam[2].substring(urlParam[2].indexOf('=') + 1);
    }

    useEffect(()=>{
        if(dig(currentUser,"currentUser", "uid")){
            const updateRequestStatus = async() =>{
                await Api.updateRequestStatusOnlyStatus(requestDcoumentId,requestStatus.inCreating);
            } 
            const createPaymentRecordAndNoticationAndSales = async() =>{
                const dataRequest = await Api.getRequest(requestId);
                const request = dataRequest[0];        
                const dataWork = await Api.getWork(request.workId);
                // そのrequestのrequestorがログインしてるユーザーと一致してれば書き込み
                if(currentUser.currentUser.uid === request.requestorId){
                    await Api.createPaymentRecord(currentUser.currentUser.uid,price,requestId);
                    // リクエストタイプがデータの購入で、productFileUrlがすでにアップロードされてるときは、納品の手間を省く
                    // なので、いきなり受け取り連絡
                    // なので、このときは、リクエストステータスを、creatingを飛ばして更新
                    if(request.requestType === requestClass.existUse && dataWork.productFileUrl !== "" && dataWork.productImageUrl !== ""){
                        await Api.updateRequestStatusWhenDeliverFile(requestDcoumentId, dataWork.productFileUrl, requestStatus.waitReceive);
                        await Api.updateRequestStatusWhenDeliverImage(requestDcoumentId, dataWork.productImageUrl, requestStatus.waitReceive);
                        if(!isSent){
                            setIsSent(true);
                            const isNewNotification = await Api.isNewNotification(requestId, notificationType.receive);
                            if(isNewNotification){
                                await Api.createNotificationForConsumer(
                                    request.requestId, 
                                    request.requestorId, 
                                    request.creatorId, 
                                    request.requestType, 
                                    notificationType.receive, 
                                    request.requestorDisplayName, 
                                    request.creatorDisplayName, 
                                    request.creatorProfileImageUrl
                                )
                                // 依頼者にメール
                                let titleForRquestor = request.creatorDisplayName+"さんの作品がダウンロード可能になりました/The creator completed delivery of the work";
                                let contentForRquestor = "▼Japanese\n(English is below)\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
                                            +request.creatorDisplayName
                                            +"さんの作品がダウンロード可能になりました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
                                            +serviceUrl.urlToRequestDetail+"/?reuqestId="+request.requestId
                                            +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-\n\n\n\n▼English\n\nThank you for always using artless.\n\n"
                                            +request.creatorDisplayName
                                            +" completed delivery of the work.\nPlease access the website from this URL and check the details.\n\n"
                                            +serviceUrl.urlToRequestDetail+"/?reuqestId="+request.requestId
                                            +"\n\nIf you have any questions or don't know what this mail says, please contact us at the email address below.\n\nartless.gallery.contact@gmail.com\n\n—————————-\nAdministrator of artless\n—————————-"
                                            ;
                    
                                mail.SendEventTriggerMail(request.requestorMailAddress, titleForRquestor, contentForRquestor) ;

                                // クリエイターさんにもメール
                                let titleForCreator = request.requestorDisplayName+"さんからの支払いが完了しました";
                                let contentForCreator = request.creatorDisplayName +"さま\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
                                +request.requestorDisplayName
                                +"さんからの支払いが完了しました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
                                +serviceUrl.urlToRequestDetail+"/?reuqestId="+request.requestId
                                +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-";
                                
                                mail.SendEventTriggerMail(request.creatorMailAddress, titleForCreator, contentForCreator);
                            }
                        }
                    }else{
                        if(!isSent){
                            setIsSent(true);
                            const isNewNotification = await Api.isNewNotification(requestId, notificationType.startCreate);
                            if(isNewNotification){
                                await Api.createNotificationForCreator(
                                    request.requestId, 
                                    request.requestorId, 
                                    request.creatorId, 
                                    request.requestType, 
                                    notificationType.startCreate, 
                                    request.requestorDisplayName, 
                                    request.creatorDisplayName, 
                                    request.requestorProfileImageUrl
                                )
                                // クリエイターさんにメール
                                let title = request.requestorDisplayName+"さんからの支払いが完了しました";
                                let content = request.creatorDisplayName +"さま\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
                                +request.requestorDisplayName
                                +"さんからの支払いが完了しました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
                                +serviceUrl.urlToRequestDetail+"/?reuqestId="+request.requestId
                                +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-";

                                mail.SendEventTriggerMail(request.creatorMailAddress, title, content);
                            }
                        }
                    }
                    createSales(request);
                }
            }

            const createSales = async(request) =>{
                // bankaccountの情報もこのタイミングで入れる
                const bankdata = await Api.getBankAccountByUserId(request.creatorId);

                // salesデータ
                const originalPrice =request.originalPrice;
                const businessFee = request.businessFee;
                const creatorTax = request.originalPrice*feeRate.tax;
                const businessTax = request.businessFee*feeRate.tax;
                const paymentFee = request.paymentFee;
                const creatorId = request.creatorId;
                const creatorDisplayName = request.creatorDisplayName;

                // 基本bankdataが空のことはないが、万が一空の場合は空のまま登録する
                if(bankdata.length > 0){
                    await Api.createSales(
                        request.requestorId,
                        request.requestId, 
                        creatorId, 
                        creatorDisplayName, 
                        originalPrice, 
                        businessFee, 
                        creatorTax, 
                        businessTax, 
                        paymentFee,
                        bankdata[0].familyName,
                        bankdata[0].firstName,
                        bankdata[0].bankName,
                        bankdata[0].branchNumber,
                        bankdata[0].accountType,
                        bankdata[0].accountNumber,
                    );    
                }else{
                    await Api.createSales(
                        request.requestorId,
                        request.requestId, 
                        creatorId, 
                        creatorDisplayName, 
                        originalPrice, 
                        businessFee, 
                        creatorTax, 
                        businessTax, 
                        paymentFee,
                        dataStatus.unregistered,
                        dataStatus.unregistered,
                        dataStatus.unregistered,
                        dataStatus.unregistered,
                        dataStatus.unregistered,
                        dataStatus.unregistered,
                    );    
                }
            }

            updateRequestStatus();
            createPaymentRecordAndNoticationAndSales();
        }
    }, [currentUser]);  


    const toTopPageButton = () => {
        return(
            <Link to='/' className={main.primary_btn}>TOPページへ</Link>
        )
    }


    return(
        <div>
            <h2>{t('payment_success_title')}</h2>
                <div className={thanks.message}>
                    <p>
                        {t('payment_success_text')}<br />
                    </p>
                </div>
            <div>
                {toTopPageButton()}
            </div>
        </div>
    )
}

export default PaymentSuccess;