import React , { useState, useEffect, useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import * as Api from "../service/api";
import dig from "object-dig";
import { Link } from 'react-router-dom';
import followlist from '../css/followlist.module.css';
import GoBack from "./GoBack";

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//言語切替
import { useTranslation } from 'react-i18next';


function Follows() {
    const currentUser = useContext(AuthContext);
    const [profileImages, setProfileImages] = useState([]);

    const [t] = useTranslation();
    
    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                const followedIdList = await Api.getFollowListOfUser(currentUser.currentUser.uid);
                if(followedIdList.length > 0){
                    const followedCreatorProfileImagesList = await Api.getFollowCreatorProfileImages(followedIdList);
                    setProfileImages(followedCreatorProfileImagesList);
                }
            }
        }  
        fetch();
    }, [currentUser]);

    const followList = profileImages.map((profileImage) => {
        return (
            <div key={profileImage.id}>
                {
                    (() => {
                        return(
                            <div className={followlist.item}>
                                <Link to={"/profile/"+profileImage.roomId}>
                                    <div className={followlist.flex}>
                                        <div className={followlist.requstorImage}>
                                            <img src={profileImage.profileImageUrl} alt="" />
                                        </div>
                                        <div className={followlist.content}>
                                            <p>{profileImage.displayName}さん</p>
                                        </div>
                                        <div className={followlist.arrow}>
                                            <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                        </div>
                                    </div>
                                </Link>
                            </div> 

                        )
                    })()
                }
            </div>
        );
      });


    return(
        <div>
            <GoBack />
            <h2>{t('follows_title')}</h2>
            {profileImages.length >0 ?
                <div>
                    {followList}
                </div>
            :
                <div>
                    <p>{t('follows_nothing')}</p>
                </div>
            }
        </div>
    )
}

export default Follows;