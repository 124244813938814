import React , { useEffect, useContext } from "react";
import dig from "object-dig";
import * as cons from "../service/constants";
import crypto from 'crypto-js'
import { AuthContext } from "../providers/AuthProvider";
import { adminUsers } from '../service/constants';


function ManualDecrypt() {
    const currentUser = useContext(AuthContext);

    // ---------encrypto/decrypto----------
    const encryptoCode = (unprocessed) => {
        const encrypted = crypto.AES.encrypt(unprocessed, cons.decryptKey.key);
        return encrypted.toString();
    }

    const decryptoCode = (processed) => {
        const decrypted = crypto.AES.decrypt(processed, cons.decryptKey.key)
        return decrypted.toString(crypto.enc.Utf8);
    }

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                if(currentUser.currentUser.uid === adminUsers.admin_userId){
                    const rawobeject = "";
                    const alreadyEncrypted = "U2FsdGVkX1/a8Mjk9ia/oVFnEkXrZQIJ+uXZJiqksKt7qamTbAyo9cVkWxIvUJtl";
                    const encrypted = encryptoCode(rawobeject);
                    const decrypted = decryptoCode(encrypted);
                    const decryptedOnly = decryptoCode(alreadyEncrypted);
                    console.log("encrypted:  "+encrypted);
                    console.log("decrypted:  "+decrypted);
                    console.log("decryptedOnly:  "+decryptedOnly);
                }else{
                    console.log("権限がありません")
                }
            }else{
                console.log("ログインしてください")
            }
        }  
        fetch();
    }, [currentUser]);

    return(
        <div>
            <div>
                hogehoge
            </div>
        </div>
    )
}

export default ManualDecrypt;