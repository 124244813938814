export const checkValidName = (value) =>{
  // blankか否か
  if(value === ""){
    return false;
  }else{
    // 字数が60字を超えてる
    if(value.length > 60){
      return false;
    } else {
      return true;
    }
  }
};

export const checkValidEmail = (value) =>{
  var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;

  // blankか否か
  if(value === ""){
    return false;
  }else{
    // メールアドレスの体をなしてるか
    if (reg.test(value)) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkValidContent = (value) =>{
  // blankか否か
  if(value === ""){
    return false;
  }else{
    // 字数が10000字を超えてる
    if(value.length > 10000){
      return false;
    } else {
      return true;
    }
  }
};

export const checkValidPrice = (value) =>{
  // blankか否か
  if(value === ""){
    return false;
  }else{
    // 数値かどうか
    // 仮に"123"のような文字列でも数値に変換されて判断される
    if(isNaN(value)){
      // 数値ではない
      return false;
    } else {
      // 0~9,999,999円の間
      if(value <= 9999999 && value >= 0){
        return true;
      } else {
        return false;
      }
    }
  }
};

export const checkValidBankCodeOthers = (value) =>{
    // blankか否か
    if(value === ""){
      return false;
    }else{
      // 金融機関コードは4桁
      if(value.length !== 4){
        return false;
      } else {
        return true;
      }
    }  
};

export const checkValidBankNameOthers = (value) =>{
    // blankか否か
    if(value === ""){
      return false;
    }else{
      // 字数が60字を超えてる
      if(value.length > 30){
        return false;
      } else {
        return true;
      }
    }  
};

export const checkValidBranchNumer = (value) =>{
  // blankか否か
  if(value === ""){
    return false;
  }else{
    // 支店コードは4桁
    if(value.length !== 3){
      return false;
    } else {
      return true;
    }
  }  
};

export const checkValidAccountNumer = (value) =>{
    // blankか否か
    if(value === ""){
      return false;
    }else{
      // 口座番号は最大8桁
      if(value.length > 8){
        return false;
      } else {
        return true;
      }
    }  
};

export const checkValidFamilyName = (value) =>{
      // blankか否か
      if(value === ""){
        return false;
      }else{
        // 長さは20字以下とする
        if(value.length > 20){
          return false;
        } else {
            // 全角カナかのチェック
            if(value.match(/^[ァ-ヶー　]+$/)){
              return true;
            }else{
              return false;
            }          
        }
      }  
};

export const checkValidFirstName = (value) =>{
        // blankか否か
        if(value === ""){
          return false;
        }else{
          // 長さは20字以下とする
          if(value.length > 20){
            return false;
          } else {
            // 全角カナかのチェック
            if(value.match(/^[ァ-ヶー　]+$/)){
              return true;
            }else{
              return false;
            }
          }
        }  
};

export const checkValidRoomId = (value) =>{
  // blankか否か
  if(value === ""){
    return false;
  }else{
    // 長さは16字以下とする
    if(value.length > 16){
      return false;
    } else {
      // 半角英数のチェック
      if(value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)){
        return true;
      }else{
        return false;
      }
    }
  }  
};

