import React , { useState, useEffect, useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import main from '../css/main.module.css';
import searchresult from '../css/searchresult.module.css';
import { useLocation } from 'react-router-dom';
import { tasteCode } from '../service/constants';
import * as Api from "../service/api";
import SinglSquareImageList from "./SinglSquareImageList";
import GoBack from "./GoBack";



//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


//言語切替
import { useTranslation } from 'react-i18next';



function SearchResult() {
    const currentUser = useContext(AuthContext);
    const [t] = useTranslation();
    const [images, setImages] = useState([]);
    const [lastDocId, setLastDocId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isAlldisplayed, setIsAlldisplayed] = useState(false);

    //先頭の?を除いて、split
    // どこから来たか確認
    const location = useLocation();

    let tasteCategoryCode = "";
    if(location.search !== ""){
        var urlParam = location.search.substring(1);
        //あんまりよろしくないですが決め打ちで
        tasteCategoryCode = urlParam.substring(urlParam.indexOf('=') + 1);
    }

    useEffect(()=>{
        let unmounted = false;
        fetchWorks();
        return () => {
            unmounted = true
        }
    }, [currentUser]);

    const fetchWorks = async() =>{
        const data = await Api.getSomeWorksByTasteCategoryCode(tasteCategoryCode);
        setImages(data);
        setLastDocId(data[data.length - 1].workId);
    }

    const fetchWorksMore = async() =>{
        setIsLoading(true);
        const data = await Api.getSomeWorksByTasteCategoryCodeMore(tasteCategoryCode, lastDocId);
        if(data.length > 0){
            const combined = images.concat(data);
            setImages(combined);
            setLastDocId(data[data.length - 1].workId);
            setIsLoading(false);
        }else{
            setIsAlldisplayed(true);
        }
    }



    const searchConditionComponent = () =>{
        if(tasteCategoryCode === tasteCode.cute){
            return(
                <div>"{t('tastecategorycode_cute')}"</div>
            )
        } else if (tasteCategoryCode === tasteCode.pop){
            return(
                <div>"{t('tastecategorycode_pop')}"</div>
            )
        } else if (tasteCategoryCode === tasteCode.beautiful){
            return(
                <div>"{t('tastecategorycode_beautiful')}"</div>
            )
        } else if (tasteCategoryCode === tasteCode.cool){
            return(
                <div>"{t('tastecategorycode_cool')}"</div>
            )
        } else if (tasteCategoryCode === tasteCode.surreal){
            return(
                <div>"{t('tastecategorycode_surreal')}"</div>
            )
        } else if (tasteCategoryCode === tasteCode.artistic){
            return(
                <div>"{t('tastecategorycode_artistic')}"</div>
            )
        } else if (tasteCategoryCode === tasteCode.ennui){
            return(
                <div>"{t('tastecategorycode_ennui')}"</div>
            )
        }
    }

    const searchResultComponent = () =>{
        // 結果0件のとき
        if(images.length > 0){
            return(
                <SinglSquareImageList images={images}/>
            )
        } else {
            return(
                <div className={searchresult.noresult}>
                    <FontAwesomeIcon icon={['far', 'sad-tear']} />
                    &nbsp;&nbsp;{t('searchresult_noresult')}
                </div>
            )
        }
    }

    const seeMoreButtonComponent = () =>{
        if(isAlldisplayed){
            return(
                <div className={main.alreadydisplayed}>
                    No more works.
                </div>
            )
        }else{
            if(isLoading){
                return(
                    <div className={main.loading}>
                        Loading...
                    </div>
                )
            }else{
                return(
                    <div className={main.secondary_btn} onClick={fetchWorksMore}>
                        {t('main_seemore_button')}
                    </div>
                )    
            }
        }
    }


    return(
        <div>
            <GoBack />
            <div className={searchresult.content_header}>
                <div className={searchresult.content_title}>
                    <h2>{t('searchresult_title')}</h2>
                </div>
                <div className={searchresult.condition}>
                    {searchConditionComponent()}
                </div>
            </div>
            <div>
                {searchResultComponent()}
            </div>
            {seeMoreButtonComponent()}
            <br />
        </div>
    )
}

export default SearchResult;