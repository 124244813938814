import React , { useState, useEffect, useContext } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";

import * as Api from "../service/api";
import * as Validation from "../service/validation";

import dig from "object-dig";
import { useLocation, useHistory } from 'react-router-dom';
import main from '../css/main.module.css';
import creatorpage from '../css/creatorpage.module.css';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import toast, { Toaster } from 'react-hot-toast';
import GoBack from "./GoBack";

//言語切替
import { useTranslation } from 'react-i18next';



function BankAccountRegister(props) {
    const userInfo = useContext(UserInfoContext);
    const [t] = useTranslation();


    const [langCode, setLangCode] = useState("");

    const [bankName, setBankName] = useState("");
    const [bankNameOthers, setBankNameOthers] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [bankCodeOthers, setBankCodeOthers] = useState("");
    const [accountType, setAccountType] = useState("");
    const [branchNumber, setBranchNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [creatorId, setCreatorId] = useState("");
    const [isAlreadyReg, setIsAlreadyReg] = useState(false);
    const [banckAccountDocId, setBanckAccountDocId] = useState(false);

    // validation用
    const [isValidBankNameOthers, setIsValidBankNameOthers] = useState(true);
    const [isValidBankCodeOthers, setIsValidBankCodeOthers] = useState(true);
    const [isValidBranchNumber, setIsValidBranchNumber] = useState(true);
    const [isValidAccountNumber, setIsValidAccountNumber] = useState(true);
    const [isValidFamilyName, setIsValidFamilyName] = useState(true);
    const [isValidFirstName, setIsValidFirstName] = useState(true);

    const history = useHistory();

    //先頭の?を除いて、split
    // どこから来たか確認
    const location = useLocation();

    let from = "";
    if(location.search !== ""){
        var urlParam = location.search.substring(1);
        //あんまりよろしくないですが
        from = urlParam.substring(urlParam.indexOf('=') + 1);
    }

    useEffect(()=>{
        // メモリーリーク対策
        let isMounted = true;

        const fetch = async () =>{
            if (isMounted) { 
                // creatorIdをセット
                if(dig(userInfo,"userInfo", "uid")){
                    setCreatorId(userInfo.userInfo.uid);
                    setLangCode(userInfo.userInfo.langCode);
                    // すでに銀行口座を登録済みの場合は値をセット
                    const data = await Api.getBankAccountByUserId(userInfo.userInfo.uid);
                    if(data.length > 0){
                        
                        setBankName(data[0].bankName);
                        setBankCode(data[0].bankCode);
                        setAccountType(data[0].accountType);
                        setAccountNumber(data[0].accountNumber);
                        setBranchNumber(data[0].branchNumber);
                        setFamilyName(data[0].familyName);
                        setFirstName(data[0].firstName);
                        setIsAlreadyReg(true);
                        setBanckAccountDocId(data[0].id);
                        
                    }
                }
            }
        }
        fetch();

        return () => {
            isMounted = false
        };
    }, [userInfo]);

    const handleBankNameChange = (event) => {
        var tmpBankName = event.target.value;
        setBankName(tmpBankName);
        // 銀行名に応じて金融機関コードをセットする
        if(tmpBankName === "三菱ＵＦＪ銀行"){
            setBankCode("0005");
        } else if (tmpBankName === "みずほ銀行"){
            setBankCode("0001");
        } else if (tmpBankName === "三井住友銀行"){
            setBankCode("0009");
        } else if (tmpBankName === "りそな銀行"){
            setBankCode("0010");
        } else if (tmpBankName === "埼玉りそな銀行"){
            setBankCode("0017");
        } else if (tmpBankName === "セブン銀行"){
            setBankCode("0034");
        } else if (tmpBankName === "ＰａｙＰａｙ銀行"){
            setBankCode("0033");
        } else if (tmpBankName === "楽天銀行"){
            setBankCode("0036");
        } else if (tmpBankName === "ゆうちょ銀行"){
            setBankCode("9900");
        } else if (tmpBankName === "ソニー銀行"){
            setBankCode("0035");
        } else if (tmpBankName === "イオン銀行"){
            setBankCode("0040");
        } else if (tmpBankName === "住信ＳＢＩ銀行"){
            setBankCode("0038");
        } else {
            //それ以外の場合は何もしない
        }
    };

    const handleAccountTypeChange = (event) => {
        setAccountType(event.target.value);
    };

    const toCreatorRegisterThanks = {
        pathname: '/thanks',
        search: '?from=initialregister',
    };

    const toHome = {
        pathname: '/',
    };

    const successMessage = langCode === "ja" ? "口座情報を更新しました":"Update Successfully";
    const notify = () => toast.success(successMessage,
        {
            style: {
                border: '1px solid #94ABFF',
                borderRadius: '10px',
                background: '#ffffff',
                color: '#94ABFF',
            },
        duration: 2000,
        }
    );

    // validation check
    const checkInputBankNameOthers = (value) =>{
        // 銀行名がその他のときのみ確認
        if(bankName === "others"){
            var isValid = Validation.checkValidName(value);
            setIsValidBankNameOthers(isValid);
        }
    }
    const checkInputBankCodeOthers = (value) =>{
        // 銀行名がその他のときのみ確認
        if(bankName === "others"){
            var isValid = Validation.checkValidBankCodeOthers(value);
            setIsValidBankCodeOthers(isValid);
        }
    }

    const checkInputBranchNumber = (value) =>{
        var isValid = Validation.checkValidBranchNumer(value);
        setIsValidBranchNumber(isValid);
    }

    const checkInputAccountNumber = (value) =>{
        var isValid = Validation.checkValidAccountNumer(value);
        setIsValidAccountNumber(isValid);
    }

    const checkInputFamilyName = (value) =>{
        var isValid = Validation.checkValidFamilyName(value);
        setIsValidFamilyName(isValid);
    }

    const checkInputFirstName = (value) =>{
        var isValid = Validation.checkValidFirstName(value);
        setIsValidFirstName(isValid);
    }

    const submitComponent = () =>{
        if(from === "initialregister"){
            if(
                isValidBankNameOthers 
                && isValidBankCodeOthers 
                && isValidBranchNumber
                && isValidAccountNumber
                && isValidFamilyName
                && isValidFirstName
            ){
                return(
                    <button variant="contained" color="primary" type="submit" className={main.primary_btn} >
                        登録して、手続きを完了する
                    </button>
                    
                )
            } else {
                return(
                    <div className={main.disabled_btn} >
                        登録して、手続きを完了する
                    </div>
                )
            }
        } else {
            if(
                isValidBankNameOthers 
                && isValidBankCodeOthers 
                && isValidBranchNumber
                && isValidAccountNumber
                && isValidFamilyName
                && isValidFirstName
            ){
                return(
                    <button variant="contained" color="primary" type="submit" className={main.primary_btn} >
                        更新する
                    </button>
                )
            } else {
                return(
                    <div className={main.disabled_btn} >
                        更新する
                    </div>
                )
            }

        }
    }




    const registerBankAccount = async (event) => {
        event.preventDefault();

        // ログインしてなかったら、登録できない
        if(dig(userInfo, "userInfo", "uid")){
            //　Apiをコール
            if(bankName !== "others"){
                if(isAlreadyReg){
                    await Api.updateBankAccount(banckAccountDocId, bankName, bankCode, accountType, branchNumber, accountNumber, familyName, firstName);
                }else{
                    await Api.createBankAccount(creatorId, bankName, bankCode, accountType, branchNumber, accountNumber, familyName, firstName);
                }
            } else {
                if(isAlreadyReg){
                    await Api.updateBankAccount(banckAccountDocId, bankNameOthers, bankCode, accountType, branchNumber, accountNumber, familyName, firstName);
                }else{
                    await Api.createBankAccount(creatorId, bankNameOthers, bankCodeOthers, accountType, branchNumber, accountNumber, familyName, firstName);
                }
            }
            // 銀行口座を登録し終わって初めて他の機能が使える(=creatorFlgがtrue)
            if(dig(userInfo, "userInfo", "docId")){
                if(from === "initialregister"){
                    Api.updateCreatorFlg(userInfo.userInfo.docId, true);
                    userInfo.userInfo.isCreator = true;
                    // ヘッダー用のクリエイターフラグの書き換え
                    props.changeCreatorFlg(true);
                }
            }
            notify();
            // ページ遷移
            if(from === "initialregister"){
                // 初期登録の動線の場合は、サンクスページ
                history.push(toCreatorRegisterThanks);
            }else{
                // 初期登録の動線の場合は、トップページ
                history.push(toHome);
            }
        } 
    };    

    const registerBankAccountMainComponent = () => {
        if(dig(userInfo, "userInfo", "uid")){
            return(
                <React.Fragment>
                    <Toaster />
                    <GoBack />
                    <h2>振り込み先口座情報登録</h2>
                    {from === "initialregister"
                    ?
                        <h3 className={main.step}>STEP4/4</h3>
                    :
                        <div></div>
                    }
                    {creatorId !== ""?
                    <React.Fragment>
                    <div>振り込み先の口座情報を登録してください。</div>
                    <br />
                    <div>
                        <div>
                            <form onSubmit={registerBankAccount}>
                                <div className={creatorpage.inputarea}>
                                    <FormControl 
                                        variant="standard" 
                                        sx={{ m: 1, minWidth: 120 }}
                                        className={creatorpage.selectarea}
                                    >
                                    <InputLabel id="demo-simple-select-standard-label">銀行名</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={bankName}
                                        onChange={handleBankNameChange}
                                        label="銀行名"
                                    >
                                        <MenuItem value={"三菱ＵＦＪ銀行"}>三菱ＵＦＪ銀行</MenuItem>
                                        <MenuItem value={"みずほ銀行"}>みずほ銀行</MenuItem>
                                        <MenuItem value={"三井住友銀行"}>三井住友銀行</MenuItem>
                                        <MenuItem value={"りそな銀行"}>りそな銀行</MenuItem>
                                        <MenuItem value={"埼玉りそな銀行"}>埼玉りそな銀行</MenuItem>
                                        <MenuItem value={"セブン銀行"}>セブン銀行</MenuItem>
                                        <MenuItem value={"ＰａｙＰａｙ銀行"}>ＰａｙＰａｙ銀行</MenuItem>
                                        <MenuItem value={"楽天銀行"}>楽天銀行</MenuItem>
                                        <MenuItem value={"ゆうちょ銀行"}>ゆうちょ銀行</MenuItem>
                                        <MenuItem value={"ソニー銀行"}>ソニー銀行</MenuItem>
                                        <MenuItem value={"イオン銀行<"}>イオン銀行</MenuItem>
                                        <MenuItem value={"住信ＳＢＩ銀行"}>住信ＳＢＩ銀行</MenuItem>
                                        <MenuItem value={"others"}>その他</MenuItem>
                                    </Select>
                                    </FormControl>
                                </div>
                                {bankName === "others"
                                ?
                                <div className={creatorpage.selectarea_others}>
                                    <div className={creatorpage.inputarea}>
                                    <TextField 
                                        className={creatorpage.inputtext} 
                                        label="銀行名" 
                                        type="text" 
                                        required 
                                        error={!isValidBankNameOthers}
                                        value={bankNameOthers} 
                                        onChange={(event)=> setBankNameOthers(event.currentTarget.value)}
                                        onBlur={(event)=> checkInputBankNameOthers(event.currentTarget.value)}
                                    />
                                    </div>
                                    <div className={creatorpage.inputarea}>
                                    <TextField 
                                        className={creatorpage.inputtext} 
                                        label="金融機関コード" 
                                        type="text" 
                                        required 
                                        error={!isValidBankCodeOthers}
                                        value={bankCodeOthers} 
                                        onChange={(event)=> setBankCodeOthers(event.currentTarget.value)} 
                                        onBlur={(event)=> checkInputBankCodeOthers(event.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                                :
                                <div></div>
                                }
                                <div className={creatorpage.inputarea}>
                                    <FormControl 
                                        variant="standard" 
                                        sx={{ m: 1, minWidth: 120 }}
                                        className={creatorpage.selectarea}
                                    >
                                    <InputLabel id="demo-simple-select-standard-label">口座種別</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={accountType}
                                        onChange={handleAccountTypeChange}
                                        label="口座種別"
                                    >
                                        <MenuItem value={"normal"}>普通預金</MenuItem>
                                        <MenuItem value={"current"}>当座預金</MenuItem>
                                        <MenuItem value={"savings"}>貯蓄預金</MenuItem>
                                    </Select>
                                    </FormControl>
                                </div>
                                <div className={creatorpage.inputarea}>
                                    <TextField 
                                        className={creatorpage.inputtext} 
                                        label="支店コード" 
                                        type="text" 
                                        required 
                                        error={!isValidBranchNumber}
                                        value={branchNumber} 
                                        onChange={(event)=> setBranchNumber(event.currentTarget.value)} 
                                        onBlur={(event)=> checkInputBranchNumber(event.currentTarget.value)}
                                    />
                                </div>
                                {!isValidBranchNumber?
                                    <div className={main.validation_error}>
                                        ！3桁の半角数字を入力してください
                                    </div>
                                :
                                    <div></div>
                                }

                                <div className={creatorpage.inputarea}>
                                    <TextField 
                                        className={creatorpage.inputtext} 
                                        label="口座番号" 
                                        type="text" 
                                        required 
                                        error={!isValidAccountNumber}
                                        value={accountNumber} 
                                        onChange={(event)=> setAccountNumber(event.currentTarget.value)} 
                                        onBlur={(event)=> checkInputAccountNumber(event.currentTarget.value)}
                                    />
                                </div>
                                {!isValidAccountNumber?
                                    <div className={main.validation_error}>
                                        ！7か8桁の半角数字を入力してください
                                    </div>
                                :
                                    <div></div>
                                }
                                <div className={creatorpage.inputarea}>
                                    <TextField 
                                        className={creatorpage.inputtext} 
                                        label="口座名義(セイ)" 
                                        type="text" 
                                        required 
                                        error={!isValidFamilyName}
                                        value={familyName} 
                                        onChange={(event)=> setFamilyName(event.currentTarget.value)}
                                        onBlur={(event)=> checkInputFamilyName(event.currentTarget.value)}
                                    />
                                </div>
                                {!isValidFamilyName?
                                    <div className={main.validation_error}>
                                        ！20文字以内のカタカナで入力してください
                                    </div>
                                :
                                    <div></div>
                                }
                                <div className={creatorpage.inputarea}>
                                    <TextField 
                                        className={creatorpage.inputtext} 
                                        label="口座名義(メイ)" 
                                        type="text" 
                                        required 
                                        error={!isValidFirstName}
                                        value={firstName} 
                                        onChange={(event)=> setFirstName(event.currentTarget.value)} 
                                        onBlur={(event)=> checkInputFirstName(event.currentTarget.value)}
                                    />
                                </div>
                                {!isValidFirstName?
                                    <div className={main.validation_error}>
                                        ！20文字以内のカタカナで入力してください
                                    </div>
                                :
                                    <div></div>
                                }
                                <div className={creatorpage.agree_btn_area}>
                                    {submitComponent()}
                                </div>
                            </form>
                        </div>
                    </div>
                    </React.Fragment>
                    :
                    <div>該当のデータが存在しません。</div>
                    }
                </React.Fragment>
            )

        }else{
            return(
                <div>
                    <br />
                    {t('confirm_after_login')}
                </div>
            )
        }

    }

    return(
        <div>
           {registerBankAccountMainComponent()}
        </div>
    )
}

export default BankAccountRegister;