import * as Api from "./api";
import dig from "object-dig";
import { subject } from '../service/constants';


// 処理の流れ
// クエリパラーメータ取得
// &でスプリット
// forで回して、各key・valueに分解して、連想配列に詰め直す
// constantで厳密に定義したkeyで引いて、ローカルで使う変数に詰める。詰める前にかならずundefinedになってないかをチェックする

export const convertToAssociatvieArray =  (location) =>{
  let queryParams = {};
  if(location.search !== ""){
      //先頭の?を除いて、split
      var urlParam = location.search.substring(1).split('&');
      urlParam.forEach(function(item) {
        if(item.indexOf('=') !== -1){
          const key = item.substring(0, item.indexOf('='));
          const value = item.substring(item.indexOf('=') + 1);  
          queryParams[key] = value;
        }      
      });
  }
  return queryParams;
};

export const eventLogging = (userInfo, eventCode, props, trackingId) => {
  if(dig(userInfo,"userInfo", "uid")){
    Api.addEventlog(userInfo.userInfo.uid, eventCode, props, userInfo.userInfo.langCode, trackingId);
  } else if(dig(userInfo, "userInfo", "tmpTrackingId")){
    Api.addEventlog(subject.anonymous+"_"+userInfo.userInfo.tmpTrackingId, eventCode, props, userInfo.userInfo.browserLanguage, trackingId);
  } else {
    Api.addEventlog(subject.anonymous, eventCode, props, "", trackingId);
  }
};

