import React, { useState } from "react";
import guideline from '../css/guideline.module.css';
import GoBack from "./GoBack";



//言語切替
import { useTranslation } from 'react-i18next';


function Guideline() {
    const [t] = useTranslation();
    const [onCreatorTab, setOnCreatorTab] = useState(true);
    // inputタグに初期値を与えてないとエラーになる
    const [avoidErrorDummy, setAvoidErrorDummy] = useState("");


    const tabConponent = () => {
        if(onCreatorTab){
            return(
                <div>
                    <input id="user" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={guideline.tab_item_on_user} htmlFor="user" onClick={() => setOnCreatorTab(true)}>{t('guideline_tab_for_user')}</label>
                    <input id="creator" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={guideline.tab_item_off} htmlFor="creator" onClick={() => setOnCreatorTab(false)}>{t('guideline_tab_for_creator')}</label>
                </div>
            )
        } else {
            return(
                <div>
                    <input id="user" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={guideline.tab_item_off} htmlFor="user" onClick={() => setOnCreatorTab(true)}>{t('guideline_tab_for_user')}</label>
                    <input id="creator" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={guideline.tab_item_on_creator} htmlFor="creator" onClick={() => setOnCreatorTab(false)}>{t('guideline_tab_for_creator')}</label>
                </div>
            )
        }
    }

    const switchContentConponent = () => {
        if(onCreatorTab){
            return(
                <div>
                    {forUserContentConponent()}
                </div>
            )
        } else {
            return(
                <div>
                    {forCreatorContentConponent()}
                </div>
            )
        }
    }


    const forUserContentConponent = () => {
        return(
            <React.Fragment>
                <h2>{t('guideline_for_user_title')}</h2>
                <div className={guideline.preface}>
                    {t('guideline_for_user_preface')}
                </div>
                <div className={guideline.item_title}>
                    {t('guideline_for_user_main_1_title')}
                </div>
                <div className={guideline.item_body}>
                    <p>{t('guideline_for_user_main_1_body_initial')}</p>
                    <p>{t('guideline_for_user_main_1_body_initial_1')}</p>
                    <p>{t('guideline_for_user_main_1_body_initial_2')}</p>
                    <p>{t('guideline_for_user_main_1_body_initial_3')}</p>
                    <p>{t('guideline_for_user_main_1_body_initial_4')}</p>
                    <p>{t('guideline_for_user_main_1_body_initial_5')}</p>
                    <p>{t('guideline_for_user_main_1_body_initial_6')}</p>
                    <p>{t('guideline_for_user_main_1_body_initial_7')}</p>                    
                </div>

                <div className={guideline.item_title}>
                    {t('guideline_for_user_main_2_title')}
                </div>
                <div className={guideline.item_body}>
                    <p>{t('guideline_for_user_main_2_body_initial')}</p>
                    <div className={guideline.item_body_small}>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_2_body_small_title_1')}</div>
                        <p>{t('guideline_for_user_main_2_body_1')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_2_body_small_title_2')}</div>
                        <p>{t('guideline_for_user_main_2_body_2')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_2_body_small_title_3')}</div>
                        <p>{t('guideline_for_user_main_2_body_3')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_2_body_small_title_4')}</div>
                        <p>{t('guideline_for_user_main_2_body_4')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_2_body_small_title_5')}</div>
                        <p>{t('guideline_for_user_main_2_body_5')}</p>
                    </div>
                </div>

                <div className={guideline.item_title}>
                    {t('guideline_for_user_main_3_title')}
                </div>
                <div className={guideline.item_body}>
                    <p>{t('guideline_for_user_main_3_body_initial')}</p>
                    <div className={guideline.item_body_small}>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_3_body_small_title_1')}</div>
                        <p>{t('guideline_for_user_main_3_body_1')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_3_body_small_title_2')}</div>
                        <p>{t('guideline_for_user_main_3_body_2')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_3_body_small_title_3')}</div>
                        <p>{t('guideline_for_user_main_3_body_3')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_3_body_small_title_4')}</div>
                        <p>{t('guideline_for_user_main_3_body_4')}</p>
                    </div>
                </div>

                <div className={guideline.item_title}>
                    {t('guideline_for_user_main_4_title')}
                </div>
                <div className={guideline.item_body}>
                    <div className={guideline.item_body_small}>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_1')}</div>
                        <p>{t('guideline_for_user_main_4_body_1')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_2')}</div>
                        <p>{t('guideline_for_user_main_4_body_2')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_3')}</div>
                        <p>{t('guideline_for_user_main_4_body_3')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_4')}</div>
                        <p>{t('guideline_for_user_main_4_body_4')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_5')}</div>
                        <p>{t('guideline_for_user_main_4_body_5')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_6')}</div>
                        <p>{t('guideline_for_user_main_4_body_6')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_7')}</div>
                        <p>{t('guideline_for_user_main_4_body_7')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_8')}</div>
                        <p>{t('guideline_for_user_main_4_body_8')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_user_main_4_body_small_title_9')}</div>
                        <p>{t('guideline_for_user_main_4_body_9')}</p>
                    </div>
                </div>


            </React.Fragment>
        )
    }

    const forCreatorContentConponent = () => {
        return(
            <React.Fragment>
                <h2>{t('guideline_for_creator_title')}</h2>
                <div className={guideline.preface}>
                    {t('guideline_for_creator_preface')}
                </div>

                <div className={guideline.item_title}>
                    {t('guideline_for_creator_main_1_title')}
                </div>
                <div className={guideline.item_body}>
                    <p>{t('guideline_for_creator_main_1_body_initial')}</p>
                    <div className={guideline.item_body_small}>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_1_body_small_title_1')}</div>
                        <p>{t('guideline_for_creator_main_1_body_1')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_1_body_small_title_2')}</div>
                        <p>{t('guideline_for_creator_main_1_body_2')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_1_body_small_title_3')}</div>
                        <p>{t('guideline_for_creator_main_1_body_3')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_1_body_small_title_4')}</div>
                        <p>{t('guideline_for_creator_main_1_body_4')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_1_body_small_title_5')}</div>
                        <p>{t('guideline_for_creator_main_1_body_5')}</p>
                    </div>
                </div>

                <div className={guideline.item_title}>
                    {t('guideline_for_creator_main_2_title')}
                </div>
                <div className={guideline.item_body}>
                    <p>{t('guideline_for_creator_main_2_body_initial')}</p>
                    <div className={guideline.item_body_small}>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_1')}</div>
                        <p>{t('guideline_for_creator_main_2_body_1')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_2')}</div>
                        <p>{t('guideline_for_creator_main_2_body_2')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_3')}</div>
                        <p>{t('guideline_for_creator_main_2_body_3')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_4')}</div>
                        <p>{t('guideline_for_creator_main_2_body_4')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_5')}</div>
                        <p>{t('guideline_for_creator_main_2_body_5')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_6')}</div>
                        <p>{t('guideline_for_creator_main_2_body_6')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_7')}</div>
                        <p>{t('guideline_for_creator_main_2_body_7')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_8')}</div>
                        <p>{t('guideline_for_creator_main_2_body_8')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_9')}</div>
                        <p>{t('guideline_for_creator_main_2_body_9')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_10')}</div>
                        <p>{t('guideline_for_creator_main_2_body_10')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_11')}</div>
                        <p>{t('guideline_for_creator_main_2_body_11')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_12')}</div>
                        <p>{t('guideline_for_creator_main_2_body_12')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_13')}</div>
                        <p>{t('guideline_for_creator_main_2_body_13')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_14')}</div>
                        <p>{t('guideline_for_creator_main_2_body_14')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_15')}</div>
                        <p>{t('guideline_for_creator_main_2_body_15')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_16')}</div>
                        <p>{t('guideline_for_creator_main_2_body_16')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_17')}</div>
                        <p>{t('guideline_for_creator_main_2_body_17')}</p>
                        <div className={guideline.item_body_small_title}>{t('guideline_for_creator_main_2_body_small_title_18')}</div>
                        <p>{t('guideline_for_creator_main_2_body_18')}</p>
                    </div>
                </div>

            </React.Fragment>
        )
    }



    return(
        <div>
            <GoBack />
            <div className={guideline.tabs}>
                {tabConponent()}
            </div>
            {switchContentConponent()}
        </div>
    )
}

export default Guideline;


