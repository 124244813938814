import React from "react";
import firebase from "../service/firebase";
import { functionMethod } from '../service/constants';
import * as Api from "../service/api";

require("firebase/functions");


export function SendEventTriggerMail(emailAddress, title, content) {
    let data = {};
    data.email = emailAddress;
    data.title = title;
    data.content = content;
    Api.sendEventTriggerMail(emailAddress, title, content);

    // 旧メールは一旦コメントアウト
    // let sendMail = firebase.app().functions('asia-northeast1').httpsCallable(functionMethod.sendEventTriggerMail);
    // sendMail(data);  
}