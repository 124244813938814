import React , { useState, useEffect, useContext } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";
import { Link } from 'react-router-dom';
import * as Api from "../service/api";
// import * as cons from "../service/constants";
import dig from "object-dig";
import requestmanage from '../css/requestmanage.module.css';
import { requestStatus } from '../service/constants';
import GoBackMarginLess from "./GoBackMarginLess";



//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//言語切替
import { useTranslation } from 'react-i18next';

const moment = require('moment');





function RequestManage() {
    const userInfo = useContext(UserInfoContext);
    const [requests, setRequests] = useState([]);


    const [t] = useTranslation();

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(userInfo,"userInfo", "uid")){
                const data = await Api.getRequestsByRequestorId(userInfo.userInfo.uid);
                setRequests(data);
            }
        }
        fetch();
    }, [userInfo]);

    const statusComponet = (request) => {
        if(request.status === requestStatus.beforeConfirm){
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>{t('request_detail_status_before_confirm')}</span>
                </div>
            );
        } else if (request.status === requestStatus.cancel){
            return(
                <div className={requestmanage.status_label_done}>
                    <span>{t('request_detail_status_cancel')}</span>
                </div>
            );
        } else if (request.status === requestStatus.waitPayment){
            return(
                <div className={requestmanage.status_label_todo}>
                    <span>{t('request_detail_status_wait_payment')}</span>
                </div>
            );
        } else if (request.status === requestStatus.inCreating){
            return(
                <div className={requestmanage.status_label_wait}>
                    <span>{t('request_detail_status_in_creating')}</span>
                </div>
            );
        } else if (request.status === requestStatus.waitReceive){
            return(
                <div className={requestmanage.status_label_todo}>
                    <span>{t('request_detail_status_wait_receiving')}</span>
                </div>
            );
        } else if (request.status === requestStatus.done){
            return(
                <div className={requestmanage.status_label_done}>
                    <span>{t('request_detail_status_done')}</span>
                </div>
            );
        } else {
            return(
                <div className={requestmanage.status_label_done}>
                    <span>Undefined</span>
                </div>
            );
        }
    }

    const reuqestViewList = requests.map((request) => {
        return (
            <div key={request.id}>
                <div className={requestmanage.item}>
                    <Link to={"/requestdetail?reuqestId="+request.requestId} className={requestmanage.linktag}>
                        <div className={requestmanage.flex}>
                            <div className={requestmanage.requstorImage}>
                                <img src={request.creatorProfileImageUrl} alt="" />
                            </div>
                            <div className={requestmanage.content}>
                                <div>{request.creatorDisplayName}</div>
                                <div className={requestmanage.date}>
                                    {moment(request.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                </div>
                            </div>
                            <div>
                                {statusComponet(request)}
                            </div>
                            <div className={requestmanage.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    });
    

    return(
        <div>
            <GoBackMarginLess />
            <div className={requestmanage.purchase_history_header}>
                <div className={requestmanage.purchase_history_header_child}>
                    <h2>{t('requesthistory_title')}</h2>
                </div>
                <div className={requestmanage.to_contact}>
                    <Link to="/contact">{t('requesthistory_contact')}</Link>
                </div>
            </div>  
            <div>
                {reuqestViewList}
            </div>
        </div>
    )
}

export default RequestManage;