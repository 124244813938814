import React , { useState, useContext, useRef } from "react";
import { AuthContext } from "../providers/AuthProvider";
import firebase, { storage } from "../service/firebase";
import * as Api from "../service/api";
import dig from "object-dig";
import { useHistory } from 'react-router-dom';
import main from '../css/main.module.css';
import workregister from '../css/workregister.module.css';
import requestdetail from '../css/requestdetail.module.css';
import deliverwork from '../css/deliverwork.module.css';
import { requestStatus, notificationType, serviceUrl, requestClass} from '../service/constants';
import { pink } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import * as mail from "./SendEventTriggerMail";
//Loader
import Loader from 'react-loader-spinner';



//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function DeliverWork(props) {
    const currentUser = useContext(AuthContext);

    const history = useHistory();

    const [workImage, setWorkImage] = useState("");
    const [workImagePreviewUrl, setWorkImagePreviewUrl] = useState("");
    let randomWorkImageName = "";

    const [workPsd, setWorkPsd] = useState("");
    let randomWorkPsdName = "";

    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    const [isNsfw, setIsNsfw] = useState(false);

    const [isSelectedWorkImage, setIsSelectedWorkImage] = useState(false);
    const [isSelectedWorkFile, setIsSelectedWorkFile] = useState(false);

    const [uploadFileName, setUploadFileName] = useState("");

    const [isSending, setIsSending] = useState(false);


    const handleWorkImage = event => {
        const image = event.target.files[0];
        setWorkImage(image);
        setWorkImagePreviewUrl(createObjectURL(image));
        setIsSelectedWorkImage(true);
    };
    
    const handleWorkPsd = event => {
        const file = event.target.files[0];
        setUploadFileName(file.name);
        setWorkPsd(file);
        setIsSelectedWorkFile(true);
    };

    const toDeliveComplete = {
        pathname: '/thanks',
        search: '?from=deliverComplete',
    };

    const processing = useRef(false);

    const workRegister = (event) => {
        event.preventDefault();
        setIsSending(true);
        //処理中(true)なら処理せずに抜ける
        if (processing.current) {
            return
        };
        
        //処理中フラグを上げる
        processing.current = true;
        
        if (workImage === "") {
            console.log("ファイルが選択されていません");
        } else {
            if(dig(currentUser,"currentUser", "uid")){
                
                //ランダム文字列の末尾につける日付
                var now = new Date();
                var timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString();
        
                // アップロード前にファイル名をランダム文字列に書き換える
                // 同じファイル名のファイルはcloudstorageが受け付けない
                // あともし同じ文字列があった場合に再度ランダマイズ？
                randomWorkImageName = Math.random().toString(32).substring(2)+timestr;
                randomWorkPsdName = Math.random().toString(32).substring(2)+timestr;
                // プロフィール画像のアップロード処理
                const uploadImageTask = storage.ref(`/images/${randomWorkImageName}`).put(workImage);
                uploadImageTask.on(
                    firebase.storage.TaskEvent.STATE_CHANGED,
                    next,
                    error,
                    completeWork
                );
                // psdを一旦やめる
                // const uploadPsdTask = storage.ref(`/images/${randomWorkPsdName}`).put(workPsd);
                // uploadPsdTask.on(
                //     firebase.storage.TaskEvent.STATE_CHANGED,
                //     next,
                //     error,
                //     completePsd
                // );
            }else{
                console.log("uidがないため、失敗");
            }
        }
        //処理中フラグを下げる
        processing.current = false;

        //アップロード時間を稼ぐ
        setTimeout(() => {
            moveToThanksPage();
        }, 5 * 1000);
    };
    const next = snapshot => {
        // 進行中のsnapshotを得る
        // アップロードの進行度を表示
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        console.log(snapshot);
    };
    const error = error => {
        // エラーハンドリング
        console.log(error);
    };

    const isSent = useRef(false);
    // exist_useのときは、addじゃなくて、update
    const completeWork = async () => {        
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        const imageUrl = await storage.ref("images").child(randomWorkImageName).getDownloadURL();
        // Psdとどっちが先に終わるか分からんから、都度処理するしかない
        await Api.updateRequestStatusWhenDeliverImage(props.request.id, imageUrl, requestStatus.waitReceive);
        //　既存のデータの購入の場合、新規作成は不要
        // すでにimageUrlはある
        // リクエストで創作したときだけ新規作成
        // 既存のデータ購入の場合もProdcutImageUrlのアップデートは必要
        if(props.request.requestType !== requestClass.existUse){
            // ここ通ってなさそう
            await Api.addWork(props.request.creatorId, imageUrl, 0, !isNsfw, false, "", "", imageUrl);
        }else{
            await Api.updateWorkProductImageUrl(props.request.workId, imageUrl);
        }
        
        // 通知は二重チェックしてるので、両方に書いとく
        if(!isSent.current){
            const isNewNotification = await Api.isNewNotification(props.request.id, notificationType.receive);
            if(isNewNotification){
                await Api.createNotificationForConsumer(
                    props.request.requestId, 
                    props.request.requestorId, 
                    props.request.creatorId, 
                    props.request.requestType, 
                    notificationType.receive, 
                    props.request.requestorDisplayName, 
                    props.request.creatorDisplayName, 
                    props.request.creatorProfileImageUrl
                )
                // 依頼者にメール
                let title = props.request.creatorDisplayName+"さんの納品が完了しました/The creator completed delivery of your work";
                let content = "▼Japanese\n(English is below)\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
                            +props.request.creatorDisplayName
                            +"さんの納品が完了しました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
                            +serviceUrl.urlToRequestDetail+"/?reuqestId="+props.request.requestId
                            +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-\n\n\n\n▼English\n\nThank you for always using artless.\n\n"
                            +props.request.creatorDisplayName
                            +" completed delivery of your work.\nPlease access the website from this URL and check the details.\n\n"
                            +serviceUrl.urlToRequestDetail+"/?reuqestId="+props.request.requestId
                            +"\n\nIf you have any questions or don't know what this mail says, please contact us at the email address below.\n\nartless.gallery.contact@gmail.com\n\n—————————-\nAdministrator of artless\n—————————-"
                            ;
                console.log("adress:"+props.request.requestorMailAddress);
                mail.SendEventTriggerMail(props.request.requestorMailAddress, title, content) ;
                isSent.current = true;
            }
        }
    };

    const completePsd = async () => {
        // 完了後の処理
        // ダウンロードのため、アップロードした画像のURLを取得
        const fileUrl = await storage.ref("images").child(randomWorkPsdName).getDownloadURL();
        await Api.updateRequestStatusWhenDeliverFile(props.request.id, fileUrl, requestStatus.waitReceive);
        //　既存のデータの購入の場合、WorksにPsdファイルも突っ込む
        if(props.request.requestType === requestClass.existUse){
            const dataWork = await Api.getWork(props.request.workId);
            // productFileUrlが空のときだけ突っ込む
            // すでにある場合は、既存のものを正とする
            if(dataWork.productFileUrl === ""){
                await Api.updateWorkProductFileUrl(props.request.workId, fileUrl);
            }
        }

        // 通知は二重チェックしてるので、両方に書いとく
        if(!isSent.current){
            const isNewNotification = await Api.isNewNotification(props.request.id, notificationType.receive);
            if(isNewNotification){
                await Api.createNotificationForConsumer(
                    props.request.requestId, 
                    props.request.requestorId, 
                    props.request.creatorId, 
                    props.request.requestType, 
                    notificationType.receive, 
                    props.request.requestorDisplayName, 
                    props.request.creatorDisplayName, 
                    props.request.creatorProfileImageUrl
                )
                // 依頼者にメール
                let title = props.request.creatorDisplayName+"さんの納品が完了しました/The creator completed delivery of your work";
                let content = "▼Japanese\n(English is below)\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
                            +props.request.creatorDisplayName
                            +"さんの納品が完了しました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
                            +serviceUrl.urlToRequestDetail+"/?reuqestId="+props.request.requestId
                            +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-\n\n\n\n▼English\n\nThank you for always using artless.\n\n"
                            +props.request.creatorDisplayName
                            +" completed delivery of your work.\nPlease access the website from this URL and check the details.\n\n"
                            +serviceUrl.urlToRequestDetail+"/?reuqestId="+props.request.requestId
                            +"\n\nIf you have any questions or don't know what this mail says, please contact us at the email address below.\n\nartless.gallery.contact@gmail.com\n\n—————————-\nAdministrator of artless\n—————————-"
                            ;
                console.log("adress:"+props.request.requestorMailAddress);
                mail.SendEventTriggerMail(props.request.requestorMailAddress, title, content);
                isSent.current = true;
            }
        }
    };


    const handleCheck = () => {
        setIsNsfw(!isNsfw);
    };
    
    
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const moveToThanksPage = () =>{
        history.push(toDeliveComplete);
    }


    const submitButtonComponent = () => {
        // psdを一旦やめる
        //if(isSelectedWorkImage && isSelectedWorkFile){
        if(isSelectedWorkImage){
            if(isSending){
                return(
                    <div className={workregister.agree_btn_area}>
                        <div className={main.loading_area}>
                            <Loader
                                type="ThreeDots"
                                color="#5278FF"
                                height={36}
                                width={36}
                            />
                        </div>
                    </div>
                )    
            }else{
                return(
                    <div className={workregister.agree_btn_area}>
                        <button type="submit" className={main.primary_btn}>
                            作品をアップロードする
                        </button>
                    </div>
                )    
            }
        }else{
            return(
                <div className={workregister.agree_btn_area}>
                    <div className={main.disabled_btn}>作品をアップロードする</div>
                </div>
            )
        
        }
    }

    return(
        <div>
            <h2>作品を納品する</h2>
            <div className={requestdetail.caveat}>
                <h4>作品をアップロードしてください</h4>
                <p>画像データ(ex. png, jpg)をアップロードください。</p>
                {/* <p>通常の画像データ(ex. png, jpg)、Psdファイルの両方をアップロードください。</p> */}
                <p>掲載画像の購入の場合は、掲載時より高画質な画像データをアップロードお願いいたします。</p>
                <p>(すでに掲載時の画像が高画質な場合、お手数ですが、再度同じものアップロードいただくようお願いいたします。)</p>
            </div>
            <div>
                <div>
                    {/* psdを一旦やめる */}
                    {/* <div>
                        <div>
                            <h3>Psdデータ</h3>
                                <label htmlFor="file_upload" className={deliverwork.input_label}>ファイルを選択</label>
                                <input type="file" className={deliverwork.input_button} id="file_upload" onChange={handleWorkPsd} />
                                <div className={deliverwork.selectingfile}>選択中のファイル: {uploadFileName}</div>
                        </div>
                    </div>
                    <br /> */}
                    <h3>通常の画像データ(ex. png, jpg)</h3>
                    <div>
                        {workImagePreviewUrl === ""?
                            <div>
                                <label htmlFor="file">
                                    <div className={workregister.filearea}>
                                        <FontAwesomeIcon icon={['fal', 'plus-circle']} className={workregister.addicon}/>
                                        <div className={workregister.filelabel}>
                                            タップして作品をアップロード
                                        </div>
                                    </div>
                                </label>
                                <input type="file" onChange={handleWorkImage} className={workregister.inputButton} id="file" />
                            </div>
                        :
                            <div>
                                <label htmlFor="file">
                                    <div className={workregister.previewarea}>
                                        <img src={workImagePreviewUrl} alt="uploaded" className={workregister.profile_img}/>
                                    </div>
                                </label>
                                <input type="file" onChange={handleWorkImage} className={workregister.inputButton} id="file" />
                            </div>                    
                        }
                    </div>
                    <br />
                    <div>
                        <Checkbox
                            id="check"
                            onClick={handleCheck}
                            {...label}
                            sx={{
                            color: pink[800],
                            '&.Mui-checked': {
                                color: pink[600],
                            },
                            }}
                        />
                        <label htmlFor="check" >
                        この作品はセンシティブな画像なので非公開にしたいです。
                        </label>
                    </div>
                    <form onSubmit={workRegister}>
                        {submitButtonComponent()}
                        <p>※サイズファイルが大きい場合は、アップロードに時間がかかる場合があります。</p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DeliverWork;