import React, {useState} from 'react';
import { signInWithGoogle } from "../service/firebase";
import * as Logic from "../service/logic";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import loginmodal from '../css/loginmodal.module.css';
import confirmationdialog from '../css/confirmationdialog.module.css';
import toast, { Toaster } from 'react-hot-toast';
import { serviceUrl, eventCode } from '../service/constants';
import { Link } from 'react-router-dom';



//言語切替
import { useTranslation } from 'react-i18next';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginModal(props) {
  const [open, setOpen] = useState(props.openLoginModal);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [t] = useTranslation();

  const handleClose = () => {
    setOpen(false);
    props.closeLoginModal(false);
  };

  const signIn = () => {
    handleDailogClose();
    Logic.eventLogging(props.userInfo, eventCode.doSignInFromWorkDetail, "", "");
    signInWithGoogle();
    setOpen(false);
    props.closeLoginModal(false);
  };

  const handleDailogOpen = () => {
    setDialogOpen(true);
  };

  const handleDailogCloseAndCopyUrl = () => {
    copyText();
    notifyCopied();
    setDialogOpen(false);
  };

  const handleDailogClose = () => {
    setDialogOpen(false);
  };

  const copyText = () => {
    navigator.clipboard.writeText(serviceUrl.url);
  };

  const isInstagram = () =>{
    var userAgent = navigator.userAgent.toLowerCase();
    if(!userAgent.includes("safari") && !userAgent.includes("chrome")){
      return true;
    }else{
      return false;
    }
  }

  const successCopiedMessage =  props.langCode === "ja" ? "サイトURLをコピーしました！":"Copied the URL!";
  const notifyCopied = () => toast.success(successCopiedMessage,
      {
          style: {
              border: '1px solid #94ABFF',
              borderRadius: '10px',
              background: '#ffffff',
              color: '#94ABFF',
          },
      duration: 2000,
      }
  );



  const confirmationDialogComponent = () => {
    return(
        <div>
            <Toaster />
            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDailogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t('dialog_confirmation_before_login_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('dialog_confirmation_before_login_body1')}
                        <br />
                        <br />
                        {t('dialog_confirmation_before_login_body2')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={confirmationdialog.secondary_option} onClick={handleDailogCloseAndCopyUrl}>{t('dialog_confirmation_before_login_secondary_option')}</Button>
                    <Link to="/" className={confirmationdialog.primary_option} onClick={signIn}>{t('dialog_confirmation_before_login_primary_option')}</Link>
                </DialogActions>
            </Dialog>
        </div>
    )
  }


  const switchModalComponentByUserAgent = () => {
    if(isInstagram()){
      console.log("it is an instagram browse");
      return(
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: {
                textAlign: "center",
                width: "360px",
                height: "540px",
                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/artless-for-creators.appspot.com/o/login_background.jpeg?alt=media&token=660ad1b5-4c97-4721-b835-0fd580eb142c)",
                // backgroundSize:"cover", 
                backgroundSize:"95%", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              },
            }}
          >
            <DialogTitle className={loginmodal.title_pc}>{t('login_modal_title')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className={loginmodal.signin_btn} onClick={handleDailogOpen}>{t('login_modal_action')}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )  
    }else{
      console.log("it is not an instagram browse");
      return(
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: {
                textAlign: "center",
                width: "360px",
                height: "540px",
                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/artless-for-creators.appspot.com/o/login_background.jpeg?alt=media&token=660ad1b5-4c97-4721-b835-0fd580eb142c)",
                // backgroundSize:"cover", 
                backgroundSize:"95%", 
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              },
            }}
          >
            <DialogTitle className={loginmodal.title_pc}>{t('login_modal_title')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Link to="/" className={loginmodal.signin_btn} onClick={signIn}>{t('login_modal_action')}</Link>
            </DialogActions>
          </Dialog>
        </div>
      )
    }

    // if(props.isSmartPhoneWidth){
    //   return(
    //     <div>
    //         <Dialog
    //           open={open}
    //           TransitionComponent={Transition}
    //           keepMounted
    //           onClose={handleClose}
    //           aria-describedby="alert-dialog-slide-description"
    //           PaperProps={{
    //             style: {
    //               textAlign: "center",
    //               width: "100%",
    //               height: "100%",
    //               margin: "0px 0px -70px 0px",
    //               backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/artless-for-creators.appspot.com/o/login_background.jpeg?alt=media&token=660ad1b5-4c97-4721-b835-0fd580eb142c)",
    //               backgroundSize:"cover", 
    //               backgroundPosition: "center",
    //               borderTopLeftRadius: "8px",
    //               borderTopRightRadius: "8px",
    //             },
    //           }}
    //         >
    //           <DialogTitle className={loginmodal.title_sp}>{t('login_modal_title')}</DialogTitle>
    //           <DialogContent>
    //             <DialogContentText id="alert-dialog-slide-description">
    //             </DialogContentText>
    //           </DialogContent>
    //           <DialogActions>
    //             <Button className={loginmodal.signin_btn} onClick={handleDailogOpen}>{t('login_modal_action')}</Button>
    //           </DialogActions>
    //         </Dialog>
    //       </div>
    //   )
    // } else {
    //   return(
    //     <div>
    //       <Dialog
    //         open={open}
    //         TransitionComponent={Transition}
    //         keepMounted
    //         onClose={handleClose}
    //         aria-describedby="alert-dialog-slide-description"
    //         PaperProps={{
    //           style: {
    //             textAlign: "center",
    //             width: "360px",
    //             height: "540px",
    //             backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/artless-for-creators.appspot.com/o/login_background.jpeg?alt=media&token=660ad1b5-4c97-4721-b835-0fd580eb142c)",
    //             backgroundSize:"cover", 
    //             backgroundPosition: "center",
    //             borderTopLeftRadius: "8px",
    //             borderTopRightRadius: "8px",
    //             borderBottomLeftRadius: "8px",
    //             borderBottomRightRadius: "8px",
    //           },
    //         }}
    //       >
    //         <DialogTitle className={loginmodal.title_pc}>{t('login_modal_title')}</DialogTitle>
    //         <DialogContent>
    //           <DialogContentText id="alert-dialog-slide-description">
    //           </DialogContentText>
    //         </DialogContent>
    //         <DialogActions>
    //           <Button className={loginmodal.signin_btn} onClick={handleDailogOpen}>{t('login_modal_action')}</Button>
    //         </DialogActions>
    //       </Dialog>
    //     </div>
    //   )
    // }
  }

  return (
    <div>
      {switchModalComponentByUserAgent()}
      {confirmationDialogComponent()}
    </div>
  );
}
