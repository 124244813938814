import React , { useContext } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";
import * as Api from "../service/api";
import dig from "object-dig";
import { Link } from 'react-router-dom';
import main from '../css/main.module.css';
import creatorpage from '../css/creatorpage.module.css';
import termsofuse from '../css/termsofuse.module.css';


//言語切替
import { useTranslation } from 'react-i18next';


function CreatorRegister() {
    const userInfo = useContext(UserInfoContext);
    const [t] = useTranslation();

    const registerCreator = () => {
        if(dig(userInfo, "userInfo", "docId")){
            Api.updateAgreeCreatorRules(userInfo.userInfo.docId, true);
        }
    }
    
    const toCreateMyProfile = {
        pathname: '/myaccount',
        //Steo 2/4以降が画面が使いまわしになるので、初期登録の動線から来た場合は明示する
        search: '?from=initialregister',
    };

    const termsOfUseConponent = () => {
        return(
            <React.Fragment>
                <div className={termsofuse.preface}>
                    {t('termsofuse_preface')}
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_1_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_1_body_initial')}</p>
                    <p>{t('termsofuse_main_1_body_1')}</p>                    
                    <p>{t('termsofuse_main_1_body_2')}</p>                    
                    <p>{t('termsofuse_main_1_body_3')}</p>                    
                    <p>{t('termsofuse_main_1_body_4')}</p>  
                    <p>{t('termsofuse_main_1_body_5')}</p>                   
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_2_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_2_body_1')}</p>                    
                    <p>{t('termsofuse_main_2_body_2')}</p>                    
                    <p>{t('termsofuse_main_2_body_3')}</p>                    
                    <p>{t('termsofuse_main_2_body_4')}</p>  
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_3_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_3_body_1')}</p>                    
                    <p>{t('termsofuse_main_3_body_2')}</p>                    
                    <p>{t('termsofuse_main_3_body_3')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_4_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_4_body_1')}</p>                    
                    <p>{t('termsofuse_main_4_body_2')}</p>                    
                    <p>{t('termsofuse_main_4_body_3')}</p>                    
                    <p>{t('termsofuse_main_4_body_4')}</p>                    
                    <p>{t('termsofuse_main_4_body_5')}</p>                    
                    <p>{t('termsofuse_main_4_body_6')}</p>                    
                    <p>{t('termsofuse_main_4_body_7')}</p>                    
                    <p>{t('termsofuse_main_4_body_8')}</p>                    
                    <p>{t('termsofuse_main_4_body_9')}</p>                    
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_5_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_5_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_6_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_6_body_1')}</p>                    
                    <p>{t('termsofuse_main_6_body_2')}</p>                    
                    <p>{t('termsofuse_main_6_body_3')}</p>                                        
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_7_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_7_body_initial')}</p>   
                    <p>{t('termsofuse_main_7_body_1')}</p>                    
                    <p>{t('termsofuse_main_7_body_2')}</p>                    
                    <p>{t('termsofuse_main_7_body_3')}</p>                    
                    <p>{t('termsofuse_main_7_body_4')}</p>                    
                    <p>{t('termsofuse_main_7_body_5')}</p>                    
                    <p>{t('termsofuse_main_7_body_6')}</p>                    
                    <p>{t('termsofuse_main_7_body_7')}</p>                    
                    <p>{t('termsofuse_main_7_body_8')}</p>                    
                    <p>{t('termsofuse_main_7_body_9')}</p>
                    <p>{t('termsofuse_main_7_body_10')}</p>                    
                    <p>{t('termsofuse_main_7_body_11')}</p>                    
                    <p>{t('termsofuse_main_7_body_12')}</p>                    
                    <p>{t('termsofuse_main_7_body_13')}</p>                    
                    <p>{t('termsofuse_main_7_body_14')}</p>                    
                    <p>{t('termsofuse_main_7_body_15')}</p>                    
                    <p>{t('termsofuse_main_7_body_16')}</p>                    
                    <p>{t('termsofuse_main_7_body_17')}</p>                    
                    <p>{t('termsofuse_main_7_body_18')}</p>
                    <p>{t('termsofuse_main_7_body_19')}</p>                    
                    <p>{t('termsofuse_main_7_body_20')}</p>                    
                    <p>{t('termsofuse_main_7_body_21')}</p>                    
                    <p>{t('termsofuse_main_7_body_22')}</p>
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_7.5_important_title.5_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_7.5_important_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_8_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_8_body_initial')}</p>
                    <p>{t('termsofuse_main_8_body_1')}</p>              
                    <p>{t('termsofuse_main_8_body_2')}</p>              
                    <p>{t('termsofuse_main_8_body_3')}</p>              
                    <p>{t('termsofuse_main_8_body_4')}</p>              
                    <p>{t('termsofuse_main_8_body_5')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_9_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_9_body_1')}</p>              
                    <p>{t('termsofuse_main_9_body_2')}</p>              
                    <p>{t('termsofuse_main_9_body_3')}</p>              
                    <p>{t('termsofuse_main_9_body_4')}</p>
                    <p>{t('termsofuse_main_9_body_5')}</p>              
                    <p>{t('termsofuse_main_9_body_6')}</p>              
                    <p>{t('termsofuse_main_9_body_7')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_10_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_10_body_1')}</p>              
                    <p>{t('termsofuse_main_10_body_2')}</p>              
                    <p>{t('termsofuse_main_10_body_3')}</p>       
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_11_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_11_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_12_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_12_body_initial')}</p>              
                </div>
                <div className={termsofuse.item_title}>
                    {t('termsofuse_main_13_title')}
                </div>
                <div className={termsofuse.item_body}>
                    <p>{t('termsofuse_main_13_body_initial')}</p>              
                </div>

            </React.Fragment>
        )
    }
    

    const creatorRegisterMainConponent = () => {
        if(dig(userInfo, "userInfo", "uid")){
            return(
                <React.Fragment>
                    <h2>利用規約確認</h2>
                    <h3 className={main.step}>STEP1/4</h3>
                    <div className={creatorpage.preface}>
                    下記の利用規約を確認の上、登録ボタンをタップしてください。
                    </div>
                    <div className={creatorpage.rulebox}>
                        <div>
                            {termsOfUseConponent()}
                        </div>
                    </div>
                    <div className={creatorpage.agree_btn_area}>
                        <Link 
                            to={toCreateMyProfile} 
                            className={main.primary_btn}
                            onClick={registerCreator}
                        >
                            利用規約に同意して始める
                        </Link>
                    </div>
                </React.Fragment>      
            )      
        }else{
            return(
                <div>
                    {t('confirm_after_login')}
                </div>
            )
        }

    }

    return(
        <div>
            <br />
            {creatorRegisterMainConponent()}
        </div>
    )
}

export default CreatorRegister;