import React , { useState,useEffect } from "react";
import { auth } from "../service/firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  

    //ユーザー情報。初期値はnull
    const [currentUser, setCurrentUser] = useState(null);
    
    //ログインユーザーの情報が変化したら詰める
    useEffect(async() => {
      auth.onAuthStateChanged(await setCurrentUser);
    }, []);
  
    return (
      // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
      <AuthContext.Provider value={{ currentUser }}>
        {children}
      </AuthContext.Provider>
    );
  };
  export default AuthProvider;