import * as React from "react";
import {
    Link,
  } from 'react-router-dom';

import main from '../css/main.module.css';


  const SinglSquareImageList = (props) => {

    return (
        <div className={main.flex}>
            {props.images.map((image) => {
                const to = {
                    pathname: '/workdetail',
                    search: '?workId='+image.workId+'&creatorId='+image.authorId,
                };
                return (
                        <Link to={to} key={image.workId}>
                            <div className={main.list_img}>
                                <img src={image.imageUrl} alt="" />
                            </div>
                        </Link>
                );
            })}
        </div>
    );
}

export default SinglSquareImageList;

