import React from "react";
import DialogTest from "./DialogTest";
import ModalTest from "./ModalTest";
import LoginModal from "./LoginModal";
import SwitchLanguageModalTest from "./SwitchLanguageModalTest";
import SwitchLanguageModalTest2 from "./SwitchLanguageModalTest2";

const Test = () => {    
    const testDate = () => {
        var now = new Date();
        // getMonthは0~11
        var timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString();
        return timestr;
    }

    const displayUserAgent = () =>{
        var userAgent = navigator.userAgent.toLowerCase();
        console.log("userAgent: "+ userAgent)
        return(
            <div>
                {userAgent}
            </div>
        )
        // if(!userAgent.includes("safari") && !userAgent.includes("chrome")){
        //   return true;
        // }else{
        //   return false;
        // }
      }
    

    return(
        <React.Fragment>
            <div>
                <h2>テスト画面</h2>
                {testDate()}
            </div>
            <div>
                <a href="googlechrome://google.co.jp/">クローム</a>
            </div>
            <div>
                <a href="x-web-search://?artless.gallery">Safari</a>
            </div>
            <div>
                <a href="safari-https://artless.gallery">Safari2</a>
            </div>
            <div>
                <a href="https://artless.gallery" target="_blank" rel="noopener noreferrer">Safari3</a>
            </div>
            <div>
                {displayUserAgent()}
            </div>
            <br />
            <br />
            <div>
                {SwitchLanguageModalTest()}
            </div>
            <br />
            <br />
            <div>
                {SwitchLanguageModalTest2()}
            </div>
        </React.Fragment>
    )
}

export default Test;