import React , {useState, useContext } from "react";
import dig from "object-dig";
import { AuthContext } from "../providers/AuthProvider";
import loadImage from 'blueimp-load-image';
import main from '../css/main.module.css';
import workregister from '../css/workregister.module.css';
import { Link } from 'react-router-dom';
import firebase, { storage } from "../service/firebase";
import { adminUsers } from '../service/constants';


function ManualImageCompressor() {

    const currentUser = useContext(AuthContext);
    const [workImage, setWorkImage] = useState("");
    let randomWorkImageName = "";
    let processedNameForGetURl =""
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    
    
    const handleWorkImage = event => {
        console.log(event);
        const image = event.target.files[0];
        setWorkImage(image);
    };

    const workRegister = async () => {
        if (workImage === "") {
            console.log("ファイルが選択されていません");
        }else{
            if(dig(currentUser,"currentUser", "uid")){   
                var now = new Date();
                var timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString();
        
                console.log("yyyyy: "+workImage.name);
                var processedName = (workImage.name.slice(0,-5)+"-min.png").toString();
                console.log("yyyya: "+processedName);
        
                // アップロード前にファイル名をランダム文字列に書き換える
                // 同じファイル名のファイルはcloudstorageが受け付けない
                // あともし同じ文字列があった場合に再度ランダマイズ？
                randomWorkImageName = Math.random().toString(32).substring(2);
                processedNameForGetURl = processedName;
     
                const canvas = await loadImage(workImage, {
                    maxWidth: 1200,
                    canvas: true,
                });
                canvas.image.toBlob((blob) => {
                    const uploadProfTask = storage.ref(`/images/${processedName}`).put(blob);
                    uploadProfTask.on(
                        firebase.storage.TaskEvent.STATE_CHANGED,
                        next,
                        error,
                        completeWorkImage
                    );        
                }, workImage.type);
            }else{
                console.log("uidがないため、失敗");
            }
        }
    };

    const next = snapshot => {
        // 進行中のsnapshotを得る
        // アップロードの進行度を表示
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        console.log(snapshot);
    };
    
    const error = error => {
        // エラーハンドリング
        console.log(error);
    };
    
    const completeWorkImage = async () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        let imageUrl = "";
        if(workImage !== ""){
            imageUrl = await storage.ref("images").child(processedNameForGetURl).getDownloadURL();
            console.log("ttttt: "+imageUrl)
        }
        setWorkImage("");
    };
    
    const imageCompoent = () =>{
        return(
            <div>
                <input type="file" onChange={handleWorkImage} id="file" />
            </div>
        )
    };

    const uploadCompoent = () =>{
        if(dig(currentUser,"currentUser", "uid")){  
            if(currentUser.currentUser.uid === adminUsers.admin_userId){
                return(
                    <React.Fragment>
                        <div>
                            manual image compressor
                        </div>
                        <br />
                        <div className={workregister.image_area}>
                            {imageCompoent()}
                        </div>
                        <div className={workregister.agree_btn_area}>
                            <div className={workregister.register_btn_area}>
                                <div>
                                    <Link 
                                        to={stayHere} 
                                        className={main.primary_btn}
                                        onClick={workRegister}
                                    >
                                        アップロード
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            } else {
                return(
                    <div>
                        権限がありません
                    </div>
                )    
            }
        }else{
            return(
                <div>
                    ログインしてください
                </div>
            )
        }
    };

    
    const stayHere = {
        pathname: '/manualimagecompressor',
    };    

    return(
        <div>
            {uploadCompoent()}
        </div>
    )
}

export default ManualImageCompressor;