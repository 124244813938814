import React , { useEffect, useState, useContext } from "react";
import * as Api from "../service/api";
import * as Validation from "../service/validation";

import { UserInfoContext } from "../providers/UserInfoProvider";

import dig from "object-dig";
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import firebase from "../service/firebase";
import { functionMethod, contactInformAddress } from '../service/constants';
import main from '../css/main.module.css';
import contact from '../css/contact.module.css';
import toast, { Toaster } from 'react-hot-toast';
import GoBack from "./GoBack";


//言語切替
import { useTranslation } from 'react-i18next';


require("firebase/functions");



function ContactForm() {

    const userInfo = useContext(UserInfoContext);

    const [userId, setUserId] = useState("");
    const [langCode, setLangCode] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");

    // validation用
    const [isValidName, setIsValidName] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidContent, setIsValidContent] = useState(true);

    const [t] = useTranslation();

    const history = useHistory();


    useEffect(()=>{
        if(dig(userInfo,"userInfo", "uid")){
          setUserId(userInfo.userInfo.uid);
          setLangCode(userInfo.userInfo.langCode);
        }
    }, [userInfo]);  

    // validation check
    const checkInputName = (value) =>{
      var isValid = Validation.checkValidName(value);
      setIsValidName(isValid);
    }

    const checkInputEmail = (value) =>{
      var isValid = Validation.checkValidEmail(value);
      setIsValidEmail(isValid);
    }

    const checkInputContent = (value) =>{
      var isValid = Validation.checkValidContent(value);
      setIsValidContent(isValid);
    }

    const onSubmit = event => {
        event.preventDefault();
        let data = {};
        data.name = event.target.name.value;
        data.email = event.target.email.value;
        data.content = event.target.content.value;

        // 問い合わせ対応者に送信する用のコンテンツ
        let title = data.name+`さまよりお問い合わせがありました`;
        let content = "お名前:"+"\n"+data.name+"\n\n"
        +"お客さまのメールアドレス:"+"\n"+data.email+"\n\n"
        +"お問い合わせ内容:"+"\n"+data.content+"\n\n"
        let emailAddress = contactInformAddress.contactMailAdress;

        // 未ログインで問い合わせしたいケースもある
        // userIdがないときは未ログインユーザー
        //　問い合わせるときにfirestoreにもデータ入れる
        Api.addContact(data.name, userId, data.email, data.content, false);
        // xx様からお問い合わせがありました
        Api.sendEventTriggerMail(emailAddress, title, content);
        // 旧メールは一旦コメントアウト
        // let sendMail = firebase.app().functions('asia-northeast1').httpsCallable(functionMethod.sendContactMail);
        // sendMail(data);
        notify();
        event.target.name.value = "";
        event.target.email.value = "";
        event.target.content.value = "";
        event.target.value = "";  
        history.push("/");
    };

    const successMessage = langCode === "ja" ? "お問い合わせを送信しました":"Send Successfully";
    const notify = () => toast.success(successMessage,
    {
        style: {
            border: '1px solid #94ABFF',
            borderRadius: '10px',
            background: '#ffffff',
            color: '#94ABFF',
        },
    duration: 2000,
    }
);

    return(
        <React.Fragment>
          <Toaster />
          <div className={main.wrapper}>
          <GoBack />
          <h2>{t('contact_title')}</h2>
          <div className={contact.contact_form_wrap}>
            <form onSubmit={onSubmit} className={contact.contact_form}>
              <TextField 
                name="name" 
                error={!isValidName}
                label={t('contact_form_name')}
                type="text" 
                required 
                className={contact.input_field}  
                value={name} 
                onChange={(event)=> setName(event.currentTarget.value)}
                onBlur={(event)=> checkInputName(event.currentTarget.value)}
              />
              <TextField 
                name="email" 
                error={!isValidEmail}
                label={t('contact_form_email')}
                type="mail" 
                required 
                className={contact.input_field}   
                value={email} 
                onChange={(event)=> setEmail(event.currentTarget.value)}
                onBlur={(event)=> checkInputEmail(event.currentTarget.value)}
              />
              <br />
              <TextField
                required
                name="content"
                error={!isValidContent}
                label={t('contact_form_content')}
                multiline
                rows="8"
                margin="normal"
                variant="outlined"
                className={contact.input_field} 
                value={content} 
                onChange={(event)=> setContent(event.currentTarget.value)}
                onBlur={(event)=> checkInputContent(event.currentTarget.value)}
              />
              <br />
              <div className={contact.submit_button_area}>
                <button variant="contained" color="primary" type="submit" className={main.primary_btn} >
                  {t('contact_form_submit_button')}
                </button>
              </div>
            </form>
          </div>
          </div>
      </React.Fragment>
    )
}

export default ContactForm;