import React from "react";
import specilaizedcommercial from '../css/specilaizedcommercial.module.css';
import GoBack from "./GoBack";

//言語切替
import { useTranslation } from 'react-i18next';



function SpecifiedCommercial() {
    const [t] = useTranslation();

    return(
        <div>
            <GoBack />
            <h2>{t('company_title')}</h2>
            <div className={specilaizedcommercial.contents}>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_name_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_name_content')}
                    </div>
                    <div className={specilaizedcommercial.info_attention}>
                        {t('company_info_name_attention')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_representative_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_representative_content')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_address_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_address_content_zipcode')}
                        <br />
                        {t('specilaizedcommercial_info_address_content_address')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_phonenumber_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        080-1442-4650
                        <br />
                        {t('specilaizedcommercial_info_phonenumber_content_acceptabletime')}
                    </div>
                    <div className={specilaizedcommercial.info_attention}>
                        {t('specilaizedcommercial_info_phonenumber_content_attention')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_email_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        artless.gallery.contact@gmail.com
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_homepage_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        https://artless.gallery
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_price_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_price_content')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_fee_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_fee_content')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_paymentmethod_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_paymentmethod_content')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_paymenttiming_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_paymenttiming_content')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_receiveitemtiming_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_receiveitemtiming_content')}
                    </div>
                </div>
                <div className={specilaizedcommercial.info_item}>
                    <div className={specilaizedcommercial.info_item_title}>
                        {t('specilaizedcommercial_info_cancel_title')}
                    </div>
                    <div className={specilaizedcommercial.info_item_content}>
                        {t('specilaizedcommercial_info_cancel_content')}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SpecifiedCommercial;