import React from "react";
import initialloadingdispaly from '../css/initialloadingdispaly.module.css';
import servicelogo from '../img/logo.png';

function InitialLoadingDisplay() {

    return(        
        <div className={initialloadingdispaly.loading_display_flex_box}>
            <div className={initialloadingdispaly.loading_display_flex_img}>
                <img src={servicelogo} alt="artless" />
            </div>
        </div>
    )
}

export default InitialLoadingDisplay;