import React from "react";
import { Link, useLocation } from 'react-router-dom';
import thanks from '../css/thanks.module.css';
import main from '../css/main.module.css';

//言語切替
import { useTranslation } from 'react-i18next';



// Thanksは1ファイルにまとめる
// どこから来たかで出すテキストをを管理する
function Thanks() {

    const [t] = useTranslation();

    // どこから来たか確認
    const location = useLocation();
    var queryString = location.search.substring(1);
    var from = queryString.substring(queryString.indexOf('=') + 1);

    const toTopPageButton = () => {
        return(
            <Link to='/' className={main.primary_btn}>TOPページへ</Link>
        )
    }

    return(
        <React.Fragment>
            <div>
                {(() => {
                    if (from === "initialregister") {
                        return(
                            <div>
                                <h2>クリエイター手続き完了</h2>
                                <div className={thanks.message}>
                                    <p>
                                        手続きありがとうございました！<br />
                                    </p>
                                </div>
                                {toTopPageButton()}
                            </div>
                        )
                    } else if (from === "requestcreate"){
                        return(
                            <div>
                                <h2>{t('thanks_title_reuqest')}</h2>
                                <div className={thanks.message}>
                                    <p>
                                        {t('thanks_text_reuqest')}<br />
                                    </p>
                                </div>
                                {toTopPageButton()}
                            </div>
                        )
                    } else if (from === "contact"){
                        return(
                            <div>
                                <h2>{t('thanks_title_contact')}</h2>
                                <div className={thanks.message}>
                                    <p>
                                        {t('thanks_text_contact')}<br />
                                    </p>
                                </div>
                                {toTopPageButton()}
                            </div>
                        )
                    } else if (from === "fixRequestAccpet"){
                        return(
                            <div>
                                <h2>リクエスト承諾完了</h2>
                                <div className={thanks.message}>
                                    <p>
                                        リクエストを承諾しました！<br />
                                        依頼者の支払いをお待ちください！<br />
                                    </p>
                                </div>
                                {toTopPageButton()}
                            </div>
                        )
                    } else if(from === "deliverComplete"){
                        return(
                            <div>
                                <h2>納品完了</h2>
                                <div className={thanks.message}>
                                    <p>
                                        納品お疲れさまでした！<br />
                                        依頼者の受け取りをお待ち下さい！<br />
                                    </p>
                                </div>
                                {toTopPageButton()}
                            </div>
                        )
                    }
                })()}
            </div>
        </React.Fragment>
    )
}

export default Thanks;