import React from "react";
import main from '../css/main.module.css';
import receivedeliverwork from '../css/receivedeliverwork.module.css';

import useFileEnhancer from './EnhanceFile';

//言語切替
import { useTranslation } from 'react-i18next';

function ConfirmDeliverWork(props) {

    const [t] = useTranslation();

    const DownloadProductFile = () => {
        const enhance = useFileEnhancer();
      
        return (
            <div>
                <div className={receivedeliverwork.btn}>
                    <button
                        className={main.primary_btn}
                        onClick={() => {
                            enhance.downloadImage(enhance.canv, enhance.img, '1');
                        }}
                        type="button"
                    >
                        {t('download_psd')}
                    </button>
                </div>
                <canvas ref={enhance.canv} style={{ display: 'none' }}>
                    <img
                        ref={enhance.img}
                        src={props.request.productFileUrl}
                        alt=""
                        crossOrigin="anonymous"
                    />
                </canvas>
            </div>
        );
      };


    return(
        <div>
            <div>
                <div className={receivedeliverwork.image}>
                    <img src={props.request.productImageUrl} alt="" />
                </div>
                    {DownloadProductFile()}
            </div>
        </div>
    )
}

export default ConfirmDeliverWork;