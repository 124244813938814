import React from "react";
import company from '../css/company.module.css';
import GoBack from "./GoBack";



//言語切替
import { useTranslation } from 'react-i18next';



function Company() {
    const [t] = useTranslation();

    return(
        <div>
            <GoBack />
            <h2>{t('company_title')}</h2>
            <div className={company.contents}>
                <div className={company.info_item}>
                    <div className={company.info_item_title}>
                        {t('company_info_name_title')}
                    </div>
                    <div className={company.info_item_content}>
                        {t('company_info_name_content')}
                    </div>
                    <div className={company.info_attention}>
                        {t('company_info_name_attention')}
                    </div>
                </div>
                <div className={company.info_item}>
                    <div className={company.info_item_title}>
                        {t('company_info_representative_title')}
                    </div>
                    <div className={company.info_item_content}>
                        {t('company_info_representative_content')}
                    </div>
                </div>
                <div className={company.info_item}>
                    <div className={company.info_item_title}>
                        {t('company_info_address_title')}
                    </div>
                    <div className={company.info_item_content}>
                        {t('company_info_address_content_zipcode')}
                        <br />
                        {t('company_info_address_content_address')}
                    </div>
                </div>
                <div className={company.info_item}>
                    <div className={company.info_item_title}>
                        {t('company_info_startdate_title')}
                    </div>
                    <div className={company.info_item_content}>
                        2021/12/01
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company;