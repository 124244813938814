export const collectionName = {
  follows: 'follows',
  users: 'users',
  likes: 'likes',
  notifications: 'notifications',
  requests: 'requests',
  works: 'works',
  sales: 'sales',
  bankaccounts: 'bankaccounts',
  payment_records: 'payment_records', 
  contacts: 'contacts',
  eventlogs: 'eventlogs',
  workviews: 'workviews',
  mail: "mail"
}

export const requestStatus = {
  beforeConfirm: 'before_confirm',
  cancel: 'cancel',
  waitPayment: 'wait_payment',
  inCreating: 'in_creating',
  waitReceive: 'wait_receive',
  done: 'done',
}

export const notificationType = {
  // for creator
  confirmRequest: 'confirm_request',
  startCreate: 'start_create',
  done: 'done',

  // for consumaer
  cancel: 'cancel',
  payment: 'payment',
  receive: 'receive',
}

export const requestClass = {
  original: 'original',
  icon: 'icon',
  portrait: 'portrait',
  existUse: 'exist_use',
}

export const objectGender = {
  male: 'male',
  female: 'female',
  anythingok: 'anythingok',
}

export const objectArea = {
  face: 'face',
  bust: 'bust',
  full: 'full',
  anythingok: 'anythingok',
}

export const usePurpose = {
  personal: 'personal',
  snsicon: 'snsicon',
  commercial: 'commercial',
}

export const imageOrientation = {
  vertical: 'vertical',
  horizontal: 'horizontal',
  anythingok: 'anythingok',
}


export const initialProfileImageUrl = {
  url: 'https://firebasestorage.googleapis.com/v0/b/artless-for-creators.appspot.com/o/images%2Fprofile_initial.jpg?alt=media&token=ec317a0e-134f-405a-ac61-b6880a8cc56c',
}

// backend side
export const apiKey = {
  stripeApiPublicKeyDev: 'pk_test_51JRvtILyAnrbCLaqjuiBwY0UAO6yNfafW4WmoTpQixD9036JDDlpEh6ZzPSz2GNpiUSvxpzZHUfvPr2WnRIyHP6N00jrIxAmnN',
  stripeApiPublicKeyProd: 'pk_live_51JRvtILyAnrbCLaqym9VBdrW16lhO6yOn1hjsHsX5YQfrJ55AtyNp6nDaZeCWESYD07R4prcxdFkmqs62o1ccDc800Y36ZQxDM',
}

export const functionsUrl = {
  checkoutUrl: 'https://asia-northeast1-artless-for-creators.cloudfunctions.net/charge',
  checkoutPaymentIntentUrl: 'https://asia-northeast1-artless-for-creators.cloudfunctions.net/chargePaymentIntent',
  checkoutPaymentSessionUrl: 'https://asia-northeast1-artless-for-creators.cloudfunctions.net/createPaymentSession',
}
export const functionMethod = {
  createPaymentSession: 'createPaymentSession',
  sendEventTriggerMail: 'sendEventTriggerMail',
  sendContactMail: 'sendContactMail',
}

export const serviceUrl = {
  url: 'https://artless.gallery/',
  urlToRequestDetail: 'https://artless.gallery/requestdetail',
}

export const feeRate = {
  tax: 0.1,
  businessFee: 0.1,
  paymentFee: 0.036,
}

export const salesStatus = {
  undone : "undone",
  done: "done",
}
export const adminUsers = {
  admin_userId: "znbO3twBhchHels5TMtkcyUQ4Xh2",
}

export const dataStatus = {
  unregistered: "unregistered",
}

export const languageCode = {
  english: "en",
  japanese: "ja",
  chinese: "zh",
  taiwan: "tw",
}

export const tasteCode = {
  cute: "cute",
  pop: "pop",
  beautiful: "beautiful",
  cool: "cool",
  surreal: "surreal",
  artistic: "artistic",
  ennui: "ennui"
}

export const decryptKey = {
  key: "evoli614krtys!@#$"
}

export const excludeAuthorId = {
  sample: "4HvqLfVcJshP3VQZrv5mjZJpgug1",
  kaname: "kMHcSSaqzZMfU8y0P3UmPGJCjlK2"
}

export const eventCode = {
  firstVisit: "firstVisit",
  doSignIn: "do_sign_in",
  doSignInFromWorkDetail: "do_sign_in_from_work_detail",
  tapSingin: "tap_singin",
  closeSigninDialog: "close_signin_dialog",
  closeSigninDialogByCopyUrl: "close_signin_dialog_by_copy_url",
  viewWorkDetail: "view_work_detail",
  viewCreatorDetail: "view_creator_detail",
  viewCreatorList: "view_creator_list",
  tapRequestCreateButton: "tap_request_create_button",
  tapRequestUseButton: "tap_request_use_button",
  agreeRequestCreate: "agree_request_create",
  agreeRequestUse: "agree_request_use",
  tapLike:"tap_like",
  tapFollow:"tap_follow",
  openLoginModalOnWorkDetail: "open_login_modal_on_work_detail",
  tapSinginOnWorkDetail: "tap_singin_on_work_detail",
  closeSigninDialogOnWorkDetail: "close_signin_dialog_on_work_detail",
  closeSigninDialogByCopyUrlOnWorkDetail: "close_signin_dialog_by_copy_url_on_work_detail",
  tapSearchCategory: "tapSearchCategory",
}

export const subject = {
  anonymous: "anonymous"
}

export const queryParameterKeys = {
  kv_path: "kv_path",
  langCode: "langCode",
  trackingId: "trackingId",
  workId: "workId",
  creatorId: "creatorId",
}

export const contactInformAddress = {
  contactMailAdress: "artless.gallery.contact@gmail.com",
}
