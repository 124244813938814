import React , { useState, useEffect }from 'react';
import './App.css';
import main from './css/main.module.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './service/firebase'
import { AuthProvider } from "./providers/AuthProvider";
import { UserInfoProvider } from './providers/UserInfoProvider';
import InitialLoadingDisplay from "./components/InitialLoadingDisplay";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Footer from "./components/Footer";
import About from "./components/About";
import CreatorRegister from "./components/CreatorRegister";
import SelectCategory from "./components/SelectCategory";
import Likes from "./components/Likes";
import Follows from "./components/Follows";
import Creators from "./components/Creators";
import MyAccount from './components/MyAccount';
import Contact from './components/Contact';
import TermsOfUse from './components/TermsOfUse';
import Guideline from './components/Guideline';
import Company from './components/Company';
import SpecifiedCommercial from './components/SpecifiedCommercial';
import WorkDetail from './components/WorkDetail';
import CreatorDetail from './components/CreatorDetail';
import Test from './components/Test';
import Checkout from './components/Checkout';
import Thanks from './components/Thanks';
import Notifications from './components/Notifications';
import RequestManage from './components/RequestManage';
import RequestCreate from './components/RequestCreate';
import RequestUse from './components/RequestUse';
import RequestHistory from "./components/RequestHistory";
import RequestDetail from "./components/RequestDetail";
import PaymentSuccess from "./components/PaymentSuccess";
import AdminSalesManage from "./components/AdminSalesManage";
import WorkManage from "./components/WorkManage";
import HowToUse from "./components/HowToUse";
import SearchResult from "./components/SearchResult";
import SalesManage from './components/SalesManage';
import WorkRegister from './components/WorkRegister';
import BankAccountRegister from './components/BankAccountRegister';
import ManualDecrypt from "./components/ManualDecrypt";
import ManualImageCompressor from "./components/ManualImageCompressor";
import OurStory from "./components/OurStory";


import toast, { Toaster } from 'react-hot-toast';


// Fontawesomeのセットアップ(1回呼べばOK)
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

library.add(fal);
library.add(far);
library.add(fas);




//　langはcurrentUseerからフラグ取得して全テキスト管理する
// isJapaneseフラグをuserに持たせる
//開発環境で2回レンダリングされるのは問題ない
function App() {
  // ヘッダー用のクリエイターフラグの書き換えを親コンポーネントで管理
  const [isCreator, setIsCreator] = useState(false);
  const changeCreatorFlg = (flg) => {
    setIsCreator(flg);
  }
  
  // notification countは他でも呼ぶので、親コンポーネントで管理
  const [notificationCount, setNotificationCount] =useState();
  const changeNotificationCount = (count) => {
    setNotificationCount(count);
  }

  // 言語コードも親に持たせる
  const [langCode, setLangCode] = useState("ja");
  const changeLangCode = (code) => {
    setLangCode(code);
  }

  const changeCreatorFlgAndLangCodeAndNotificationCount = (flg, code, count) =>{
    setIsCreator(flg);
    setLangCode(code);
    setNotificationCount(count);
  }

  const [allWorksListLatestOrder, setAllWorksListLatestOrder] = useState([]);
  const updateAllWorksListLatestOrder = (data) => {
    setAllWorksListLatestOrder(data);
  }

  const [allWorksListRadomized, setAllWorksListRadomized] = useState([]);
  const updateAllWorksListRandomized= (data) => {
    setAllWorksListRadomized(data);
  }

  const [lastIndexForRandomized, setLastIndexForRandomized] = useState(0);
  const updateLastIndexForRandomized= (data) => {
    setLastIndexForRandomized(data);
  }

  const [lastIndexForLatestOrder, setLastIndexForLatestOrder] = useState(0);
  const updateLastIndexForLatestOrder= (data) => {
    setLastIndexForLatestOrder(data);
  }

  const [onRecommendTab, setOnRecommendTab] = useState(true);
  const updateOnRecommendTab= (data) => {
    setOnRecommendTab(data);
  }

  const [lastViewItemIndex, setLastViewItemIndex] = useState("");
  const updateLastViewItemIndex= (data) => {
    setLastViewItemIndex(data);
  }

  const [keyvisualPath, setKeyvisualPath] = useState("");
  const updateKeyvisualPath= (data) => {
    setKeyvisualPath(data);
  }


  const [isLoading, setIsLoading] = useState(true);

  // function openWindow(windowName, url) {
  //   // 指定のウィンドウ名で空の URL を開いてみる
  //   var win = window.open('', windowName);
    
  //   // 開いたウィンドウの URL が about:blank だったら、空ウィンドウが開けたことになる
  //   if(win.location.href === 'about:blank') {
  //     // 空ウィンドウが開けた = その名前のウィンドウはそれまで存在していなかったので
  //     // 開いたウィンドウの location.href に開きたいページを指定する
  //     win.location.href = url;
  //   }
  //   else {
  //     // ウィンドウの URL が about:blank ではなかった場合、既にそのウィンドウは存在している
  //     // この場合も変数 win にはウィンドウの参照が取得できているので、以下のように掴んだウィンドウを閉じて開き直したりできる
  //     win.close();
  //     window.open(url, windowName);
  //   }
  // }

  window.addEventListener("load",function() {
    // ローディング画面の高さ調整要のview height
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    setTimeout(setIsLoading(false), 50);
  });
  
  return (
    <AuthProvider>
      <UserInfoProvider>
      {/* <HiddenUserInfo></HiddenUserInfo> */}
      <BrowserRouter>
        {isLoading?
          <InitialLoadingDisplay />
        :
          <div></div>
        }
        <div className={main.body}>
          {isLoading?
            <div></div>
          :
            <Header 
              notificationCount={notificationCount}
              isCreator={isCreator}
              langCode={langCode}
              changeLangCode={changeLangCode}
              changeCreatorFlgAndLangCodeAndNotificationCount={changeCreatorFlgAndLangCodeAndNotificationCount}
              isLoading={isLoading}
            >
            </Header>
          }
          <div className={main.wrapper}>
            {/* Appに入れとくと遷移先にもtoast出せる */}
            <Toaster/>
            <Switch>
              <Route 
                exact path='/' 
                render={props => <MainContent 
                                    updateAllWorksListLatestOrder={updateAllWorksListLatestOrder} 
                                    allWorksListLatestOrder={allWorksListLatestOrder}
                                    updateAllWorksListRandomized={updateAllWorksListRandomized}
                                    allWorksListRadomized={allWorksListRadomized}
                                    lastIndexForRandomized={lastIndexForRandomized}
                                    updateLastIndexForRandomized={updateLastIndexForRandomized}
                                    lastIndexForLatestOrder={lastIndexForLatestOrder}
                                    updateLastIndexForLatestOrder={updateLastIndexForLatestOrder}
                                    onRecommendTab={onRecommendTab}
                                    updateOnRecommendTab={updateOnRecommendTab}
                                    lastViewItemIndex={lastViewItemIndex}
                                    updateLastViewItemIndex={updateLastViewItemIndex}
                                    keyvisualPath={keyvisualPath}
                                    updateKeyvisualPath={updateKeyvisualPath}
                                    langCode={langCode}
                                    {...props} 
                                  />
                      }
              />
              <Route path='/creatorregister' component={CreatorRegister} />
              <Route 
                path='/profile/:roomId' 
                render={props => <CreatorDetail langCode={langCode} {...props} />}
              />
              <Route path='/requestmanage' component={RequestManage} />
              <Route path='/salesmanage' component={SalesManage} />
              <Route path='/likes' component={Likes} />
              <Route path='/follows' component={Follows} />
              <Route path='/creators' component={Creators} />
              <Route path='/myaccount' component={MyAccount} />
              <Route path='/contact' component={Contact} />
              <Route 
                path='/workdetail' 
                render={props => <WorkDetail langCode={langCode} {...props} />}
              />
              <Route path='/requestcreate' component={RequestCreate}/>
              <Route path='/requestuse' component={RequestUse}/>
              <Route path='/requestdetail' component={RequestDetail}/>
              <Route path='/requesthistory' component={RequestHistory} />
              <Route path='/about' component={About} />
              <Route path='/termsofuse' component={TermsOfUse} />
              <Route path='/guideline' component={Guideline}/>
              <Route path='/company' component={Company}/>
              <Route path='/specifiedcommercial' component={SpecifiedCommercial}/>
              <Route path='/thanks' component={Thanks}/>
              <Route path='/workregister' component={WorkRegister}/>
              <Route path='/howtouse' component={HowToUse}/>
              <Route path='/selectcategory' component={SelectCategory}/>
              <Route path='/searchresult' component={SearchResult}/>
              <Route 
                path='/bankaccountregister' 
                render={props => <BankAccountRegister changeCreatorFlg={changeCreatorFlg} {...props} />}
              />
              <Route path='/payment_success' component={PaymentSuccess}/>
              <Route path='/workmanage' component={WorkManage}/>
              <Route path='/admin_salesmanage' component={AdminSalesManage}/>
              <Route
                path='/notifications'
                render={props => <Notifications changeNotificationCount={changeNotificationCount} {...props} />}
              />

              <Route path='/manualdecrypt' component={ManualDecrypt}/>
              <Route path='/manualimagecompressor' component={ManualImageCompressor}/>
              <Route path='/test' component={Test}/>
              <Route path='/checkout' component={Checkout}/>
              <Route path='/ourstory' component={OurStory}/>
            </Switch>
          </div>
          <Footer></Footer>
        </div>
      </BrowserRouter>
      </UserInfoProvider>
    </AuthProvider>
  );
}

export default App;
