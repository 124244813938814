import React , { useState, useContext, useEffect } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";
import workmanage from '../css/workmanage.module.css';
import * as Api from "../service/api";
import { Link } from 'react-router-dom';
import dig from "object-dig";
import main from '../css/main.module.css';
import GoBack from "./GoBack";

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//言語切替
import { useTranslation } from 'react-i18next';

const moment = require('moment');





function WorkManage() {
    const userInfo = useContext(UserInfoContext);
    const [works, setWorks] = useState([]);
    const [lastDocId, setLastDocId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isAlldisplayed, setIsAlldisplayed] = useState(false);

    const [t] = useTranslation();

    useEffect(()=>{
        const fetch = async() =>{
            // 必要な情報をセット
            if(dig(userInfo, "userInfo", "uid")){
                if(userInfo.userInfo.isCreator){
                    fetchWorks();
                }
            }  
        } 
        fetch();
    },[userInfo]);  

    const fetchWorks = async() =>{
        const data = await Api.getSomeWorksOfCreator(userInfo.userInfo.uid);
        setWorks(data);
        setLastDocId(data[data.length - 1].workId);
    }

    const fetchWorksMore = async() =>{
        setIsLoading(true);
        const data = await Api.getSomeWorksOfCreatorMore(userInfo.userInfo.uid, lastDocId);
        if(data.length > 0){
            const combined = works.concat(data);
            setWorks(combined);
            setLastDocId(data[data.length - 1].workId);
            setIsLoading(false);
        }else{
            setIsAlldisplayed(true);
        }
    }



    const toWorkRegister = {
        pathname: '/workregister',
        //Steo 2/4以降が画面が使いまわしになるので、初期登録の動線から来た場合は明示する
        search: '?from=list&workId=',
    };

    const seeMoreButtonComponent = () =>{
        if(isAlldisplayed){
            return(
                <div className={main.alreadydisplayed}>
                    No more works.
                </div>
            )
        }else{
            if(isLoading){
                return(
                    <div className={main.loading}>
                        Loading...
                    </div>
                )
            }else{
                return(
                    <div className={main.secondary_btn} onClick={fetchWorksMore}>
                        {t('main_seemore_button')}
                    </div>
                )    
            }
        }
    }

    const workViewList = works.map((work) => {
        return (
            <div key={work.workId} className={workmanage.wrap}>
                <div className={workmanage.item}>
                    <Link to={"/workregister?from=list&workId="+work.workId} className={workmanage.linktag}>
                        <div className={workmanage.flex}>
                            <div className={workmanage.requstorImage}>
                                <img src={work.imageUrl} alt="" />
                            </div>
                            <div className={workmanage.content}>
                                <div>
                                    <div className={workmanage.title}>
                                        データの購入価格
                                    </div>
                                    <div><span><FontAwesomeIcon icon={['far', 'yen-sign']} /></span>&nbsp;
                                        <span className={workmanage.price}>{work.requestUsePrice}</span>
                                    </div>
                                </div>
                                <div>
                                    <span className={workmanage.like_star}><FontAwesomeIcon icon={['fas', 'star']} /></span>&nbsp;
                                    {typeof work.likeNumber !== 'undefined'?
                                        <React.Fragment>{work.likeNumber}</React.Fragment>
                                    :
                                        <React.Fragment>0</React.Fragment>
                                    }
                                </div>
                                <div className={workmanage.date}>
                                    {typeof work.updatedAt !== 'undefined'?
                                        <React.Fragment>{moment(work.updatedAt.seconds * 1000).format('YYYY-MM-DD')}</React.Fragment>
                                    :
                                        <React.Fragment>{moment(work.createdAt.seconds * 1000).format('YYYY-MM-DD')}</React.Fragment>
                                    }
                                    </div>
                            </div>
                            <div className={workmanage.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    });

    const workmanageComponent = () =>{
        if(dig(userInfo, "userInfo", "uid")){
            if(userInfo.userInfo.isCreator){
                return(
                    <React.Fragment>
                        <div>
                            <Link to={toWorkRegister} className={main.primary_btn}>出品作品を登録する</Link>
                        </div>
                        <div className={workmanage.list}>
                            {workViewList}
                        </div>
                        {seeMoreButtonComponent()}
                        <br />
                    </React.Fragment>
                )
            }else{
                return(
                    <div>該当のデータは存在しません。</div>
                )
            }
        }else{
            return(
                <div>ログインの上、ご確認ください。</div>
            )
        }
    }


    return(
        <div>
            <GoBack />
            <h2>作品管理</h2>
            {workmanageComponent()}
        </div>
    )
}

export default WorkManage;