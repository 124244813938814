import firebase from 'firebase/compat/app';
import 'firebase/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/compat/app-check";
import * as cons from "./constants";
import crypto from 'crypto-js'
import { getAnalytics } from "firebase/analytics";



if (!firebase.apps.length) {
  const app = firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    })
  firebase.appCheck().activate('6LezmnwcAAAAAKngGq0E03AEKh9F6EswAuuKnZjv',true);
  getAnalytics(app);
};

const googleProvider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.app().functions('asia-northeast1');
export default firebase;


export const signInWithGoogle = () => {
  firebase.auth().signInWithPopup(googleProvider)
    .then((res) => {
      console.log(res.user);
      createUser(res.user);
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const logOut = () => {
    firebase.auth().signOut()
      .then(() => {
        console.log("logged out");
        document.location.reload();
        //あとでやる
        //ログアウトしたらTOPページに返してほしい
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const encryptoCode = (unprocessed) => {
    const encrypted = crypto.AES.encrypt(unprocessed, cons.decryptKey.key);
    return encrypted.toString();
}
  

//登録時Userテーブルの情報を書き込み
//すでに登録済みなら、スキップ
  const createUser = async (user) =>{
    await db.collection("users").where("uid", "==", user.uid).get().then(querySnapshot => {
      console.log("querySnapshot is empty?:");
      console.log(querySnapshot.empty);
      const processedEmail = encryptoCode(user.email);
      if (!querySnapshot.empty) {
        console.log("Query: already exist");
      }else{
        console.log("Query: not yet");
        db.collection("users").add({
            uid: user.uid,
            email: processedEmail,
            agreeCreatorRules: false,
            isCreator: false,
            langCode: "ja",
            // 最初はgoogleアカウントのdisplayNameを突っ込む
            displayName: user.displayName,
            introductionJapanese: "",
            introductionEnglish: "",
            // 最初はデフォルトの画像を詰める
            profileImageUrl: cons.initialProfileImageUrl.url,
            requestCreatePrice: 0,
            isRequestAcceptable: false,
            roomId: "",
            twitterUrl: "",
            instagramUrl: "",
            isFirstRoomIdUpdate: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
      }
    })
  }
  

