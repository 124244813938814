import React , { useState,useEffect,useContext } from "react";
import { AuthContext } from "../providers/AuthProvider.js";
import dig from "object-dig";
import firebase from 'firebase/compat/app';
import crypto from 'crypto-js';
import * as cons from "../service/constants";



export const UserInfoContext = React.createContext();
export const db = firebase.firestore();

export const UserInfoProvider = ({ children }) => {
  
    //ユーザー情報。初期値はnull
    const [userInfo, setUserInfo] = useState(null);
    const currentUser = useContext(AuthContext);

    const decryptoCode = (processed) => {
      const decrypted = crypto.AES.decrypt(processed, cons.decryptKey.key);
      return decrypted.toString(crypto.enc.Utf8);
    }    

    const getUserInfo = async (uid) =>{
      const data = await db.collection("users").where("uid", "==", uid);
      return data.get().then((snapShot) => {
        let userInfoList = [];
        snapShot.forEach((doc) => {
          userInfoList.push({
            docId: doc.id,
            uid: doc.data().uid,
            agreeCreatorRules: doc.data().agreeCreatorRules,
            isCreator: doc.data().isCreator,
            langCode: doc.data().langCode,
            displayName: doc.data().displayName,
            profileImageUrl: doc.data().profileImageUrl,
            introductionJapanese: doc.data().introductionJapanese,
            introductionEnglish: doc.data().introductionEnglish,
            requestCreatePrice: doc.data().requestCreatePrice,
            roomId: doc.data().roomId,
            isRequestAcceptable: doc.data().isRequestAcceptable,
            email: decryptoCode(doc.data().email),
            isFirstRoomIdUpdate: doc.data().isFirstRoomIdUpdate,
            twitterUrl: doc.data().twitterUrl,
            instagramUrl: doc.data().instagramUrl,
          });
        });
        return userInfoList;
      })
    }

    const generateTmpTrackingId = () =>{
      //ランダム文字列の末尾につける日付
      const now = new Date();
      const timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString();
      return Math.random().toString(32).substring(2)+timestr;   
    }

    //ここは変えちゃいけない。バグる。
    useEffect(async() => {  
      var browserLanguage = (window.navigator.languages && window.navigator.languages[0]) ||
            window.navigator.language ||
            window.navigator.userLanguage ||
            window.navigator.browserLanguage;

      if(dig(currentUser,"currentUser", "uid")){
        const data = await getUserInfo(currentUser.currentUser.uid);
        // ここを通るときは、authの認証は済んでる(∵currentUserは存在してる)
        // 初回登録時のみ、usersの登録より先にこっちの処理が行われることがある
        // なので、userInfoが空のときは、Headerで使う最低限の情報を詰める
        if(data.length > 0) {
          setUserInfo(data[0]);
        }else{
          var initialInfo = {uid: currentUser.currentUser.uid, displayName: currentUser.currentUser.displayName,isCreator: false, browserLanguage: browserLanguage};
          setUserInfo(initialInfo);
        }
      }else{
        // 未ログインの場合
        var tmpTrackingId = generateTmpTrackingId();
        var initialInfo = {tmpTrackingId: tmpTrackingId, browserLanguage: browserLanguage};
        setUserInfo(initialInfo);
        console.log("tmpTrackingId:"+tmpTrackingId)
        console.log("browserLanguage:"+browserLanguage)
      }
    }, [currentUser]);    
  
    return (
      // Contextを使用して認証に必要な情報をコンポーネントツリーに流し込む。
      <UserInfoContext.Provider value={{ userInfo }}>
        {children}
      </UserInfoContext.Provider>
    );
  };
  export default UserInfoProvider;