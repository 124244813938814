import React , { useState, useContext, useEffect, useRef } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";

import firebase, { storage } from "../service/firebase";
import * as Api from "../service/api";
import * as Validation from "../service/validation";

import dig from "object-dig";
import { Link, useLocation } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import main from '../css/main.module.css';
import workregister from '../css/workregister.module.css';
import toast, { Toaster } from 'react-hot-toast';
import { tasteCode } from '../service/constants';
import loadImage from 'blueimp-load-image';
import GoBack from "./GoBack";



//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//言語切替
import { useTranslation } from 'react-i18next';




function WorkRegister() {
    const [t] = useTranslation();

    const userInfo = useContext(UserInfoContext);

    const [langCode, setLangCode] = useState("");

    const [creatorId, setCreatorId] = useState("");

    const [workImage, setWorkImage] = useState("");
    const [workImagePreviewUrl, setWorkImagePreviewUrl] = useState("");
    let randomWorkImageName = "";

    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    const [requestUsePrice, setRequestUsePrice] = useState(0);


    const [title, setTitle] = useState("");
    const [tasteCategoryCode, setTasteCategoryCode] = useState("");

    // validation
    const [isValidPrice, setIsValidPrice] = useState(true);
    const [isAttachedImage, setIsAttachedImage] = useState(true);





    const location = useLocation();
    let from = "";
    let workId = "";
    if(location.search !== ""){
        var urlParam = location.search.substring(1).split('&');
        //あんまりよろしくないですが
        from = urlParam[0].substring(urlParam[0].indexOf('=') + 1);
        workId = urlParam[1].substring(urlParam[1].indexOf('=') + 1);
    }


    useEffect(()=>{
        const fetch = async() =>{
            // 必要な情報をセット
            if(dig(userInfo,"userInfo", "uid")){
                setCreatorId(userInfo.userInfo.uid);
                setLangCode(userInfo.userInfo.langCode);  
                if(userInfo.userInfo.isCreator && workId !== ""){
                    const data = await Api.getWork(workId);
                    setWorkImagePreviewUrl(data.imageUrl);
                    setRequestUsePrice(data.requestUsePrice);
                    setTasteCategoryCode(data.tasteCategoryCode);
                    // カラムがないなら、なにもしない
                    if(typeof data.title !== 'undefined'){
                        setTitle(data.title);
                    }
                }                    
            }     
        } 
        fetch();
    },[userInfo, workId]);  

    const headerCompoent = () =>{
        if(from === "initialregister"){
            return(
                <div>
                    <h2>出品作品登録</h2>
                    <h3 className={main.step}>STEP3/4</h3>
                    <div>作品のアップロードをお願いします。1つからでもOK。あとからでも追加できます。</div>
                </div>
            )
        } else {
            if(workId === ""){
                return(
                    <div>
                        <GoBack />
                        <h2>出品作品登録</h2>
                    </div>
                )    
            }else{
                return(
                    <div>
                        <GoBack />
                        <h2>出品作品編集</h2>
                    </div>
                )    
            }
        }
    }

    const imageCompoent = () =>{
        if(workImagePreviewUrl !== ""){
            if(workId !== ""){
                return(
                    <div>
                        <div className={workregister.previewarea}>
                            <img src={workImagePreviewUrl} alt="uploaded" className={workregister.profile_img}/>
                        </div>
                    </div>    
                )
            }else{
                return(
                    <div>
                        <label htmlFor="file">
                            <div className={workregister.previewarea}>
                                <img src={workImagePreviewUrl} alt="uploaded" className={workregister.profile_img}/>
                            </div>
                        </label>
                        <input type="file" onChange={handleWorkImage} className={workregister.inputButton} id="file" />
                    </div>
                )
            }
        } else {
            return (
                        <div>
                            <label htmlFor="file">
                                <div className={workregister.filearea}>
                                    <FontAwesomeIcon icon={['fal', 'plus-circle']} className={workregister.addicon}/>
                                    <div className={workregister.filelabel}>
                                        タップして作品をアップロード
                                    </div>
                                </div>
                            </label>
                            <input type="file" onChange={handleWorkImage} className={workregister.inputButton} id="file" />
                        </div>
                    
            )
        }
    }

    const buttonCompoent = () =>{
        if(from === "initialregister"){
            if(isValidPrice && workImage !== ""){
                return(
                    <React.Fragment>
                        <div className={workregister.register_btn_area}>
                            <Link 
                                to={toBankAccountRegister} 
                                className={main.primary_btn}
                                onClick={workRegister}
                            >
                            登録して次へ
                            </Link>
                        </div>
                        <div className={workregister.register_btn_area_bottom}>
                            <Link 
                                to={toWorkRegister} 
                                className={main.secondary_btn}
                                onClick={workRegister}
                            >
                            登録して、他の作品も登録する
                            </Link>
                        </div>
                    </React.Fragment>
                )
            }else{
                return(
                    <React.Fragment>
                        <div className={workregister.register_btn_area}>
                            <div className={main.disabled_btn} >
                                登録して次へ
                            </div>
                        </div>
                        <div className={workregister.register_btn_area_bottom}>
                            <div className={main.disabled_btn} >
                                登録して、他の作品も登録する
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        } else {
            if(workId === ""){
                if(isValidPrice && workImage !== ""){
                    return(
                        <React.Fragment>
                            <div className={workregister.register_btn_area}>
                                <Link 
                                    to={toHome} 
                                    className={main.primary_btn}
                                    onClick={workRegister}
                                >
                                登録してトップに戻る
                                </Link>
                            </div>
                            <div className={workregister.register_btn_area_bottom}>
                                <Link 
                                    to={toStay} 
                                    className={main.secondary_btn}
                                    onClick={workRegister}
                                >
                                登録して、他の作品も登録する
                                </Link>
                            </div>
                        </React.Fragment>
                    )   
                } else {
                    return(
                        <React.Fragment>
                            <div className={workregister.register_btn_area}>
                                <div className={main.disabled_btn} >
                                    登録してトップに戻る
                                </div>
                            </div>
                            <div className={workregister.register_btn_area_bottom}>
                                <div className={main.disabled_btn} >
                                    登録して、他の作品も登録する
                                </div>
                            </div>
                        </React.Fragment>
                    )   
                }
            }else{
                if(isValidPrice){
                    return(
                        <React.Fragment>
                            <div className={workregister.register_btn_area}>
                                <Link 
                                    to={toStay} 
                                    className={main.primary_btn}
                                    onClick={() => updateWorkInfo(false)}
                                >
                                更新する
                                </Link>
                            </div>
                            <div className={workregister.register_btn_area_bottom}>
                                <Link 
                                    to={toReturnWorkManage} 
                                    className={main.tertiary_btn}
                                    onClick={() => updateWorkInfo(true)}
                                >
                                削除する
                                </Link>
                            </div>
                        </React.Fragment>
                    )    
                } else {
                    return(
                        <React.Fragment>
                            <div className={workregister.register_btn_area}>
                                <div className={main.disabled_btn} >
                                    登録してトップに戻る
                                </div>
                            </div>
                            <div className={workregister.register_btn_area_bottom}>
                                <div className={main.disabled_btn} >
                                    登録して、他の作品も登録する
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            }
        }
    }

    const updateWorkInfo = async (deleteFlg) => {
        await Api.updateWorkInfo(workId, requestUsePrice, deleteFlg, tasteCategoryCode, title);
        // deleteFlgがtrueのときは、関連するlikeを全部消す
        if(deleteFlg){
            await Api.deleteLikesOfWork(workId);
        }
        notifyUpdate();        
    }


    const successUpdateMessage = langCode === "ja" ? "作品の更新が完了しました":"Update Successfully";
    const notifyUpdate = () => toast.success(successUpdateMessage,
        {
            style: {
                border: '1px solid #94ABFF',
                borderRadius: '10px',
                background: '#ffffff',
                color: '#94ABFF',
            },
        duration: 2000,
        }
    );
    const successRegisterMessage = langCode === "ja" ? "作品の登録が完了しました":"Register Successfully";
    const notifyRegister = () => toast.success(successRegisterMessage,
        {
            style: {
                border: '1px solid #94ABFF',
                borderRadius: '10px',
                background: '#ffffff',
                color: '#94ABFF',
            },
        duration: 2000,
        }
    );



    const handleWorkImage = event => {
        console.log(event);
        const image = event.target.files[0];
        setWorkImage(image);
        setWorkImagePreviewUrl(createObjectURL(image));
    };

    const toStay = {
        pathname: '/workregister',
        search: '?from=list&workId='+workId,
    };
    const toWorkRegister = {
        pathname: '/workregister',
        //Steo 2/4以降が画面が使いまわしになるので、初期登録の動線から来た場合は明示する
        search: '?from=initialregister&workId=',
    };

    const toHome = {
        pathname: '/',
    };

    const toReturnWorkManage = {
        pathname: '/workmanage',
    };


    const toBankAccountRegister = {
        pathname: '/bankaccountregister',
        //Steo 2/4以降が画面が使いまわしになるので、初期登録の動線から来た場合は明示する
        search: '?from=initialregister',
    };

    const processing = useRef(false);

    const workRegister = async () => {
        //処理中(true)なら処理せずに抜ける
        if (processing.current) {
            return
        };

        //処理中フラグを上げる
        processing.current = true;

        if (workImage === "") {
            console.log("ファイルが選択されていません");
            // ファイルが添付されてない場合はバリデーションエラー
            setIsAttachedImage(false);
        }else{
            if(dig(userInfo,"userInfo", "uid")){                
                //ランダム文字列の末尾につける日付
                var now = new Date();
                var timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString();
        
                // アップロード前にファイル名をランダム文字列に書き換える
                // 同じファイル名のファイルはcloudstorageが受け付けない
                // あともし同じ文字列があった場合に再度ランダマイズ？
                randomWorkImageName = Math.random().toString(32).substring(2)+timestr;

                const canvas = await loadImage(workImage, {
                    maxWidth: 1200,
                    canvas: true,
                });
                canvas.image.toBlob((blob) => {
                    const uploadProfTask = storage.ref(`/images/${randomWorkImageName}`).put(blob);
                    uploadProfTask.on(
                        firebase.storage.TaskEvent.STATE_CHANGED,
                        next,
                        error,
                        completeWorkImage
                        );        
                }, workImage.type);

                // プロフィール画像のアップロード処理
                // const uploadProfTask = storage.ref(`/images/${randomWorkImageName}`).put(workImage);
                // uploadProfTask.on(
                // firebase.storage.TaskEvent.STATE_CHANGED,
                // next,
                // error,
                // completeWorkImage
                // );
            }else{
                console.log("uidがないため、失敗");
            }
        }
        //処理中フラグを下げる
        processing.current = false;
    };
    const next = snapshot => {
        // 進行中のsnapshotを得る
        // アップロードの進行度を表示
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        console.log(snapshot);
    };
    const error = error => {
        // エラーハンドリング
        console.log(error);
    };
    const completeWorkImage = async () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        let imageUrl = "";
        if(workImage !== ""){
            imageUrl = await storage.ref("images").child(randomWorkImageName).getDownloadURL();
        }
        // Api呼び出し
        if(creatorId !== "" && imageUrl !== ""){
            // Api呼び出し
            Api.addWork(creatorId, imageUrl, requestUsePrice, true, false, tasteCategoryCode, title, "");
            //トースト
            notifyRegister();
        }else{
            //この2つが空のときは何も起こらない
        }
        //　新規登録のときは空にする
        if(workId === ""){
            setWorkImage("");
            setWorkImagePreviewUrl("");
            setRequestUsePrice(0);
        }
    };

    // validation check
    const checkInputPrice = (value) =>{
        var isValid = Validation.checkValidPrice(value);
        setIsValidPrice(isValid);
    }

    const handleChangeTasteCategoryCode=(e)=>{
        setTasteCategoryCode(e.target.value);
    }

    const workRegisterMainComponent = () =>{
        if(dig(userInfo,"userInfo", "uid")){  
            return(
                <React.Fragment>
                    <Toaster />
                    {headerCompoent()}
                    <div>
                            <div>
                                <div className={workregister.image_area}>
                                    {imageCompoent()}
                                </div>
                                <div className={workregister.inputarea}>
                                    <TextField 
                                        className={workregister.inputtext}
                                        name="price" 
                                        label="データ販売価格"
                                        type="number" 
                                        required 
                                        error={!isValidPrice}
                                        value={requestUsePrice} 
                                        onChange={(event)=> setRequestUsePrice(event.currentTarget.value)} 
                                        onBlur={(event)=> checkInputPrice(event.currentTarget.value)}
                                    />
                                </div>
                                <div className={workregister.inputarea}>
                                    <FormControl 
                                        variant="standard" 
                                        sx={{ m: 1, minWidth: 120 }}
                                        className={workregister.selectarea}
                                    >
                                        <InputLabel id="demo-simple-select-standard-label-tastecategorycode">カテゴリ</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label-tastecategorycode"
                                            id="demo-simple-select-standard"
                                            value={tasteCategoryCode}
                                            onChange={handleChangeTasteCategoryCode} 
                                            label="カテゴリ"
                                        >
                                            <MenuItem value={tasteCode.cute}>{t('tastecategorycode_cute')}</MenuItem>
                                            <MenuItem value={tasteCode.pop}>{t('tastecategorycode_pop')}</MenuItem>
                                            <MenuItem value={tasteCode.beautiful}>{t('tastecategorycode_beautiful')}</MenuItem>
                                            <MenuItem value={tasteCode.cool}>{t('tastecategorycode_cool')}</MenuItem>
                                            <MenuItem value={tasteCode.surreal}>{t('tastecategorycode_surreal')}</MenuItem>
                                            <MenuItem value={tasteCode.artistic}>{t('tastecategorycode_artistic')}</MenuItem>
                                            <MenuItem value={tasteCode.ennui}>{t('tastecategorycode_ennui')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={workregister.inputarea}>
                                    <TextField 
                                        className={workregister.inputtext}
                                        name="title" 
                                        label="作品タイトル"
                                        type="text" 
                                        value={title} 
                                        onChange={(event)=> setTitle(event.currentTarget.value)} 
                                    />
                                </div>
                                <div className={workregister.agree_btn_area}>
                                    {buttonCompoent()}
                                </div>
                            </div>
                    </div>
                </React.Fragment>
            )  
        }else{
            return(
                <div>
                    <br />
                    {t('confirm_after_login')}
                </div>
            )
        }

    }


    return(
        <div>
            {workRegisterMainComponent()}
        </div>
    )
}

export default WorkRegister;