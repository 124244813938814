import React, {useState} from "react";

import { apiKey, functionMethod } from '../service/constants';

import main from '../css/main.module.css';
import checkout from '../css/checkout.module.css';

//Loader
import Loader from 'react-loader-spinner';

//言語切替
import { useTranslation } from 'react-i18next';


import { loadStripe } from '@stripe/stripe-js';
import firebase from "../service/firebase";
require("firebase/functions");



//const stripeApiKey = apiKey.stripeApiPublicKeyDev;
const stripeApiKey = apiKey.stripeApiPublicKeyProd;
const createPaymentSessionCons = functionMethod.createPaymentSession;

// セッション作成functionをインスタンス化
const Checkout = (props) => {

    const [t] = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const originalPrice = Number(props.request.originalPrice);
    const businessFee = Number(props.request.businessFee);
    const tax = Number(props.request.tax);
    const paymentFee = Number(props.request.paymentFee);
    
    const paymentPrice = originalPrice+businessFee+tax+paymentFee;
    const title = "Payment For"+props.request.creatorDisplayName;
    const requestId = props.request.requestId;
    const requestDcoumentId = props.request.id;
    const path = props.request.path;

    const showPayment = async () => {
        // 挟むとしたらここにローディング処理を挟む
        setIsLoading(true);
        
        // セッション作成functionをインスタンス化
        const createPaymentSession = firebase.app().functions('asia-northeast1').httpsCallable(createPaymentSessionCons);
 
        // 公開可能キーをもとに、stripeオブジェクトを作成
        const stripePromise = loadStripe(stripeApiKey);
        const stripe = await stripePromise;
    
        // セッション情報をもとに、支払いページに遷移
        createPaymentSession({
            price: paymentPrice,
            title: title,
            requestId: requestId,
            requestDcoumentId: requestDcoumentId,
            cancelReturnPath: path,
        }).then((result)=>{
            stripe.redirectToCheckout({
                sessionId: result.data.id,
            }).then((result)=>{
                console.log(result);
            });
        });
    }

    const switchSumitButton = () => {
        if(isLoading){
            // 決済ボタンタップ後、ローディング中
            return(
                <div className={main.loading_area}>
                    <Loader
                        timeout={10000}
                        type="ThreeDots"
                        color="#5278FF"
                        height={36}
                        width={36}
                    />
                </div>    
            )        
        }else{
            // 決済ボタンタップ前
            return(
                <button className={main.primary_btn} onClick={showPayment}>{t('to_payment_procedure')}</button>
            )
        }
    }

    return(
        <div>
                <div>
                    <table className={checkout.table}>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <th>{t('creator_fee')}</th>
                                <td>{originalPrice}&nbsp;<span>JPY</span></td>
                            </tr>
                            <tr>
                                <th>{t('service_fee')}</th>
                                <td>{businessFee}&nbsp;<span>JPY</span></td>
                            </tr>
                            <tr>
                                <th>{t('tax')}</th>
                                <td>{tax}&nbsp;<span>JPY</span></td>
                            </tr>
                            <tr>
                                <th>{t('payment_fee')}</th>
                                <td>{paymentFee}&nbsp;<span>JPY</span></td>
                            </tr>
                            <tr className={checkout.sum_row}>
                                <th>{t('total_fee')}</th>
                                <td><strong>{paymentPrice}</strong>&nbsp;<span>JPY</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            <div className={checkout.submit_area}>
                {switchSumitButton()}
            </div>
            <p>{t('attention_payment1')}</p>
            <p>{t('attention_payment2')}</p>
        </div>
    )

}

export default Checkout;