import React , { useEffect, useState, useContext } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";

import * as Api from "../service/api";
import * as Logic from "../service/logic";
import dig from "object-dig";
import { Link, useParams, useLocation } from 'react-router-dom';
import main from '../css/main.module.css';
import profile from '../css/profile.module.css';
import creatorpage from '../css/creatorpage.module.css';
import workdetail from '../css/workdetail.module.css';
import confirmationdialog from '../css/confirmationdialog.module.css';

import readmore from '../css/readmore.module.css';
import SinglSquareImageList from "./SinglSquareImageList";
import LoginModal from "./LoginModal";
import GoBack from "./GoBack";
import { languageCode, eventCode, queryParameterKeys} from '../service/constants';

import twitter_icon from '../img/twitter_icon.png';
import instagram_icon from '../img/insta_icon.png';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';





//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//言語切替
import { useTranslation } from 'react-i18next';


// サインインボタンタップ時の確認ポップアップのアニメーション
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});  


function CreatorDetail(props) {
    const userInfo = useContext(UserInfoContext);
    
    const [creatorProfile, setCreatorProfile] = useState("");
    const [introductionJaLength, setIntroductionJaLength] = useState(0);
    const [introductionEnLength, setIntroductionEnLength] = useState(0);

    const [images, setImages] = useState([]);
    const [lastDocId, setLastDocId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isAlldisplayed, setIsAlldisplayed] = useState(false);

    const [isFollowed, setIsFollowed] = useState(false);
    const [isIntroOpened, setIsIntroOpened] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [doneRequestCount, setDoneRequestCount] = useState(0);
    const [requestorId, setRequestorId] = useState("");

    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [dialogOpenForRequestCreate, setDialogOpenForRequestCreate] = useState(false);

    const [creatorIdForFetechMore, setCreatorIdForFetechMore] = useState("");

    const [firstVisit, setFirstVisit] = useState(true);

    const [t] = useTranslation();
    
    const {roomId} = useParams();

    const location = useLocation();
    let trackingId ="";
    let queryParams = {};
    queryParams = Logic.convertToAssociatvieArray(location);
    // for (let key in queryParams) {
    //     console.log('key_result:' + key + ' value_result:' + queryParams[key]);
    // }
    if(Object.keys(queryParams).length > 0){
        if(typeof queryParams[queryParameterKeys.trackingId] !== "undefined"){
            trackingId = queryParams[queryParameterKeys.trackingId]
        }
    }

    useEffect(()=>{     
        const fetch = async() =>{
            // CreatorProfile
            const dataCreatorProfile = await Api.getProfileByRoomId(roomId);
        
            const creatorId =dataCreatorProfile[0].creatorId;
            setCreatorIdForFetechMore(creatorId);
            if(dataCreatorProfile.length >0){
                setCreatorProfile(dataCreatorProfile[0]);
                setIntroductionJaLength(dataCreatorProfile[0].introductionJa.length);
                setIntroductionEnLength(dataCreatorProfile[0].introductionEn.length)
            
                // Works
                fetchWorks(creatorId);

                // checkIsAlreadyFollowed,like数、成立したリクエスト数はログインしないと見えない
                if(dig(userInfo,"userInfo", "uid")){
                    const follow = await Api.getFollowedId(userInfo.userInfo.uid, creatorId);
                    
                    let isAlreadyFollowed = false;
                    if(follow.length > 0){
                        setIsFollowed(!isAlreadyFollowed);
                    }else{
                        setIsFollowed(isAlreadyFollowed);
                    }
                    // 成立したリクエスト数
                    const requests = await Api.getDoneRequestsByCreatorId(creatorId);
                    setDoneRequestCount(requests.length);

                    setRequestorId(userInfo.userInfo.uid);
                }

                // likeされてる数
                // リクエスト数はログインしてないと見えたらあかんけど、like数はOK
                const likes = await Api.getLikeNumberOfCreator(creatorId);
                setLikeCount(likes.length);
                
            }
        } 
        fetch();
        
        if(firstVisit){
            Logic.eventLogging(userInfo, eventCode.viewCreatorDetail, roomId, trackingId);
        }
        setFirstVisit(false);
    }, [userInfo,roomId]);  

    const fetchWorks = async(creatorId) =>{
        const data = await Api.getSomeWorksOfCreator(creatorId);
        setImages(data);
        setLastDocId(data[data.length - 1].workId);
    }

    const fetchWorksMore = async() =>{
        setIsLoading(true);
        const data = await Api.getSomeWorksOfCreatorMore(creatorIdForFetechMore, lastDocId);
        if(data.length > 0){
            const combined = images.concat(data);
            setImages(combined);
            setLastDocId(data[data.length - 1].workId);
            setIsLoading(false);
        }else{
            setIsAlldisplayed(true);
        }
    }


    const handleFollow = async () =>{
        if(dig(userInfo,"userInfo", "uid")){
            await Api.addOrDeleteFollow(userInfo.userInfo.uid, creatorProfile.creatorId, isFollowed);
            setIsFollowed(!isFollowed);
        }
    }    

    const handleReadMore = () =>{
        setIsIntroOpened(!isIntroOpened);
    }   
    
    const intorductionContentComponent = () => {
        if(props.langCode === languageCode.english && creatorProfile.introductionEn !== ""){
            return(
                <div>
                    {creatorProfile.introductionEn}
                </div>
            )
        }else{
            return(
                <div>
                    {creatorProfile.introductionJa}
                </div>
            )
        }
    }

    const intorductionComponent = () => {
        if(introductionJaLength < 100 && introductionEnLength < 200){
            return(
                <div className={profile.profile_introduction}>
                    <div className={readmore.creator_introduction_content_open}>
                        {intorductionContentComponent()}
                    </div>
                </div>
            )
        } else {
            if(isIntroOpened){
                return(
                    <div className={profile.profile_introduction}>
                        <div className={readmore.creator_introduction_content_open}>
                            {intorductionContentComponent()}
                        </div>
                        <p className={readmore.open_close_btn} onClick={handleReadMore}>{t('readmore')}</p>
                    </div>
                )
            } else {
                return(
                    <div className={profile.profile_introduction}>
                        <div className={readmore.creator_introduction_content}>
                            {intorductionContentComponent()}
                        </div>
                        <p className={readmore.open_close_btn} onClick={handleReadMore}>{t('readmore')}</p>
                    </div>
                )
            }
        }
    }


    const FollowButton = () => {
        if(dig(userInfo,"userInfo", "uid")){
            // 再代入可能
            let buttonDom
            if(!isFollowed){
                buttonDom = <button className={main.secondary_btn} onClick={handleFollow}>{t('follow')}</button>
            }else{
                buttonDom = <button className={main.primary_btn} onClick={handleFollow}>{t('following')}</button>
            }
            return buttonDom;
        }
    }

    const seeMoreButtonComponent = () =>{
        if(isAlldisplayed){
            return(
                <div className={main.alreadydisplayed}>
                    No more works.
                </div>
            )
        }else{
            if(isLoading){
                return(
                    <div className={main.loading}>
                        Loading...
                    </div>
                )
            }else{
                return(
                    <div className={main.secondary_btn} onClick={fetchWorksMore}>
                        {t('main_seemore_button')}
                    </div>
                )    
            }
        }
    }

    const twitterUrlComponent = () =>{
        if(typeof creatorProfile.twitterUrl !== 'undefined' && creatorProfile.twitterUrl !== ""){
            return(
                <React.Fragment>
                    <a href={creatorProfile.twitterUrl} target="_blank" rel="noopener noreferrer">
                        <img src={twitter_icon} alt="twitter_icon" className={creatorpage.twitter_icon}/>
                    </a>
                </React.Fragment>
            )
        }else{
            return(
                <div></div>
            )
        }
    }
    const instagramUrlComponent = () =>{
        if(typeof creatorProfile.instagramUrl !== 'undefined' && creatorProfile.instagramUrl !== ""){
            return(
                <React.Fragment>
                    <a href={creatorProfile.instagramUrl}  target="_blank" rel="noopener noreferrer">
                        <img src={instagram_icon} alt="instagram_icon" />
                    </a>
                </React.Fragment>
            )
        }else{
            return(
                <div></div>
            )
        }
    }

    const numberDisplayComponent = () =>{
        // like数はいつも表示
        // リクエスト数あは、ログインしてたら数を出す、してなかったら??で表示
        if(dig(userInfo,"userInfo", "uid")){
            return(
                <div className={creatorpage.number_display_area}>
                    <FontAwesomeIcon icon={['fas', 'star']} className={creatorpage.count_icon}/>
                    &nbsp;
                    <span className={creatorpage.count_number}>{likeCount}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={['fas', 'handshake']} className={creatorpage.count_icon}/>
                    &nbsp;
                    <span className={creatorpage.count_number}>{doneRequestCount}</span>
                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={['fas', 'eye']} className={creatorpage.count_icon}/>
                    &nbsp;
                    <span className={creatorpage.count_number}>??</span> */}
                    <p className={creatorpage.request_price}>{t('request_price')}: &nbsp;{creatorProfile.requestCreatePrice}&nbsp;JPY</p>
                </div>
            )
        } else {
            return(
                <div className={creatorpage.number_display_area}>
                    <FontAwesomeIcon icon={['fas', 'star']} className={creatorpage.count_icon}/>
                    &nbsp;
                    <span className={creatorpage.count_number}>{likeCount}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={['fas', 'handshake']} className={creatorpage.count_icon}/>
                    &nbsp;
                    <span className={creatorpage.count_number}>??</span>
                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={['fas', 'eye']} className={creatorpage.count_icon}/>
                    &nbsp;
                    <span className={creatorpage.count_number}>??</span> */}
                    <p className={creatorpage.request_price}>{t('request_price')}: &nbsp;{creatorProfile.requestCreatePrice}&nbsp;JPY</p>
                </div>
            )
        }
    }

    // プロフィールページからリクエストボタン押したときは、最新の作品のworkIdを渡す
    const toRequestCreate = {
        pathname: '/requestcreate',
        search: '?creatorId='+creatorProfile.creatorId+'&workId='+lastDocId,
    };

        // ログインモーダルセット
        const handleLoginModal = () => {
            setOpenLoginModal(!openLoginModal);
        }  
    
        const loginModal = () => {
            // 再代入可能
            let modalDom;
            if(!openLoginModal){
                modalDom = <div></div>;
            }else{
                modalDom = <LoginModal openLoginModal={openLoginModal} closeLoginModal={closeLoginModal} />;
            }
            return modalDom;
        }
        const closeLoginModal = (flg) =>{
            setOpenLoginModal(flg);
        }
    
        const handleDailogOpenForRequestCreate = () => {
            setDialogOpenForRequestCreate(true);
        };
            
        const handleDailogCloseForRequestCreate = () => {
            setDialogOpenForRequestCreate(false);
        };

        const confirmationDialogComponentForRequestCreate = () => {
            return(
                <div>
                    <Dialog
                        open={dialogOpenForRequestCreate}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDailogCloseForRequestCreate}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{t('dialog_confirmation_before_request_create_title')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {t('dialog_confirmation_before_request_create_body1')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className={confirmationdialog.secondary_option} onClick={handleDailogCloseForRequestCreate}>{t('dialog_confirmation_before_reuqest_create_secondary_option')}</Button>
                            <Link to={toRequestCreate} className={confirmationdialog.primary_option_before_request}>{t('dialog_confirmation_before_reuqest_create_primary_option')}</Link>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
    

    const requestCreateButton = () => {
        if(creatorProfile.isRequestAcceptable > 0){
            if(requestorId !== ""){
                if(requestorId !== creatorProfile.creatorId){
                    return(
                        <div className={workdetail.btn_area}>
                            <a herf="#" onClick={handleDailogOpenForRequestCreate} className={main.primary_btn}>{t('request_create')}</a>
                        </div>
                    )   
                } else {
                    // 自分には依頼できない
                    return(
                        <div className={workdetail.btn_area}>
                            <button className={main.disabled_btn}>ご自身の作品には依頼できません</button>
                        </div>
                    )            
                }
            } else {
                return(
                    <div className={workdetail.btn_area}>
                        <button className={main.primary_btn} onClick={handleLoginModal}>{t('request_create')}</button>
                    </div>
                )    
            }
        } else {
            return(
                <div className={workdetail.btn_area}>
                    <button className={main.disabled_btn}>{t('request_create_disbaled')}</button>
                </div>
            )
        }
    }


    return(
        <div>
            <GoBack />
            <h2>{t('creator_detail_title')}</h2>
            <div>
                <div>
                    <div className={creatorpage.previewarea}>
                        <img src={creatorProfile.profileImageUrl} alt="プロフィール画像" className={creatorpage.profile_img}/>
                    </div>
                    <div className={creatorpage.display_name}>
                        <p>{creatorProfile.displayName}</p>
                    </div>
                    <div>
                        {numberDisplayComponent()}
                    </div>
                    <div className={creatorpage.sns_icon_display_area}>
                        {twitterUrlComponent()}
                        {instagramUrlComponent()}
                    </div>
                </div>
                <div>
                    {intorductionComponent()}
                </div>
                {requestCreateButton()}
                {confirmationDialogComponentForRequestCreate()}
                <div className={profile.profilepage_follow_area}>
                    {FollowButton()}
                </div>
                <div>
                    <div>
                        <SinglSquareImageList images={images}/>
                    </div>
                    {seeMoreButtonComponent()}
                    <br />
                </div>
            </div>
            {loginModal()}
        </div>
      
    )
}

export default CreatorDetail;