import React from "react";
import selectcategory from '../css/selectcategory.module.css';
import { Link } from 'react-router-dom';
import { tasteCode } from '../service/constants';
import GoBack from "./GoBack";



//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//言語切替
import { useTranslation } from 'react-i18next';


function SelectCategory() {
    const [t] = useTranslation();

    return(
        <div>
            <GoBack />
            <h2>{t('selectcategory_title')}</h2>
            <div className={selectcategory.item}>
                <Link to={"/searchresult?tasteCategoryCode="+tasteCode.cute} className={selectcategory.linktag}>
                        <div className={selectcategory.flex}>
                            <div className={selectcategory.content}>
                                <div>
                                    <p>{t('tastecategorycode_cute')}</p>
                                </div>
                            </div>
                            <div className={selectcategory.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                </Link>
            </div>
            <div className={selectcategory.item}>
                <Link to={"/searchresult?tasteCategoryCode="+tasteCode.pop} className={selectcategory.linktag}>
                        <div className={selectcategory.flex}>
                            <div className={selectcategory.content}>
                                <div>
                                    <p>{t('tastecategorycode_pop')}</p>
                                </div>
                            </div>
                            <div className={selectcategory.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                </Link>
            </div>
            <div className={selectcategory.item}>
                <Link to={"/searchresult?tasteCategoryCode="+tasteCode.beautiful} className={selectcategory.linktag}>
                        <div className={selectcategory.flex}>
                            <div className={selectcategory.content}>
                                <div>
                                    <p>{t('tastecategorycode_beautiful')}</p>
                                </div>
                            </div>
                            <div className={selectcategory.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                </Link>
            </div>
            <div className={selectcategory.item}>
                <Link to={"/searchresult?tasteCategoryCode="+tasteCode.cool} className={selectcategory.linktag}>
                        <div className={selectcategory.flex}>
                            <div className={selectcategory.content}>
                                <div>
                                    <p>{t('tastecategorycode_cool')}</p>
                                </div>
                            </div>
                            <div className={selectcategory.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                </Link>
            </div>
            <div className={selectcategory.item}>
                <Link to={"/searchresult?tasteCategoryCode="+tasteCode.surreal} className={selectcategory.linktag}>
                        <div className={selectcategory.flex}>
                            <div className={selectcategory.content}>
                                <div>
                                    <p>{t('tastecategorycode_surreal')}</p>
                                </div>
                            </div>
                            <div className={selectcategory.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                </Link>
            </div>
            <div className={selectcategory.item}>
                <Link to={"/searchresult?tasteCategoryCode="+tasteCode.artistic} className={selectcategory.linktag}>
                        <div className={selectcategory.flex}>
                            <div className={selectcategory.content}>
                                <div>
                                    <p>{t('tastecategorycode_artistic')}</p>
                                </div>
                            </div>
                            <div className={selectcategory.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                </Link>
            </div>
            <div className={selectcategory.item}>
                <Link to={"/searchresult?tasteCategoryCode="+tasteCode.ennui} className={selectcategory.linktag}>
                        <div className={selectcategory.flex}>
                            <div className={selectcategory.content}>
                                <div>
                                    <p>{t('tastecategorycode_ennui')}</p>
                                </div>
                            </div>
                            <div className={selectcategory.arrow}>
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                            </div>
                        </div>
                </Link>
            </div>
        </div>
    )
}

export default SelectCategory;