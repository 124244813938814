//import firebase from "firebase";
import firebase from 'firebase/compat/app';
import { db } from "./firebase"
import * as cons from "./constants";
import crypto from 'crypto-js';

require("firebase/functions");

// トップページ分割取得のlimit number
const limitNumberForToppage = 24;

// トップページ分割取得のランダム表示のためのlimit number
// const limitNumberForToppageRandom = 0;

// カテゴリサーチの分割取得のlimit number
const limitNumberForCategorySearch = 16;

// その他の分割取得のlimit number
const limitNumberForOthers = 16;

// クリエイターさんに公開してからの作品データのみ表示
const startOpenDate = new Date('December 25, 2021');

// ---------encrypto/decrypto----------
const encryptoCode = (unprocessed) => {
  const encrypted = crypto.AES.encrypt(unprocessed, cons.decryptKey.key);
  return encrypted.toString();
}

const decryptoCode = (processed) => {
  const decrypted = crypto.AES.decrypt(processed, cons.decryptKey.key);
  return decrypted.toString(crypto.enc.Utf8);
}

// ---------Users-----------

//画像アップロード後にDBにアカウント名、紹介文、profileのimageUrlを保存
//本当はここでuserの言語フラグ見る必要がある(最初は日本人だけなので不要)
export const addOrUpdateUserProfile = async (userDocId, displayName, introduction, requestCreatePrice, isRequestAcceptable, url, lang, roomId, isFirstRoomIdUpdate, twitterUrl, instagramUrl) =>{
  if(typeof userDocId !== "undefined"){
    const userRef = await db.collection(cons.collectionName.users).doc(userDocId);
    if(url !== ""){
      await userRef.update({
        displayName: displayName,
        introductionJapanese: introduction,
        isRequestAcceptable: isRequestAcceptable,
        requestCreatePrice: Number(requestCreatePrice),
        profileImageUrl: url,
        roomId: roomId,
        isFirstRoomIdUpdate: isFirstRoomIdUpdate,
        twitterUrl: twitterUrl,
        instagramUrl: instagramUrl,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }else{
      await userRef.update({
        displayName: displayName,
        introductionJapanese: introduction,
        isRequestAcceptable: isRequestAcceptable,
        requestCreatePrice: Number(requestCreatePrice),
        roomId: roomId,
        isFirstRoomIdUpdate: isFirstRoomIdUpdate,
        twitterUrl: twitterUrl,
        instagramUrl: instagramUrl,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
    }
  }
}


export const getProfileByUserId = async (userId) =>{
const user = await db.collection(cons.collectionName.users).where("uid", "==", userId);
return user.get().then((snapShot) => {
  let users = [];
  snapShot.forEach((doc) => {
    users.push({
      id: doc.id,
      isRequestAcceptable: doc.data().isRequestAcceptable,
      creatorId: doc.data().uid,
      userId: doc.data().uid,
      displayName: doc.data().displayName,
      introductionJa: doc.data().introductionJapanese,
      introductionEn: doc.data().introductionEnglish,
      requestCreatePrice: doc.data().requestCreatePrice,
      profileImageUrl: doc.data().profileImageUrl,
      roomId: doc.data().roomId,
      email: decryptoCode(doc.data().email),
    });
  });
  return users;
});
};

export const getProfileByRoomId = async (roomId) =>{
const user = await db.collection(cons.collectionName.users).where("roomId", "==", roomId);
return user.get().then((snapShot) => {
  let users = [];
  snapShot.forEach((doc) => {
    users.push({
      id: doc.id,
      isRequestAcceptable: doc.data().isRequestAcceptable,
      creatorId: doc.data().uid,
      displayName: doc.data().displayName,
      introductionJa: doc.data().introductionJapanese,
      introductionEn: doc.data().introductionEnglish,
      requestCreatePrice: doc.data().requestCreatePrice,
      profileImageUrl: doc.data().profileImageUrl,
      roomId: doc.data().roomId,
      twitterUrl: doc.data().twitterUrl,
      instagramUrl: doc.data().instagramUrl,
      email: decryptoCode(doc.data().email),
    });
  });
  return users;
});
};

export const getCreators = async () =>{
  const user = await db.collection(cons.collectionName.users).where("isCreator", "==", true).orderBy('createdAt', 'asc');
  return user.get().then((snapShot) => {
    let users = [];
    snapShot.forEach((doc) => {
      if(isProductionCreators(doc.data().uid)){
        users.push({
          id: doc.id,
          creatorId: doc.data().uid,
          displayName: doc.data().displayName,
          profileImageUrl: doc.data().profileImageUrl,
          roomId: doc.data().roomId,
        });
      }
    });
    return users;
  });
  };
  





// ---------Works-----------
//TOPページのの画像一覧取得
export const getWorks = async () =>{
  const works = await db.collection(cons.collectionName.works).where("displayFlg", "==" ,true).where("deleteFlg", "==" ,false).orderBy('createdAt', 'desc');
  return works.get().then((snapShot) => {
    let worksImages = [];
    snapShot.forEach((doc) => {
      if(isProductionCreators(doc.data().authorId)){
        worksImages.push({
          workId: doc.id,
          authorId: doc.data().authorId,
          imageUrl: doc.data().imageUrl,
          requestUsePrice: doc.data().requestUsePrice,
          deleteFlg: doc.data().deleteFlg,
          tasteCategoryCode: doc.data().tasteCategoryCode,
          createdAt: doc.data().createdAt,
          updatedAt: doc.data().updatedAt,
        });
      }
    });
    return worksImages;
  });
}

//そのクリエイターの該当作品以外の作品取得
export const getOtherWorksOfCreator = async (workId, creatorId) =>{
  const works = await db.collection(cons.collectionName.works).where("authorId", "==" ,creatorId).where("displayFlg", "==" ,true).where("deleteFlg", "==" ,false).orderBy('createdAt', 'desc');
  return works.get().then((snapShot) => {
    let otherWorks = [];
    snapShot.forEach((doc) => {
      if(!isAlreadyDisplayedWork(doc.id, workId)){
        otherWorks.push({
          workId: doc.id,
          authorId: doc.data().authorId,
          imageUrl: doc.data().imageUrl,
          requestUsePrice: doc.data().requestUsePrice,
          deleteFlg: doc.data().deleteFlg,
          tasteCategoryCode: doc.data().tasteCategoryCode,
          createdAt: doc.data().createdAt,
          updatedAt: doc.data().updatedAt,
        });
      }
    });
    return otherWorks;
  });
}

//閲覧数の多い作品を10作品
export const getPopularWorksOfCreator = async (workId, topViewWorkIds) =>{
  const works = await db.collection(cons.collectionName.works).where(firebase.firestore.FieldPath.documentId(), "in", topViewWorkIds).where("displayFlg", "==" ,true).where("deleteFlg", "==" ,false);
  return works.get().then((snapShot) => {
    let otherWorks = [];
    snapShot.forEach((doc) => {
      if(!isAlreadyDisplayedWork(doc.id, workId)){
        otherWorks.push({
          workId: doc.id,
          authorId: doc.data().authorId,
          imageUrl: doc.data().imageUrl,
          requestUsePrice: doc.data().requestUsePrice,
          deleteFlg: doc.data().deleteFlg,
          tasteCategoryCode: doc.data().tasteCategoryCode,
          createdAt: doc.data().createdAt,
          updatedAt: doc.data().updatedAt,
        });
      }
    });
    return otherWorks;
  });
}

export const getTopViewWorkIds = async () =>{
  const topViewWorks = await db.collection(cons.collectionName.workviews).orderBy('viewCount', 'desc').limit(10);
  return topViewWorks.get().then((snapShot) => {
    let topViewWorkIds = [];
    snapShot.forEach((doc) => {
        topViewWorkIds.push({
          workId: doc.data().workId,
        });
    });
    return topViewWorkIds;
  });
}


export const getLastWork = async (workId) =>{
  const work = await db.collection(cons.collectionName.works).doc(workId);
  return work.get().then(res => {
    return res;
  })
};

// クリエイターのプロフィールページの作品データの分割取得
export const getSomeWorksOfCreator = async (creatorId) =>{
  const data = await db.collection(cons.collectionName.works).where("authorId", "==", creatorId).where("deleteFlg", "==", false).orderBy("createdAt", "desc").limit(limitNumberForOthers);
  return data.get().then((snapShot) => {
    let works = [];
    snapShot.forEach((doc) => {
      works.push({
        workId: doc.id,
        authorId: doc.data().authorId,
        imageUrl: doc.data().imageUrl,
        requestUsePrice: doc.data().requestUsePrice,
        deleteFlg: doc.data().deleteFlg,
        tasteCategoryCode: doc.data().tasteCategoryCode,
        likeNumber: doc.data().likeNumber,
        createdAt: doc.data().createdAt,
        updatedAt: doc.data().updatedAt,
      });
    });
    return works;
  });
}

export const getSomeWorksOfCreatorMore = async (creatorId, lastDocId) =>{
  const lastDocRef = await getLastWork(lastDocId);
  const data = await db.collection(cons.collectionName.works).where("authorId", "==", creatorId).where("deleteFlg", "==", false).orderBy("createdAt", "desc").startAfter(lastDocRef).limit(limitNumberForOthers);
  return data.get().then((snapShot) => {
    let works = [];
    snapShot.forEach((doc) => {
      works.push({
        workId: doc.id,
        authorId: doc.data().authorId,
        imageUrl: doc.data().imageUrl,
        requestUsePrice: doc.data().requestUsePrice,
        deleteFlg: doc.data().deleteFlg,
        tasteCategoryCode: doc.data().tasteCategoryCode,
        likeNumber: doc.data().likeNumber,
        createdAt: doc.data().createdAt,
        updatedAt: doc.data().updatedAt,
      });
    });
    return works;
  });
}



//tasteCategoryCodeごとの画像一覧取得
export const getWorksByTasteCategoryCode = async (tasteCategoryCode) =>{
  const works = await db.collection(cons.collectionName.works).where("tasteCategoryCode", "==" ,tasteCategoryCode).where("displayFlg", "==" ,true).where("deleteFlg", "==" ,false).orderBy("createdAt", "desc");
  return works.get().then((snapShot) => {
    let worksImages = [];
    snapShot.forEach((doc) => {
      worksImages.push({
        workId: doc.id,
        authorId: doc.data().authorId,
        imageUrl: doc.data().imageUrl,
        requestUsePrice: doc.data().requestUsePrice,
        deleteFlg: doc.data().deleteFlg,
        tasteCategoryCode: doc.data().tasteCategoryCode,
        createdAt: doc.data().createdAt,
        updatedAt: doc.data().updatedAt,
      });
    });
    return worksImages;
  });
}
export const getSomeWorksByTasteCategoryCode = async (tasteCategoryCode) =>{
  const works = await db.collection(cons.collectionName.works).where("tasteCategoryCode", "==" ,tasteCategoryCode).where("displayFlg", "==" ,true).where("deleteFlg", "==" ,false).orderBy("createdAt", "desc").limit(limitNumberForCategorySearch);
  return works.get().then((snapShot) => {
    let worksImages = [];
    snapShot.forEach((doc) => {
      if(isProductionCreators(doc.data().authorId)){
        worksImages.push({
          workId: doc.id,
          authorId: doc.data().authorId,
          imageUrl: doc.data().imageUrl,
          requestUsePrice: doc.data().requestUsePrice,
          deleteFlg: doc.data().deleteFlg,
          tasteCategoryCode: doc.data().tasteCategoryCode,
          createdAt: doc.data().createdAt,
          updatedAt: doc.data().updatedAt,
        });
      }
    });
    return worksImages;
  });
}
export const getSomeWorksByTasteCategoryCodeMore = async (tasteCategoryCode, lastDocId) =>{
  const lastDocRef = await getLastWork(lastDocId);
  const works = await db.collection(cons.collectionName.works).where("tasteCategoryCode", "==" ,tasteCategoryCode).where("displayFlg", "==" ,true).where("deleteFlg", "==" ,false).orderBy("createdAt", "desc").startAfter(lastDocRef).limit(limitNumberForCategorySearch);
  return works.get().then((snapShot) => {
    let worksImages = [];
    snapShot.forEach((doc) => {
      if(isProductionCreators(doc.data().authorId)){
        worksImages.push({
          workId: doc.id,
          authorId: doc.data().authorId,
          imageUrl: doc.data().imageUrl,
          requestUsePrice: doc.data().requestUsePrice,
          deleteFlg: doc.data().deleteFlg,
          tasteCategoryCode: doc.data().tasteCategoryCode,
          createdAt: doc.data().createdAt,
          updatedAt: doc.data().updatedAt,
        });
      }
    });
    return worksImages;
  });
}

//画像アップロード後にDBにimage urlを保存
// addimageurlを作り変えた
export const addWork = async (creatorId, url, price, displayFlg, deleteFlg, tasteCategoryCode, title, productImageUrl) =>{
  await db.collection(cons.collectionName.works).add({
    authorId: creatorId,
    imageUrl: url,
    productFileUrl: "",
    productImageUrl: productImageUrl,
    requestUsePrice: Number(price),
    // NSFW用
    displayFlg: true,
    // 公開停止用
    deleteFlg: false,
    tasteCategoryCode: tasteCategoryCode,
    title: title,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const getWork = async (workId) =>{
  const work = await db.collection(cons.collectionName.works).doc(workId);
  //async awaitで返ってくる値は全部Promiseなので、thenで処理する必要がある(皮剥くイメージ)
  return work.get().then(res => {
    return res.data();
  })
};

export const updateWorkInfo = async (workId, requestUsePrice, deleteFlg, tasteCategoryCode, title) =>{
  const workRef = await db.collection(cons.collectionName.works).doc(workId);
    await workRef.update({
      requestUsePrice: Number(requestUsePrice),
      deleteFlg: deleteFlg,
      tasteCategoryCode: tasteCategoryCode,
      title: title,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
}

export const updateWorkProductFileUrl = async (workId, productFileUrl) =>{
  const workRef = await db.collection(cons.collectionName.works).doc(workId);
    await workRef.update({
      productFileUrl: productFileUrl,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
}

export const updateWorkProductImageUrl = async (workId, productImageUrl) =>{
  const workRef = await db.collection(cons.collectionName.works).doc(workId);
    await workRef.update({
      productImageUrl: productImageUrl,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
}

export const updateLikeNumberOfWork = async (workId, likeNumber) =>{
  const workRef = await db.collection(cons.collectionName.works).doc(workId);
    await workRef.update({
      likeNumber: Number(likeNumber),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
}



// ---------Likes-----------
export const addOrDeleteLike = async (likerId, workId, isAlreadyLiked, creatorId, imageUrl) =>{
  //すでにlike済みかをチェック
  if(isAlreadyLiked){
    //すでにあるなら取ってきて消す
    const likes = await getLikes(likerId, workId);
    if(likes.length > 0){
      await db.collection(cons.collectionName.likes).doc(likes[0].id).delete();
    }
  }else{
    //ないならならadd
    await db.collection(cons.collectionName.likes).add({
      likerId: likerId,
      workId: workId,
      authorId: creatorId,
      imageUrl: imageUrl,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
};

export const getLikes = async (likerId, workId) =>{
  const data = await db.collection(cons.collectionName.likes).where("likerId", "==", likerId).where("workId", "==" , workId);
  return data.get().then((snapShot) => {
    let likes = [];
    snapShot.forEach((doc) => {
      likes.push({
        id: doc.id,
      });
    });
    return likes;
  });
}

export const getLikeListOfUser = async (likerId) =>{
  const data = await db.collection(cons.collectionName.likes).where("likerId", "==", likerId);
  return data.get().then((snapShot) => {
    let likes = [];
    snapShot.forEach((doc) => {
      likes.push({
        likerId: doc.data().likerId,
        workId: doc.data().workId,
        authorId: doc.data().authorId,
        imageUrl: doc.data().imageUrl,
      });
    });
    return likes;
  });
}

export const getLikeNumberOfCreator = async (creatorId) =>{
  const data = await db.collection(cons.collectionName.likes).where("authorId", "==", creatorId);
  return data.get().then((snapShot) => {
    let likes = [];
    snapShot.forEach((doc) => {
      likes.push({
        likerId: doc.data().likerId,
      });
    });
    return likes;
  });
}

export const getLikeNumberOfWork = async (workId) =>{
  const data = await db.collection(cons.collectionName.likes).where("workId", "==", workId);
  return data.get().then((snapShot) => {
    let likes = [];
    snapShot.forEach((doc) => {
      likes.push({
        likerId: doc.data().likerId,
      });
    });
    return likes;
  });
}

export const deleteLikesOfWork = async (workId) =>{
  const data = await db.collection(cons.collectionName.likes).where("workId", "==", workId);
  data.get().then((snapShot) => {
    snapShot.forEach(async (doc) => {
        await doc.ref.delete();
    })  
  })
}

// ---------Follows-----------

export const getFollowedId = async (followId, followedId) =>{
  const follow = await db.collection(cons.collectionName.follows).where("followId", "==", followId).where("followedId", "==" , followedId);
  return follow.get().then((snapShot) => {
    let follows = [];
    snapShot.forEach((doc) => {
      follows.push({
        id: doc.id
      });
    });
    return follows;
  });
}

export const addOrDeleteFollow = async (followId, followedId, isAlreadyFollowed) =>{
  //すでにlike済みかをチェック
  if(isAlreadyFollowed){
    //すでにあるなら取ってきて消す
    const follows = await getFollowedId(followId, followedId);
    if(follows.length > 0){
      await db.collection(cons.collectionName.follows).doc(follows[0].id).delete();
    }
  }else{
    //ないならならadd
    await db.collection(cons.collectionName.follows).add({
      followId: followId,
      followedId: followedId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
};

export const getFollowListOfUser = async (followId) =>{
  const data = await db.collection(cons.collectionName.follows).where("followId", "==", followId);
  return data.get().then((snapShot) => {
    let follows = [];
    snapShot.forEach((doc) => {
      follows.push(doc.data().followedId);
    });
    return follows;
  });
}

export const getFollowCreatorProfileImages = async (followedIdList) =>{
  // in句は10個の要素までなので基本使わない
  const data = await db.collection(cons.collectionName.users).where("isCreator", "==", true);
  //[resolve later]ドキュメントIDとcreatedAtでインデックスする方法が分からん
  //取得してからソートするしかない
  return data.get().then((snapShot) => {
    let profileImages = [];
    snapShot.forEach((doc) => {
      if(followedIdList.includes(doc.data().uid)){
        profileImages.push({
          id: doc.id,
          creatorId: doc.data().uid,
          displayName: doc.data().displayName,
          profileImageUrl: doc.data().profileImageUrl,
          roomId: doc.data().roomId,
        });
      }
    });
    return profileImages;
  });
}


// ---------Reqeusts-----------

//めちゃくちゃきもいけど、これしか思いつかんからこれで進める
//最初にサンプル画像のないリクエストを生成、以降のサンプル画像のアップロード処理が終わるごとにはupdateでつっこむ
export const createRequest = async (
  requestorId, 
  requestId, 
  creatorId, 
  requestStatus,
  requestType, 
  usePurpose, 
  objectGenger, 
  objectArea, 
  imageOrientation,
  existBackground, 
  existSignature, 
  others, 
  langCode, 
  requestorProfileImageUrl, 
  creatorProfileImageUrl, 
  requestorDisplayName, 
  creatorDisplayName,
  requestorMailAddress,
  creatorMailAddress,
  workId,
  workImageUrl,
  originalPrice,
  tax,
  businessFee,
  paymentFee,
  willAttachImage
  ) => {
  if(langCode === cons.languageCode.english){
    //　英語
    //　この前にtranslateが必要
    await db.collection(cons.collectionName.requests).add({
      requestorId: requestorId,
      requestId: requestId,
      creatorId: creatorId,
      requestType: requestType,
      usePurpose: usePurpose,
      objectGenger: objectGenger,
      objectArea: objectArea,
      imageOrientation: imageOrientation,
      existBackground: existBackground, 
      existSignature: existSignature,
      textJa: "",
      textEn: others,
      textZh: "",
      textTw: "",
      translated_en_to_ja: "",
      translated_zh_to_ja: "",
      translated_tw_to_ja: "",
      // このworkIdはworksのdocのid
      workId: workId,
      //忘れてたー、定数定義してinput
      status: requestStatus,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      sampleImageUrl1: "",
      sampleImageUrl2: "",
      sampleImageUrl3: "",
      sampleImageUrl4: "",
      requestorProfileImageUrl: requestorProfileImageUrl,
      creatorProfileImageUrl: creatorProfileImageUrl,
      requestorDisplayName: requestorDisplayName,
      creatorDisplayName: creatorDisplayName,
      workImageUrl: workImageUrl,
      deliveryDate: firebase.firestore.FieldValue.serverTimestamp(),
      requestorMailAddress: encryptoCode(requestorMailAddress),
      creatorMailAddress: encryptoCode(creatorMailAddress),
      originalPrice: originalPrice,
      tax: tax,
      businessFee: businessFee,
      paymentFee: paymentFee,
      productImageUrl: "",
      productFileUrl: "",
      willAttachImage: willAttachImage,
    })  
  }else if(langCode === cons.languageCode.japanese){
    //　　日本語
    await db.collection(cons.collectionName.requests).add({
      requestorId: requestorId,
      requestId: requestId,
      creatorId: creatorId,
      requestType: requestType,
      usePurpose: usePurpose,
      objectGenger: objectGenger,
      objectArea: objectArea,
      imageOrientation: imageOrientation,
      existBackground: existBackground, 
      existSignature: existSignature,
      textJa: others,
      textEn: "",
      textZh: "",
      textTw: "",
      translated_en_to_ja: "",
      translated_zh_to_ja: "",
      translated_tw_to_ja: "",
      // このworkIdはworksのdocのid
      workId: workId,
      //忘れてたー、定数定義してinput
      status: requestStatus,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      sampleImageUrl1: "",
      sampleImageUrl2: "",
      sampleImageUrl3: "",
      sampleImageUrl4: "",
      requestorProfileImageUrl: requestorProfileImageUrl,
      creatorProfileImageUrl: creatorProfileImageUrl,
      requestorDisplayName: requestorDisplayName,
      creatorDisplayName: creatorDisplayName,
      requestorMailAddress: encryptoCode(requestorMailAddress),
      creatorMailAddress: encryptoCode(creatorMailAddress),
      workImageUrl: workImageUrl,
      originalPrice: originalPrice,
      tax: tax,
      businessFee: businessFee,
      paymentFee: paymentFee,
      productImageUrl: "",
      productFileUrl: "",
      willAttachImage: willAttachImage,
    })
  }else if(langCode === cons.languageCode.chinese){
    //　　中国語(簡体)
    await db.collection(cons.collectionName.requests).add({
      requestorId: requestorId,
      requestId: requestId,
      creatorId: creatorId,
      requestType: requestType,
      usePurpose: usePurpose,
      objectGenger: objectGenger,
      objectArea: objectArea,
      imageOrientation: imageOrientation,
      existBackground: existBackground, 
      existSignature: existSignature,
      textJa: "",
      textEn: "",
      textZh: others,
      textTw: "",
      translated_en_to_ja: "",
      translated_zh_to_ja: "",
      translated_tw_to_ja: "",
      // このworkIdはworksのdocのid
      workId: workId,
      //忘れてたー、定数定義してinput
      status: requestStatus,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      sampleImageUrl1: "",
      sampleImageUrl2: "",
      sampleImageUrl3: "",
      sampleImageUrl4: "",
      requestorProfileImageUrl: requestorProfileImageUrl,
      creatorProfileImageUrl: creatorProfileImageUrl,
      requestorDisplayName: requestorDisplayName,
      creatorDisplayName: creatorDisplayName,
      requestorMailAddress: encryptoCode(requestorMailAddress),
      creatorMailAddress: encryptoCode(creatorMailAddress),
      workImageUrl: workImageUrl,
      originalPrice: originalPrice,
      tax: tax,
      businessFee: businessFee,
      paymentFee: paymentFee,
      productImageUrl: "",
      productFileUrl: "",
      willAttachImage: willAttachImage,
    })
  }else if(langCode === cons.languageCode.taiwan){
    //　　台湾(中国語(繁体))
    await db.collection(cons.collectionName.requests).add({
      requestorId: requestorId,
      requestId: requestId,
      creatorId: creatorId,
      requestType: requestType,
      usePurpose: usePurpose,
      objectGenger: objectGenger,
      objectArea: objectArea,
      imageOrientation: imageOrientation,
      existBackground: existBackground, 
      existSignature: existSignature,
      textJa: "",
      textEn: "",
      textZh: "",
      textTw: others,
      translated_en_to_ja: "",
      translated_zh_to_ja: "",
      translated_tw_to_ja: "",
      // このworkIdはworksのdocのid
      workId: workId,
      //忘れてたー、定数定義してinput
      status: requestStatus,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      sampleImageUrl1: "",
      sampleImageUrl2: "",
      sampleImageUrl3: "",
      sampleImageUrl4: "",
      requestorProfileImageUrl: requestorProfileImageUrl,
      creatorProfileImageUrl: creatorProfileImageUrl,
      requestorDisplayName: requestorDisplayName,
      creatorDisplayName: creatorDisplayName,
      requestorMailAddress: encryptoCode(requestorMailAddress),
      creatorMailAddress: encryptoCode(creatorMailAddress),
      workImageUrl: workImageUrl,
      originalPrice: originalPrice,
      tax: tax,
      businessFee: businessFee,
      paymentFee: paymentFee,
      productImageUrl: "",
      productFileUrl: "",
      willAttachImage: willAttachImage,
    })
  }else{
    //　　どのlanguageコードでもなかったら日本語で書き込む
    await db.collection(cons.collectionName.requests).add({
      requestorId: requestorId,
      requestId: requestId,
      creatorId: creatorId,
      requestType: requestType,
      usePurpose: usePurpose,
      objectGenger: objectGenger,
      objectArea: objectArea,
      imageOrientation: imageOrientation,
      existBackground: existBackground, 
      existSignature: existSignature,
      textJa: others,
      textEn: "",
      textZh: "",
      textTw: "",
      translated_en_to_ja: "",
      translated_zh_to_ja: "",
      translated_tw_to_ja: "",
      // このworkIdはworksのdocのid
      workId: workId,
      //忘れてたー、定数定義してinput
      status: requestStatus,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      sampleImageUrl1: "",
      sampleImageUrl2: "",
      sampleImageUrl3: "",
      sampleImageUrl4: "",
      requestorProfileImageUrl: requestorProfileImageUrl,
      creatorProfileImageUrl: creatorProfileImageUrl,
      requestorDisplayName: requestorDisplayName,
      creatorDisplayName: creatorDisplayName,
      requestorMailAddress: encryptoCode(requestorMailAddress),
      creatorMailAddress: encryptoCode(creatorMailAddress),
      workImageUrl: workImageUrl,
      originalPrice: originalPrice,
      tax: tax,
      businessFee: businessFee,
      paymentFee: paymentFee,
      productImageUrl: "",
      productFileUrl: "",
      willAttachImage: willAttachImage,
    })    
  }
};

// requestIdはユニーク
export const getRequest = async (requestId) =>{
  const request = await db.collection(cons.collectionName.requests).where("requestId", "==", requestId);
  return request.get().then((snapShot) => {
    let requests = [];
    snapShot.forEach((doc) => {
      requests.push({
        id: doc.id,
        requestorId: doc.data().requestorId,
        requestId: doc.data().requestId,
        creatorId: doc.data().creatorId,
        requestType: doc.data().requestType,
        usePurpose: doc.data().usePurpose,
        objectGenger: doc.data().objectGenger,
        objectArea: doc.data().objectArea,
        imageOrientation: doc.data().imageOrientation,
        existBackground: doc.data().existBackground, 
        existSignature: doc.data().existSignature,
        textJa: doc.data().textJa,
        textEn: doc.data().textEn,
        textZh: doc.data().textZh,
        textTw: doc.data().textTw,
        translated_en_to_ja: doc.data().translated_en_to_ja,
        translated_zh_to_ja: doc.data().translated_zh_to_ja,
        translated_tw_to_ja: doc.data().translated_tw_to_ja,
        status: doc.data().status,
        createdAt: doc.data().createdAt,
        sampleImageUrl1: doc.data().sampleImageUrl1,
        sampleImageUrl2: doc.data().sampleImageUrl2,
        sampleImageUrl3: doc.data().sampleImageUrl3,
        sampleImageUrl4: doc.data().sampleImageUrl4,
        requestorProfileImageUrl: doc.data().requestorProfileImageUrl,
        creatorProfileImageUrl: doc.data().creatorProfileImageUrl,
        requestorDisplayName: doc.data().requestorDisplayName,
        creatorDisplayName: doc.data().creatorDisplayName,
        requestorMailAddress: decryptoCode(doc.data().requestorMailAddress),
        creatorMailAddress: decryptoCode(doc.data().creatorMailAddress),
        workId: doc.data().workId,
        workImageUrl: doc.data().workImageUrl,
        deliveryDate: doc.data().deliveryDate,
        originalPrice: doc.data().originalPrice,
        tax: doc.data().tax,
        businessFee: doc.data().businessFee,
        paymentFee: doc.data().paymentFee,
        productImageUrl: doc.data().productImageUrl,
        productFileUrl: doc.data().productFileUrl,
        willAttachImage: doc.data().willAttachImage,
      });
    });
    return requests;
  });
}

export const getRequestsByCreatorId = async (creatorId) =>{
  const request = await db.collection(cons.collectionName.requests).where("creatorId", "==", creatorId).orderBy("createdAt", "desc");
  return request.get().then((snapShot) => {
    let requests = [];
    snapShot.forEach((doc) => {
      requests.push({
        id: doc.id,
        requestorId: doc.data().requestorId,
        requestId: doc.data().requestId,
        creatorId: doc.data().creatorId,
        requestorProfileImageUrl: doc.data().requestorProfileImageUrl,
        creatorProfileImageUrl: doc.data().creatorProfileImageUrl,
        requestorDisplayName: doc.data().requestorDisplayName,
        creatorDisplayName: doc.data().creatorDisplayName,
        createdAt: doc.data().createdAt,
        status: doc.data().status,
        requestType: doc.data().requestType,
      });
    });
    return requests;
  });
}

export const getDoneRequestsByCreatorId = async (creatorId) =>{
  const request = await db.collection(cons.collectionName.requests).where("creatorId", "==", creatorId).where("status", "==", cons.requestStatus.done)
  return request.get().then((snapShot) => {
    let requests = [];
    snapShot.forEach((doc) => {
      requests.push({
        id: doc.id,
      });
    });
    return requests;
  });
}


export const getRequestsByRequestorId = async (requestorId) =>{
  const request = await db.collection(cons.collectionName.requests).where("requestorId", "==", requestorId).orderBy("createdAt", "desc");
  return request.get().then((snapShot) => {
    let requests = [];
    snapShot.forEach((doc) => {
      requests.push({
        id: doc.id,
        requestorId: doc.data().requestorId,
        requestId: doc.data().requestId,
        creatorId: doc.data().creatorId,
        requestorProfileImageUrl: doc.data().requestorProfileImageUrl,
        creatorProfileImageUrl: doc.data().creatorProfileImageUrl,
        requestorDisplayName: doc.data().requestorDisplayName,
        creatorDisplayName: doc.data().creatorDisplayName,
        createdAt: doc.data().createdAt,
        status: doc.data().status,
      });
    });
    return requests;
  });
}


export const updateRequestSampleImage1Url = async (docId, url) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      sampleImageUrl1: url,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}


export const updateRequestSampleImage2Url = async (docId, url) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      sampleImageUrl2: url,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })  
  }
}
export const updateRequestSampleImage3Url = async (docId, url) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      sampleImageUrl3: url,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}
export const updateRequestSampleImage4Url = async (docId, url) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      sampleImageUrl4: url,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}

export const updateRequestStatusWhenApproval = async (docId, status, deliveryDate) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      status: status,
      deliveryDate: deliveryDate,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}
export const updateRequestStatusOnlyStatus = async (docId, status) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      status: status,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}

export const updateRequestStatusWhenDeliverImage = async (docId, deliverWorkImageUrl, status) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      productImageUrl: deliverWorkImageUrl,
      status: status,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}
export const updateRequestStatusWhenDeliverFile = async (docId, deliverWorkFileUrl, status) =>{
  if(typeof docId !== "undefined"){
    const requestRef = await db.collection(cons.collectionName.requests).doc(docId);
    await requestRef.update({
      productFileUrl: deliverWorkFileUrl,
      status: status,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}



// ---------Notification-----------
export const createNotificationForCreator = async (requestId, requestorId, creatorId, requestType, notificationType, requestorDisplayName, creatorDisplayName, requestorProfileImageUrl) =>{
  db.collection(cons.collectionName.notifications).add({
     requestId: requestId,
     requestorId: requestorId,
     creatorId: creatorId,
     toUserId: creatorId,
     requestType: requestType,
     openFlg: false,
     notificationType: notificationType,
     requestorDisplayName: requestorDisplayName,
     creatorDisplayName: creatorDisplayName, 
     requestorProfileImageUrl: requestorProfileImageUrl,
     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
})
}
export const createNotificationForConsumer = async (requestId, requestorId, creatorId, requestType, notificationType, requestorDisplayName, creatorDisplayName, creatorProfileImageUrl) =>{
  db.collection(cons.collectionName.notifications).add({
     requestId: requestId,
     requestorId: requestorId,
     creatorId: creatorId,
     toUserId: requestorId,
     requestType: requestType,
     openFlg: false,
     notificationType: notificationType,
     requestorDisplayName: requestorDisplayName,
     creatorDisplayName: creatorDisplayName, 
     creatorProfileImageUrl: creatorProfileImageUrl,
     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
})
}

export const isNewNotification = async (requestId, notificationType) =>{
  const data = await db.collection(cons.collectionName.notifications).where("requestId", "==", requestId).where("notificationType", "==" , notificationType);
  return data.get().then((snapShot) => {
    let notifications = [];
    snapShot.forEach((doc) => {
      notifications.push({
        id: doc.id,
      });
    });
    return notifications.length === 0;
  });
}


export const getNotificationsByUserId = async (uid) =>{
  const data = await db.collection(cons.collectionName.notifications).where("toUserId", "==", uid).orderBy("createdAt", "desc");
  return data.get().then((snapShot) => {
    let notifications = [];
    snapShot.forEach((doc) => {
      notifications.push({
        id: doc.id,
        requestId: doc.data().requestId,
        requestorId: doc.data().requestorId,
        creatorId: doc.data().creatorId,
        toUserId: doc.data().toUserId,
        requestType: doc.data().requestType,
        openFlg: doc.data().openFlg,
        notificationType: doc.data().notificationType,
        requestorDisplayName: doc.data().requestorDisplayName,
        creatorDisplayName: doc.data().creatorDisplayName,
        requestorProfileImageUrl: doc.data().requestorProfileImageUrl,
        creatorProfileImageUrl: doc.data().creatorProfileImageUrl,
        createdAt: doc.data().createdAt,
      });
    });
    return notifications;
  });
}

export const getNotificationCountByUserId = async (uid) =>{
  const data = await db.collection(cons.collectionName.notifications).where("toUserId", "==", uid).where("openFlg", "==", false);
  return data.get().then((snapShot) => {
    let notifications = [];
    snapShot.forEach((doc) => {
      notifications.push({
        id: doc.id,
      });
    });
    return notifications;
  });
}

//　notificationが開かれたら開封フラグを立てる
export const updateNotificationOpenFlg = async (docId, openFlg) =>{
  if(typeof docId !== "undefined"){
    const notificationRef = await db.collection(cons.collectionName.notifications).doc(docId);
    await notificationRef.update({
      openFlg: openFlg,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}




// ---------BankAccount-----------

export const createBankAccount = async (creatorId, bankName, bankCode, accountType, branchNumber, accountNumber, familyName, firstName) =>{
  await db.collection(cons.collectionName.bankaccounts).add({
    creatorId: creatorId,
    bankName: bankName,
    bankCode: bankCode,
    accountType: accountType,
    branchNumber: branchNumber,
    accountNumber: encryptoCode(accountNumber),
    familyName: encryptoCode(familyName),
    firstName: encryptoCode(firstName),
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export const getBankAccountByUserId = async (creatorId) =>{
  const data = await db.collection(cons.collectionName.bankaccounts).where("creatorId", "==", creatorId);
  return data.get().then((snapShot) => {
    let bankaccounts = [];
    snapShot.forEach((doc) => {
      bankaccounts.push({
        id: doc.id,
        accountNumber: decryptoCode(doc.data().accountNumber),
        accountType: doc.data().accountType,
        bankCode: doc.data().bankCode,
        bankName: doc.data().bankName,
        branchNumber: doc.data().branchNumber,
        familyName: decryptoCode(doc.data().familyName),
        firstName: decryptoCode(doc.data().firstName),
      });
    });
    return bankaccounts;
  });
}

export const updateBankAccount = async (docId, bankName, bankCode, accountType, branchNumber, accountNumber, familyName, firstName) =>{
  if(typeof docId !== "undefined"){
    const userRef = await db.collection(cons.collectionName.bankaccounts).doc(docId);
    await userRef.update({
      bankName: bankName,
      bankCode: bankCode,
      accountType: accountType,
      branchNumber: branchNumber,
      accountNumber: encryptoCode(accountNumber),
      familyName: encryptoCode(familyName),
      firstName: encryptoCode(firstName),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}


// ---------Language-----------
//言語フラグの切り替わったときにupdateする
export const updateLangFlg = async (docId, langCode) =>{
  if(typeof docId !== "undefined"){
    const userRef = await db.collection(cons.collectionName.users).doc(docId);
    await userRef.update({
      langCode: langCode,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}

// ---------CreatorFlg-----------
//creatorフラグが立ったときにupdateする
export const updateCreatorFlg = async (docId, creatorflg) =>{
  if(typeof docId !== "undefined"){
    const userRef = await db.collection(cons.collectionName.users).doc(docId);
    await userRef.update({
      isCreator: creatorflg,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}

// ---------agreeCreatorRules-----------
//creatorフラグが立ったときにupdateする
export const updateAgreeCreatorRules = async (docId, agreeCreatorRules) =>{
  if(typeof docId !== "undefined"){
    const userRef = await db.collection(cons.collectionName.users).doc(docId);
    await userRef.update({
      agreeCreatorRules: agreeCreatorRules,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}


// --------PaymentRecord--------
// --------あくまでなにかあったときの記録用--------

export const isNewPaymentRecord = async (requestId) =>{
  const data = await db.collection(cons.collectionName.payment_records).where("requestId", "==", requestId);
  return data.get().then((snapShot) => {
    let records = [];
    snapShot.forEach((doc) => {
      records.push({
        id: doc.id,
      });
    });
    console.log(records.length);
    return records.length === 0;
  });
}

export const createPaymentRecord = async (payerId,paymentPrice,requestId) =>{
  const isNew = await isNewPaymentRecord(requestId);
  console.log(isNew);
  if(isNew){
    await db.collection(cons.collectionName.payment_records).add({
      payerId: payerId,
      paymentPrice: Number(paymentPrice),
      requestId: requestId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
  //すでにある場合はなにもしない
}

// --------Sales--------
export const isNewSales = async (requestId) =>{
  const data = await db.collection(cons.collectionName.sales).where("requestId", "==", requestId);
  return data.get().then((snapShot) => {
    let records = [];
    snapShot.forEach((doc) => {
      records.push({
        id: doc.id,
      });
    });
    console.log(records.length);
    return records.length === 0;
  });
}

export const createSales = async (
  requestorId,
  requestId, 
  creatorId, 
  creatorDisplayName, 
  originalPrice, 
  businessFee, 
  creatorTax, 
  businessTax, 
  paymentFee,
  familyName,
  firstName,
  bankName,
  branchNumber,
  accountType,
  accountNumber,
  ) =>{
  const isNew = await isNewSales(requestId);
  if(isNew){
    await db.collection(cons.collectionName.sales).add({
      requestorId: requestorId,
      requestId: requestId,
      creatorId: creatorId,
      creatorDisplayName: creatorDisplayName,
      originalPrice: Number(originalPrice),
      businessFee: Number(businessFee),
      creatorTax: Number(creatorTax),
      businessTax: Number(businessTax),
      paymentFee: Number(paymentFee),
      status: cons.salesStatus.undone,
      familyName: encryptoCode(familyName),
      firstName: encryptoCode(firstName),
      bankName: bankName,
      branchNumber: branchNumber,
      accountType: accountType,
      accountNumber: encryptoCode(accountNumber),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
  //すでにある場合はなにもしない
}

export const getSalesDocId = async (requestId) =>{
  const data = await db.collection(cons.collectionName.sales).where("requestId", "==", requestId);
  return data.get().then((snapShot) => {
    let records = [];
    snapShot.forEach((doc) => {
      records.push({
        id: doc.id,
      });
    });
    return records;
  });
}

export const updateSalesStatus = async (docId, status) =>{
  if(typeof docId !== "undefined"){
    const userRef = await db.collection(cons.collectionName.sales).doc(docId);
    await userRef.update({
      status: status,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
}

// --------AdminSalesManage--------
export const getSendSalesListInThisMonth = async (startOfMonth, endOfMonth) =>{
  const fromDate = firebase.firestore.Timestamp.fromDate(startOfMonth);
  const toDate = firebase.firestore.Timestamp.fromDate(endOfMonth);
  const data = await db.collection(cons.collectionName.sales).where("status", "==", cons.salesStatus.done).orderBy("updatedAt", "asc").startAt(fromDate).endAt(toDate);
  return data.get().then((snapShot) => {
    let SendSalesList = [];
    snapShot.forEach((doc) => {
      SendSalesList.push({
        id: doc.id,
        creatorId: doc.data().creatorId,
        creatorDisplayName: doc.data().creatorDisplayName,
        originalPrice: doc.data().originalPrice,
        creatorTax: doc.data().creatorTax,
        familyName: decryptoCode(doc.data().familyName),
        firstName: decryptoCode(doc.data().firstName),
        bankName: doc.data().bankName,
        branchNumber: doc.data().branchNumber,
        accountType: doc.data().accountType,
        accountNumber: decryptoCode(doc.data().accountNumber),      
      });
    });
    return SendSalesList;
  });
}

export const getSendSalesListInThisMonthGroupBy = async (startOfMonth, endOfMonth) =>{
  const fromDate = firebase.firestore.Timestamp.fromDate(startOfMonth);
  const toDate = firebase.firestore.Timestamp.fromDate(endOfMonth);
  const snapshot = await db.collection(cons.collectionName.sales).where("status", "==", cons.salesStatus.done).orderBy("updatedAt", "asc").startAt(fromDate).endAt(toDate).get();
  const groupBy = snapshot.docs
        .map(doc => doc.data())
        .reduce((prev, current) => {
            const element = prev.find(value => value.creatorId === current.creatorId);
            if (element) {
                element.count++;
                element.originalPrice += current.originalPrice;
                element.creatorTax += current.creatorTax;
            } else {
                prev.push({
                    creatorId: current.creatorId,
                    count: 1,
                    originalPrice: current.originalPrice,
                    creatorTax: current.creatorTax,
                });
            }
            return prev;
        }, []);
        console.log(groupBy)
}

// --------SalesManage--------
export const getSalesByUser = async (creatorId, startOfMonth, endOfMonth) =>{
  const fromDate = firebase.firestore.Timestamp.fromDate(startOfMonth);
  const toDate = firebase.firestore.Timestamp.fromDate(endOfMonth);
  // 当月にdoneになったrequestを取得
  // descにすると動かない、、、
  const data = await db.collection(cons.collectionName.requests).where("creatorId", "==", creatorId).where("status", "==", cons.requestStatus.done).orderBy("updatedAt", "asc").startAt(fromDate).endAt(toDate);
  return data.get().then((snapShot) => {
    let salesList = [];
    snapShot.forEach((doc) => {
      salesList.push({
        id: doc.id,
        creatorId: doc.data().creatorId,
        requestId: doc.data().requestId,
        requestorDisplayName: doc.data().requestorDisplayName,
        originalPrice: doc.data().originalPrice,
        updatedAt: doc.data().updatedAt,
      });
    });
    return salesList;
  });
}

// --------Contact--------

export const addContact = async (name, userId, email, content, doneFlg) =>{
  await db.collection(cons.collectionName.contacts).add({
    name: name,
    userId: userId,
    email: encryptoCode(email),
    content: content,
    doneFlg: doneFlg,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

// --------Room ID exist check--------
export const isAlreadyExistRoomId = async (roomId) =>{
  const profile = await db.collection(cons.collectionName.users).where("roomId", "==", roomId);
  return profile.get().then((snapShot) => {
    let profiles = [];
    snapShot.forEach((doc) => {
      profiles.push({
        id: doc.id
      });
    });
    return profiles.length > 0;
  });
}


const isProductionCreators = (creatorId) =>{
  if(creatorId !== cons.excludeAuthorId.sample && creatorId !== cons.excludeAuthorId.kaname){
    return true;
  }else{
    return false;
  }
}

const isAlreadyDisplayedWork = (workId, originalWorkId) =>{
  if(workId == originalWorkId){
    return true;
  }else{
    return false;
  }
}



// --------eventlogs--------
export const addEventlog = async (uid, eventCode, props, lang, trackingId) =>{
  await db.collection(cons.collectionName.eventlogs).add({
    uid: uid,
    eventCode: eventCode,
    props: props,
    lang: lang,
    trackingId: trackingId,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

// --------workviews--------
export const addOrUpdateWorkViews = async (workId) => {
  const workviews = await getWorkViewRecord(workId);
  if(workviews.length > 0){
    const workviewRef = await db.collection(cons.collectionName.workviews).doc(workviews[0].id);
      await workviewRef.update({
        viewCount: Number(workviews[0].viewCount+1),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  }else{
    await db.collection(cons.collectionName.workviews).add({
      workId: workId,
      viewCount: Number(1),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })  
  }
}

export const getWorkViewRecord = async (workId) =>{
  const workview = await db.collection(cons.collectionName.workviews).where("workId", "==", workId);
  return workview.get().then((snapShot) => {
    let workviews = [];
    snapShot.forEach((doc) => {
      workviews.push({
        id: doc.id,
        viewCount: doc.data().viewCount,
      });
    });
    return workviews;
  });
}

// メール送信用
export const sendEventTriggerMail = async (emailAddress, title, content) =>{
  db.collection(cons.collectionName.mail).add({
    to: emailAddress,
    message: {
      subject: title,
      text: content,
      html: "",
    },
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
  .then(() => console.log("Queued email for delivery!"));
}













