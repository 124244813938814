import React , { useState, useEffect, useContext } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";

import firebase, { storage } from "../service/firebase";
import main from '../css/main.module.css';
import requestcreate from '../css/requestcreate.module.css';

import { useLocation, useHistory } from 'react-router-dom';
import * as Api from "../service/api";
import * as Validation from "../service/validation";
import { requestClass, feeRate, notificationType, requestStatus , serviceUrl} from '../service/constants';

//Loader
import Loader from 'react-loader-spinner';

import dig from "object-dig";
import GoBack from "./GoBack";

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import * as mail from "./SendEventTriggerMail";

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//言語切替
import { useTranslation } from 'react-i18next';




// ログインしてなくてもリクエストできてしまう問題
function RequestCreate() {
    const userInfo = useContext(UserInfoContext);

    const [t] = useTranslation();

    const history = useHistory();

    const location = useLocation();
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;

    let creatorId = "";
    let workId = "";
    if(location.search !== ""){
        //先頭の?を除いて、split
        var urlParam = location.search.substring(1).split('&');
        //あんまりよろしくないですが
        creatorId = urlParam[0].substring(urlParam[0].indexOf('=') + 1);
        workId = urlParam[1].substring(urlParam[1].indexOf('=') + 1);
    }

    //ランダム文字列の末尾につける日付
    var now = new Date();
    var timestr= now.getFullYear().toString()+(now.getMonth()+1).toString()+now.getDate().toString()+now.getHours().toString()+now.getMinutes().toString()+now.getSeconds().toString();
    var requestId = Math.random().toString(32).substring(2) + timestr;

    const [others, setOthers] = useState("");
    const [requestType, setRequestType] = useState(requestClass.original);
    const [usePurpose, setUsePurpose] = useState("personal");
    const [objectGenger, setObjectGender] = useState("female");
    const [objectArea, setObjectArea] = useState("face");
    const [imageOrientation, setImageOrientation] = useState("vertical");
    const [existBackground, setExistBackground] = useState(true);
    const [existSignature, setExistSignature] = useState(true);
    const [requestorId, setRequestorId] = useState("");
    const [creatorProfile, setCreatorProfile] = useState({});
    const [langCode, setLangCode] = useState("");
    const [sampleImage1, setSampleImage1] = useState("");
    const [sampleImage1PreviewUrl, setSampleImage1PreviewUrl] = useState("");
    const [sampleImage2, setSampleImage2] = useState("");
    const [sampleImage2PreviewUrl, setSampleImage2PreviewUrl] = useState("");
    const [sampleImage3, setSampleImage3] = useState("");
    const [sampleImage3PreviewUrl, setSampleImage3PreviewUrl] = useState("");
    const [sampleImage4, setSampleImage4] = useState("");
    const [sampleImage4PreviewUrl, setSampleImage4PreviewUrl] = useState("");
    const [work, setWork] = useState({});
    const [willAttachImage, setWillAttachImage] = useState(false);
    const [isSending, setIsSending] = useState(false);

    // 価格計算用
    const [originalPrice, setOriginalPrice] = useState();
    const [tax, setTax] = useState();
    const [businessFee, setBusinessFee] = useState();
    const [paymentFee, setPaymentFee] = useState();
    
    const handleChangeRequestHandleType=(e)=>{
        setRequestType(e.target.value);
    }
    const handleChangeUsePurpose=(e)=>{
        setUsePurpose(e.target.value);
    }
    const handleChangeObjectGenger=(e)=>{
        setObjectGender(e.target.value);
    }
    const handleChangeObjectArea=(e)=>{
        setObjectArea(e.target.value);
    }
    const handleChangeImageOrientation=(e)=>{
        setImageOrientation(e.target.value);
    }
    //e.target.valueは文字列で入ってくる
    const handleChangeExistBackground=(e)=>{
        setExistBackground(e.target.value === "true");
    }
    const handleChangeExistSignature=(e)=>{
        setExistSignature(e.target.value === "true");
    }

    const calculatePaymentPrice = (originalPrice) => {
        setOriginalPrice(originalPrice);
        setBusinessFee(Math.ceil(originalPrice*feeRate.businessFee));
        setTax(Math.ceil(originalPrice*(1+feeRate.businessFee)*feeRate.tax));
        setPaymentFee(Math.ceil(originalPrice*(1+feeRate.businessFee)*(1+feeRate.tax)*feeRate.paymentFee));
    }

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(userInfo,"userInfo", "uid")){
                const data = userInfo.userInfo.langCode;
                setLangCode(data);
                setRequestorId(userInfo.userInfo.uid);
                const profileData = await Api.getProfileByUserId(creatorId);
                if(profileData.length > 0){
                    setCreatorProfile(profileData[0]);
                    calculatePaymentPrice(profileData[0].requestCreatePrice);
                }

            }    
            // image
            const workData = await Api.getWork(workId);
            setWork(workData);
        }
        fetch();
    }, [userInfo]);    
    
    let randomSampleImage1Name = "";
    let randomSampleImage2Name = "";
    let randomSampleImage3Name = "";
    let randomSampleImage4Name = "";

    const handleSampleImage1 = event => {
        const image = event.target.files[0];
        setSampleImage1(image);
        setSampleImage1PreviewUrl(createObjectURL(image));
    };


    const handleSampleImage2 = event => {
        const image = event.target.files[0];
        setSampleImage2(image);
        setSampleImage2PreviewUrl(createObjectURL(image));
    };

    const handleSampleImage3 = event => {
        const image = event.target.files[0];
        setSampleImage3(image);
        setSampleImage3PreviewUrl(createObjectURL(image));
    };

    const handleSampleImage4 = event => {
        const image = event.target.files[0];
        setSampleImage4(image);
        setSampleImage4PreviewUrl(createObjectURL(image));
    };

    const sampleImage1Compoent = () =>{
        if(sampleImage1PreviewUrl !== ""){
                return(
                    <div>
                        <label htmlFor="file1">
                            <div className={requestcreate.refimg_sample}>
                                <img src={sampleImage1PreviewUrl} alt="uploaded" className={requestcreate.preview_img}/>
                            </div>
                        </label>
                        <input type="file" onChange={handleSampleImage1} className={requestcreate.inputButton} id="file1" />
                    </div>
                )
            
        } else {
            return (
                        <div>
                            <label htmlFor="file1">
                                <div className={requestcreate.filearea}>
                                    <FontAwesomeIcon icon={['fal', 'plus-circle']} className={requestcreate.addicon}/>
                                    <div className={requestcreate.filelabel}>
                                        {t('request_create_upload_favorite_image')}
                                    </div>
                                </div>
                            </label>
                            <input type="file" onChange={handleSampleImage1} className={requestcreate.inputButton} id="file1" />
                        </div>
                    
            )
        }
    }

    const sampleImage2Compoent = () =>{
        if(sampleImage2PreviewUrl !== ""){
                return(
                    <div>
                        <label htmlFor="file2">
                            <div className={requestcreate.refimg_sample}>
                                <img src={sampleImage2PreviewUrl} alt="uploaded" className={requestcreate.preview_img}/>
                            </div>
                        </label>
                        <input type="file" onChange={handleSampleImage2} className={requestcreate.inputButton} id="file2" />
                    </div>
                )
            
        } else {
            return (
                        <div>
                            <label htmlFor="file2">
                                <div className={requestcreate.filearea}>
                                    <FontAwesomeIcon icon={['fal', 'plus-circle']} className={requestcreate.addicon}/>
                                    <div className={requestcreate.filelabel}>
                                        {t('request_create_upload_favorite_image')}
                                    </div>
                                </div>
                            </label>
                            <input type="file" onChange={handleSampleImage2} className={requestcreate.inputButton} id="file2" />
                        </div>
                    
            )
        }
    }

    const sampleImage3Compoent = () =>{
        if(sampleImage3PreviewUrl !== ""){
                return(
                    <div>
                        <label htmlFor="file3">
                            <div className={requestcreate.refimg_sample}>
                                <img src={sampleImage3PreviewUrl} alt="uploaded" className={requestcreate.preview_img}/>
                            </div>
                        </label>
                        <input type="file" onChange={handleSampleImage3} className={requestcreate.inputButton} id="file3" />
                    </div>
                )
            
        } else {
            return (
                        <div>
                            <label htmlFor="file3">
                                <div className={requestcreate.filearea}>
                                    <FontAwesomeIcon icon={['fal', 'plus-circle']} className={requestcreate.addicon}/>
                                    <div className={requestcreate.filelabel}>
                                        {t('request_create_upload_favorite_image')}
                                    </div>
                                </div>
                            </label>
                            <input type="file" onChange={handleSampleImage3} className={requestcreate.inputButton} id="file3" />
                        </div>
                    
            )
        }
    }

    const sampleImage4Compoent = () =>{
        if(sampleImage4PreviewUrl !== ""){
                return(
                    <div>
                        <label htmlFor="file4">
                            <div className={requestcreate.refimg_sample}>
                                <img src={sampleImage4PreviewUrl} alt="uploaded" className={requestcreate.preview_img}/>
                            </div>
                        </label>
                        <input type="file" onChange={handleSampleImage4} className={requestcreate.inputButton} id="file4" />
                    </div>
                )
            
        } else {
            return (
                        <div>
                            <label htmlFor="file4">
                                <div className={requestcreate.filearea}>
                                    <FontAwesomeIcon icon={['fal', 'plus-circle']} className={requestcreate.addicon}/>
                                    <div className={requestcreate.filelabel}>
                                        {t('request_create_upload_favorite_image')}
                                    </div>
                                </div>
                            </label>
                            <input type="file" onChange={handleSampleImage4} className={requestcreate.inputButton} id="file4" />
                        </div>
                    
            )
        }
    }

    const submitButtonComponent = () => {
        if(isSending){
            return(
                <div className={requestcreate.submit_btn_area}>              
                    <div className={main.loading_area} >
                        {/* {t('request_create_form_submit_button_is_sending')} */}
                        <Loader
                            type="ThreeDots"
                            color="#5278FF"
                            height={36}
                            width={36}
                        />
                    </div>
                </div>
            )
        }else{
            return(
                <div className={requestcreate.submit_btn_area}>              
                    <button type="submit" className={main.primary_btn} >
                        {t('request_create_form_submit_button')}
                    </button>
                </div>
            )
        }
    }

    // requestId: request生成時にrandomで生成
    // requestIdで一意性を担保する(ランダム文字列+登録日時)

    const postRequest = async (event) =>{
        event.preventDefault();
        setIsSending(true);
        // requestorのprofileImageUrlを足す
        // Userinfoから取れるようにした
        if(dig(userInfo, "userInfo", "profileImageUrl")){
            if(dig(userInfo, "userInfo", "displayName")){
                if(dig(userInfo, "userInfo", "email")){
                    await Api.createRequest(
                        requestorId, 
                        requestId, 
                        creatorId, 
                        requestStatus.beforeConfirm,
                        requestType, 
                        usePurpose, 
                        objectGenger, 
                        objectArea, 
                        imageOrientation,
                        existBackground, 
                        existSignature, 
                        others,
                        langCode,
                        userInfo.userInfo.profileImageUrl,
                        creatorProfile.profileImageUrl,
                        userInfo.userInfo.displayName,
                        creatorProfile.displayName,
                        userInfo.userInfo.email,
                        creatorProfile.email,
                        workId,
                        work.imageUrl,
                        originalPrice,
                        tax,
                        businessFee,
                        paymentFee,
                        willAttachImage
                    );

                    await Api.createNotificationForCreator(
                        requestId, 
                        requestorId, 
                        creatorId, 
                        requestType, 
                        notificationType.confirmRequest, 
                        userInfo.userInfo.displayName, 
                        creatorProfile.displayName, 
                        userInfo.userInfo.profileImageUrl, 
                        creatorProfile.profileImageUrl
                    );

                    // クリエイターさんにメール送る
                    let title = `${userInfo.userInfo.displayName}さんから依頼がありました`;
                    let content = creatorProfile.displayName +"さま\n\nいつもartlessをご利用いただきありがとうございます。\n\n"
                    +userInfo.userInfo.displayName
                    +"さんから依頼がありました。\n下記URLからサイトにアクセスして、詳細をご確認ください。\n\n"
                    +serviceUrl.urlToRequestDetail+"/?reuqestId="+requestId
                    +"\n\nご不明点がある場合、またはお心当たりがない場合は、下記のメールアドレスにお問い合わせください。\n\nartless.gallery.contact@gmail.com\n\n—————————-\nartless運営事務局\n—————————-";
        
                    mail.SendEventTriggerMail(creatorProfile.email, title, content);     
        

                    // 画像のアップロード
                    if (sampleImage1 === "") {
                        console.log("ファイルが選択されていません_1");
                    }else{
                        if(dig(userInfo,"userInfo", "uid")){
                            
                            //ランダム文字列の末尾につける日付
                            var now1 = new Date();
                            var timestr1 = now1.getFullYear().toString()+(now1.getMonth()+1).toString()+now1.getDate().toString()+now1.getHours().toString()+now1.getMinutes().toString();
                    
                            // アップロード前にファイル名をランダム文字列に書き換える
                            // 同じファイル名のファイルはcloudstorageが受け付けない
                            // あともし同じ文字列があった場合に再度ランダマイズ？
                            randomSampleImage1Name = Math.random().toString(32).substring(2)+timestr1;
                            // プロフィール画像のアップロード処理
                            const uploadProfTask = storage.ref(`/images/${randomSampleImage1Name}`).put(sampleImage1);
                            uploadProfTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED,
                            next,
                            error,
                            completeSampleImage1
                            );
                        }else{
                            console.log("uidがないため、失敗");
                        }
                    }
                    if (sampleImage2 === "") {
                        console.log("ファイルが選択されていません_2");
                    }else{
                        if(dig(userInfo,"userInfo", "uid")){
                            
                            //ランダム文字列の末尾につける日付
                            var now2 = new Date();
                            var timestr2 = now2.getFullYear().toString()+(now2.getMonth()+1).toString()+now2.getDate().toString()+now2.getHours().toString()+now2.getMinutes().toString();
                    
                            // アップロード前にファイル名をランダム文字列に書き換える
                            // 同じファイル名のファイルはcloudstorageが受け付けない
                            // あともし同じ文字列があった場合に再度ランダマイズ？
                            randomSampleImage2Name = Math.random().toString(32).substring(2)+timestr2;
                            // プロフィール画像のアップロード処理
                            const uploadProfTask = storage.ref(`/images/${randomSampleImage2Name}`).put(sampleImage2);
                            uploadProfTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED,
                            next,
                            error,
                            completeSampleImage2
                            );
                        }else{
                            console.log("uidがないため、失敗");
                        }
                    }
                    if (sampleImage3 === "") {
                        console.log("ファイルが選択されていません_3");
                    }else{
                        if(dig(userInfo,"userInfo", "uid")){
                            
                            //ランダム文字列の末尾につける日付
                            var now3 = new Date();
                            var timestr3 = now3.getFullYear().toString()+(now3.getMonth()+1).toString()+now3.getDate().toString()+now3.getHours().toString()+now3.getMinutes().toString();
                    
                            // アップロード前にファイル名をランダム文字列に書き換える
                            // 同じファイル名のファイルはcloudstorageが受け付けない
                            // あともし同じ文字列があった場合に再度ランダマイズ？
                            randomSampleImage3Name = Math.random().toString(32).substring(2)+timestr3;
                            // プロフィール画像のアップロード処理
                            const uploadProfTask = storage.ref(`/images/${randomSampleImage3Name}`).put(sampleImage3);
                            uploadProfTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED,
                            next,
                            error,
                            completeSampleImage3
                            );
                        }else{
                            console.log("uidがないため、失敗");
                        }
                    }
                    if (sampleImage4 === "") {
                        console.log("ファイルが選択されていません_4");
                    }else{
                        if(dig(userInfo,"userInfo", "uid")){
                            
                            //ランダム文字列の末尾につける日付
                            var now4 = new Date();
                            var timestr4 = now4.getFullYear().toString()+(now4.getMonth()+1).toString()+now4.getDate().toString()+now4.getHours().toString()+now4.getMinutes().toString();
                    
                            // アップロード前にファイル名をランダム文字列に書き換える
                            // 同じファイル名のファイルはcloudstorageが受け付けない
                            // あともし同じ文字列があった場合に再度ランダマイズ？
                            randomSampleImage4Name = Math.random().toString(32).substring(2)+timestr4;
                            // プロフィール画像のアップロード処理
                            const uploadProfTask = storage.ref(`/images/${randomSampleImage4Name}`).put(sampleImage4);
                            uploadProfTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED,
                            next,
                            error,
                            completeSampleImage4
                            );
                        }else{
                            console.log("uidがないため、失敗");
                        }
                    }
                }
            }
        }
        setTimeout(() => {
            moveToThanksPage();
        }, 3 * 1000);
    }

    const moveToThanksPage = () =>{
        history.push(toRequestThanks);
    }

    const completeSampleImage1 = async () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        const url1 = await storage.ref("images").child(randomSampleImage1Name).getDownloadURL();
        const data = await Api.getRequest(requestId);
        if(data.length >0){
            const requestDocId = data[0].id;
            await Api.updateRequestSampleImage1Url(requestDocId, url1);
        }

    };
    const completeSampleImage2 = async () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        const url2 = await storage.ref("images").child(randomSampleImage2Name).getDownloadURL();
        const data = await Api.getRequest(requestId);
        if(data.length >0){
            const requestDocId = data[0].id;
            await Api.updateRequestSampleImage2Url(requestDocId, url2);
        }
    };
    const completeSampleImage3 = async () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        const url3 = await storage.ref("images").child(randomSampleImage3Name).getDownloadURL();
        const data = await Api.getRequest(requestId);
        if(data.length >0){
            const requestDocId = data[0].id;
            await Api.updateRequestSampleImage3Url(requestDocId, url3);
        }
    };
    const completeSampleImage4 = async () => {
        // 完了後の処理
        // 画像表示のため、アップロードした画像のURLを取得
        const url3 = await storage.ref("images").child(randomSampleImage4Name).getDownloadURL();
        const data = await Api.getRequest(requestId);
        if(data.length >0){
            const requestDocId = data[0].id;
            await Api.updateRequestSampleImage4Url(requestDocId, url3);
        }
    };

    const next = snapshot => {
        // 進行中のsnapshotを得る
        // アップロードの進行度を表示
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        console.log(snapshot);
    };
    const error = error => {
        // エラーハンドリング
        console.log(error);
    };

    const toRequestThanks = {
        pathname: '/thanks',
        search: '?from=requestcreate',
    };



    const requestCreateMainCompomnent = () =>{
        if(dig(userInfo, "userInfo", "uid")){
            return(
                <React.Fragment>
                    <GoBack />
                    <h2>{t('request_create_title')}</h2>
                    <div className={requestcreate.inputarea}>
                            <FormControl 
                                variant="standard" 
                                sx={{ m: 1, minWidth: 120 }}
                                className={requestcreate.selectarea}
                            >
                            <InputLabel 
                                id="demo-simple-select-standard-label-content" 
                            >
                                {t('request_create_form_title')}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label-content"
                                id="demo-simple-select-standard"
                                value={requestType}
                                onChange={handleChangeRequestHandleType}
                                label={t('request_create_form_request_type')}
                            >
                                <MenuItem value={"original"}>{t('request_create_form_request_type_option1')}</MenuItem>
                                <MenuItem value={"icon"}>{t('request_create_form_request_type_option2')}</MenuItem>
                                <MenuItem value={"portrait"}>{t('request_create_form_request_type_option3')}</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                        <div className={requestcreate.inputarea}>
                            <FormControl 
                                variant="standard" 
                                sx={{ m: 1, minWidth: 120 }}
                                className={requestcreate.selectarea}
                            >
                            <InputLabel id="demo-simple-select-standard-label-usepupose">{t('request_create_form_purpose_of_use')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label-usepupose"
                                id="demo-simple-select-standard"
                                value={usePurpose}
                                onChange={handleChangeUsePurpose}
                                label={t('request_create_form_purpose_of_use')}
                            >
                                <MenuItem value={"personal"}>{t('request_create_form_purpose_of_use_option1')}</MenuItem>
                                <MenuItem value={"snsicon"}>{t('request_create_form_purpose_of_use_option2')}</MenuItem>
                                <MenuItem value={"commercial"}>{t('request_create_form_purpose_of_use_option3')}</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                        <div className={requestcreate.inputarea}>
                            <FormControl 
                                variant="standard" 
                                sx={{ m: 1, minWidth: 120 }}
                                className={requestcreate.selectarea}
                            >
                            <InputLabel id="demo-simple-select-standard-label-objectgender">{t('request_create_form_gender_of_object')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label-objectgender"
                                id="demo-simple-select-standard"
                                value={objectGenger}
                                onChange={handleChangeObjectGenger}
                                label={t('request_create_form_gender_of_object')}
                            >
                                <MenuItem value={"male"}>{t('request_create_form_gender_of_object_option1')}</MenuItem>
                                <MenuItem value={"female"}>{t('request_create_form_gender_of_object_option2')}</MenuItem>
                                <MenuItem value={"anythingok"}>{t('request_create_form_anything_fine_option')}</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                        <div className={requestcreate.inputarea}>
                            <FormControl 
                                variant="standard" 
                                sx={{ m: 1, minWidth: 120 }}
                                className={requestcreate.selectarea}
                            >
                            <InputLabel id="demo-simple-select-standard-label-objectarea">{t('request_create_form_draw_area')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label-objectarea"
                                id="demo-simple-select-standard"
                                value={objectArea}
                                onChange={handleChangeObjectArea}
                                label={t('request_create_form_draw_area')}
                            >
                                <MenuItem value={"face"}>{t('request_create_form_draw_area_option1')}</MenuItem>
                                <MenuItem value={"bust"}>{t('request_create_form_draw_area_option2')}</MenuItem>
                                <MenuItem value={"full"}>{t('request_create_form_draw_area_option3')}</MenuItem>
                                <MenuItem value={"anythingok"}>{t('request_create_form_anything_fine_option')}</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                        <div className={requestcreate.inputarea}>
                            <FormControl 
                                variant="standard" 
                                sx={{ m: 1, minWidth: 120 }}
                                className={requestcreate.selectarea}
                            >
                            <InputLabel id="demo-simple-select-standard-label-imageOrientation">{t('request_create_form_image_orientation')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label-imageOrientation"
                                id="demo-simple-select-standard"
                                value={imageOrientation}
                                onChange={handleChangeImageOrientation}
                                label={t('request_create_form_image_orientation')}
                            >
                                <MenuItem value={"vertical"}>{t('request_create_form_image_orientation_option1')}</MenuItem>
                                <MenuItem value={"horizontal"}>{t('request_create_form_image_orientation_option2')}</MenuItem>
                                <MenuItem value={"anythingok"}>{t('request_create_form_anything_fine_option')}</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                        <div className={requestcreate.inputarea}>
                            <FormControl 
                                variant="standard" 
                                sx={{ m: 1, minWidth: 120 }}
                                className={requestcreate.selectarea}
                            >
                            <InputLabel id="demo-simple-select-standard-label-existbaclground">{t('request_create_form_exist_background')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label-existbaclground"
                                id="demo-simple-select-standard"
                                value={existBackground}
                                onChange={handleChangeExistBackground}
                                label={t('request_create_form_exist_background')}
                            >
                                <MenuItem value={true}>{t('request_create_form_exist_background_option1')}</MenuItem>
                                <MenuItem value={false}>{t('request_create_form_exist_background_option2')}</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                        <div className={requestcreate.inputarea}>
                            <FormControl 
                                variant="standard" 
                                sx={{ m: 1, minWidth: 120 }}
                                className={requestcreate.selectarea}
                            >
                            <InputLabel id="demo-simple-select-standard-label-existsignature">{t('request_create_form_exist_signature')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label-existsignature"
                                id="demo-simple-select-standard"
                                value={existSignature}
                                onChange={handleChangeExistSignature}
                                label={t('request_create_form_exist_signature')}
                            >
                                <MenuItem value={true}>{t('request_create_form_exist_signature_option1')}</MenuItem>
                                <MenuItem value={false}>{t('request_create_form_exist_signature_option2')}</MenuItem>
                            </Select>
                            </FormControl>
                        </div>
                        <div className={requestcreate.inputarea}>
                            <TextField 
                                placeholder={t('request_create_others_placeholder')}
                                multiline
                                rows="8"
                                margin="normal"
                                variant="outlined"                            
                                className={requestcreate.inputtext} 
                                name="name" 
                                label={t('request_create_form_other_requirement')}
                                type="textarea" 
                                required 
                                value={others} 
                                onChange={(event)=> setOthers(event.currentTarget.value)} 
                            />
                        </div>
                        <div className={requestcreate.checkboxarea}>
                            <input 
                                type="checkbox" 
                                id="checkbox_attach_image" 
                                name="checkbox_attach_image" 
                                onChange={(event)=> setWillAttachImage(event.currentTarget.checked)}
                            />
                            <label htmlFor="checkbox_attach_image" className={requestcreate.checkbox_attach_image}>{t('request_create_form_attach_smaple_image')}</label>
                        </div>
                        <div className={requestcreate.attention_sns}>
                            <p>{t('request_create_form_attention_sns')}</p>
                        </div>

                    <form onSubmit={postRequest}>
                        {willAttachImage?
                        <div className={requestcreate.sample_image_attach}>
                            <h4>{t('request_create_form_sample_image_1')}</h4>
                                {sampleImage1Compoent()}
                            <h4>{t('request_create_form_sample_image_2')}</h4>
                                {sampleImage2Compoent()}
                            <h4>{t('request_create_form_sample_image_3')}</h4>
                                {sampleImage3Compoent()}
                            <h4>{t('request_create_form_sample_image_4')}</h4>
                                {sampleImage4Compoent()}
                        </div>
                        :
                        <div></div>
                        }
                        {submitButtonComponent()}
                    </form>
                </React.Fragment>
            )
        }else{
            return(
                <div>
                    <br />
                    {t('confirm_after_login')}
                </div>
            )
        }
    }

    return(
        <div>
            {requestCreateMainCompomnent()}
        </div>
    )
}

export default RequestCreate;