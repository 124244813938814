import React , { useContext , useState, useEffect} from "react";
import { signInWithGoogle, logOut } from "../service/firebase";
import { UserInfoContext } from "../providers/UserInfoProvider";

import dig from "object-dig";
import Badge from "@material-ui/core/Badge";
import PropTypes from 'prop-types';
import confirmationdialog from '../css/confirmationdialog.module.css';
import hamburgermenu from '../css/hamburgermenu.module.css';
import servicelogo from '../img/logo.png';
import { Link, useLocation } from 'react-router-dom';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';

import { makeStyles } from "@material-ui/core/styles";
import * as Api from "../service/api";
import * as Logic from "../service/logic";
import { languageCode, serviceUrl, subject, eventCode, queryParameterKeys } from '../service/constants';
import toast, { Toaster } from 'react-hot-toast';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import SwitchLanguageModal from "./SwitchLanguageModal";



//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";



//言語切替
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import enJson from '../locales/en.json';
import jaJson from '../locales/ja.json';
import twJson from '../locales/tw.json';
import zhJson from '../locales/zh.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {translation: enJson },
    ja: {translation: jaJson },
    tw: {translation: twJson },
    zh: {translation: zhJson },
  },
  lng: 'ja',
  fallbackLng: 'ja',
  interpolation: { escapeValue: false },
});

// material UIのCSSだけ、一旦直指定
const useStyles = makeStyles(() => ({
  linkText: {
      textDecoration: "none",
      color : "#000"
  },
})
);

// サインインボタンタップ時の確認ポップアップのアニメーション
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});  



function Header(props) {
  const userInfo = useContext(UserInfoContext);

  const classes = useStyles();

  const [toggleOpen, setToggleOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [firstVisit, setFirstVisit] = useState(true);


  const [t, i18n] = useTranslation();

  const location = useLocation();
  let queryparam_langCode = "";
  let trackingId = "";
  let queryParams = {};
  queryParams = Logic.convertToAssociatvieArray(location);
  for (let key in queryParams) {
    console.log('key_result:' + key + ' value_result:' + queryParams[key]);
  }
  if(Object.keys(queryParams).length > 0){
    if(typeof queryParams[queryParameterKeys.langCode] !== "undefined"){
      queryparam_langCode = queryParams[queryParameterKeys.langCode]
    }
    if(typeof queryParams[queryParameterKeys.trackingId] !== "undefined"){
      trackingId = queryParams[queryParameterKeys.trackingId]
    }
  }

  const [initialLanguageConfirmationDialogOpen, setInitialLanguageConfirmationDialogOpen] = useState(false);

  window.addEventListener("load",function() {
    // イニシャル画面のロードが終わったら表示する
    setTimeout(setInitialLanguageConfirmationDialogOpen(true), 100);
  });

  const handleToggle = () => {
    setToggleOpen(!toggleOpen);
  };
  
  const handleSwitchLang = (code) => {
    // ログインしてないときは、固定の言語だけ切り替える
    // 未ログインで英語フラグに切り替え

    props.changeLangCode(code);
    i18n.changeLanguage(code);

    if(dig(userInfo, "userInfo", "uid")){
      // DBの更新と今持ってるUserInfoの上書き
      Api.updateLangFlg(userInfo.userInfo.docId, code);
      userInfo.userInfo.langCode = code;
    }
  };

  useEffect(()=>{
    const fetch = async () =>{
      if(dig(userInfo,"userInfo", "uid")){
        setUserId(userInfo.userInfo.uid);
        setDisplayName(userInfo.userInfo.displayName);
      
        // ログインしてるなら、登録の言語情報を詰める
        const data_langCode = userInfo.userInfo.langCode;
        handleSwitchLang(data_langCode);
        // //props.changeLangCode(data_langCode);
        // if(data_langCode === languageCode.english){
        //   // ベタ書きはあとで直す
        //   i18n.changeLanguage("en");
        // }else{
        //   i18n.changeLanguage("ja");
        // }
      
        // ここでバッジカウント
        // 切り出して、呼び出し元でasync awaitかけないと、返り値がpromiseでハンドリングできない
        const dataNotificationCount = await Api.getNotificationCountByUserId(userInfo.userInfo.uid);

        // 最後にまとめて変更
        props.changeCreatorFlgAndLangCodeAndNotificationCount(userInfo.userInfo.isCreator, data_langCode, dataNotificationCount.length);
      }
    }
    if(dig(userInfo,"userInfo", "browserLanguage") && firstVisit){
      if(userInfo.userInfo.browserLanguage === "ja"){
        handleSwitchLang(languageCode.japanese)
      }else{
        handleSwitchLang(languageCode.english)
      }
    }
    if(queryparam_langCode !== "" && firstVisit){
      if(queryparam_langCode === "en" || queryparam_langCode === "ja"){
        handleSwitchLang(queryparam_langCode);
        setFirstVisit(false);
      }
    }
    fetch();

    if(firstVisit){
      Logic.eventLogging(userInfo, eventCode.firstVisit, "", trackingId);
      setFirstVisit(false);
    }

  }, [userInfo, i18n, props]);


  const isInstagram = () =>{
    var userAgent = navigator.userAgent.toLowerCase();
    if(!userAgent.includes("safari") && !userAgent.includes("chrome")){
      return true;
    }else{
      return false;
    }
  }

  const loginButton = () => {
    // 再代入可能
    let buttonDom
    if(dig(userInfo,"userInfo", "uid")){
        buttonDom = <Button className={hamburgermenu.button} onClick={logOut}>{t('logout')}</Button>
    }else{
        if(isInstagram()){
          buttonDom = <Button className={hamburgermenu.button} onClick={handleDailogOpen}>{t('login')}</Button>
        }else{
          buttonDom = <Button className={hamburgermenu.button} onClick={signInWithGoogle}>{t('login')}</Button>
        }
    }
    return buttonDom
  }

  const addEventlogTapSignIn = () =>{
    Logic.eventLogging(userInfo, eventCode.tapSingin, "", trackingId);
  }

  const addEventlogCopyText = () =>{
    Logic.eventLogging(userInfo, eventCode.closeSigninDialogByCopyUrl, "", trackingId);
  }

  const addEventlogDialogClose = () =>{
    Logic.eventLogging(userInfo, eventCode.closeSigninDialog, "", trackingId);
  }

  const handleDailogOpen = () => {
    setDialogOpen(true);
    addEventlogTapSignIn();
  };

  const handleDailogCloseAndCopyUrl = () => {
    copyText();
    notifyCopied();
    setDialogOpen(false);
    addEventlogCopyText();
  };

  const handleDailogClose = () => {
    setDialogOpen(false);
    addEventlogDialogClose();
  };

  const copyText = () => {
    navigator.clipboard.writeText(serviceUrl.url);
  };

  const successCopiedMessage =  props.langCode === "ja" ? "サイトURLをコピーしました！":"Copied the URL!";
  const notifyCopied = () => toast.success(successCopiedMessage,
      {
          style: {
              border: '1px solid #94ABFF',
              borderRadius: '10px',
              background: '#ffffff',
              color: '#94ABFF',
          },
      duration: 2000,
      }
  );

  const signIn = () => {
    handleDailogClose();
    Logic.eventLogging(userInfo, eventCode.doSignIn, "", trackingId);
    signInWithGoogle();
  };



  const confirmationDialogComponent = () => {
    return(
        <div>
            <Toaster />
            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDailogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t('dialog_confirmation_before_login_title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('dialog_confirmation_before_login_body1')}
                        <br />
                        <br />
                        {t('dialog_confirmation_before_login_body2')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={confirmationdialog.secondary_option} onClick={handleDailogCloseAndCopyUrl}>{t('dialog_confirmation_before_login_secondary_option')}</Button>
                    <Link to="/" className={confirmationdialog.primary_option} onClick={signIn}>{t('dialog_confirmation_before_login_primary_option')}</Link>
                </DialogActions>
            </Dialog>
        </div>
    )
  }

  const toProfileLinkComponent = () => {
    // 再代入可能
    let linkDom
    if(displayName === ""){
        // 未ログインなのでただのテキスト
        linkDom = <Link to='' onClick={handleToggle}>ゲストさん</Link>
    } else {
        linkDom = <Link to='/myaccount' onClick={handleToggle}>{displayName}さん</Link>
    }
    return linkDom
  }

  const switchLanguageComponentOld = () => {
    if(props.langCode === languageCode.japanese){
      return(
        <div className={hamburgermenu.translation}>
          <a onClick={()=> handleSwitchLang(languageCode.english)}><span className={hamburgermenu.focus}>JP</span>&nbsp;/&nbsp;EN</a>
        </div>
      )
    }else{
      return(
        <div className={hamburgermenu.translation}>
          <a onClick={()=> handleSwitchLang(languageCode.japanese)}>JP&nbsp;/&nbsp;<span className={hamburgermenu.focus}>EN</span></a>
        </div>
      )
    }
  }

  const switchLanguageComponent = () => {
      return(
        <SwitchLanguageModal handleSwitchLang={handleSwitchLang} initialDisplayOn={props.isLoading}/>
      )
  }



  return (
    <div className={hamburgermenu.header}>

        <div className={hamburgermenu.logo}>
            <Link to='/'>
                <img src={servicelogo} alt="artless" />
            </Link>
        </div>
        {userId === ""?
        <div className={hamburgermenu.attention_label}>
          Switch!
        </div>
        :
        <div></div>
        }
        {switchLanguageComponent()}
        {/* タスクリスト機能はいずれ */}
        {/* <div className={hamburgermenu.check}>
          <Badge badgeContent={props.notificationCount} overlap="circular" color="error">
            <CheckIcon></CheckIcon>
          </Badge>
        </div> */}
        <div className={hamburgermenu.bell}>
        <Link to='/notifications' 
          className={classes.linkText} 
          > &nbsp;&nbsp;
        {props.notificationCount > 0?
          <Badge badgeContent={props.notificationCount} overlap="circular" color="error">
                <NotificationsNoneOutlinedIcon style={{ fontSize: 30 }} ></NotificationsNoneOutlinedIcon>
          </Badge>
        :          
          <NotificationsNoneOutlinedIcon style={{ fontSize: 30 }} ></NotificationsNoneOutlinedIcon>
        }
        </Link>
        </div>

        <div className={(toggleOpen ? hamburgermenu.open : "")}>
            <nav>
                <div className={hamburgermenu.inner}>
                <ul>
                    <li>
                        <div className={hamburgermenu.login_user_name}>
                          {toProfileLinkComponent()}
                        </div>
                        <div className={hamburgermenu.login_btn}>
                            {loginButton()}
                        </div>
                        {confirmationDialogComponent()}
                    </li>
                    {props.isCreator && userId !== ""?
                      <li className={hamburgermenu.menu_title}>
                        <p>{t('menu_title_creatormenu')}</p>
                      </li>
                      :
                      <div></div>
                    }
                    {/* {!props.isCreator && userId !== ""?
                      <li>
                          <Link to='/creatorregister' onClick={handleToggle}>
                            <FontAwesomeIcon icon={['fal', 'pen']} />
                            &nbsp;&nbsp;
                            {t('menu_content_creatorregister')}
                          </Link>
                      </li>
                    :
                      <div></div>
                    } */}
                    {props.isCreator ?
                      <React.Fragment>
                        <li><Link to='/myaccount' onClick={handleToggle}>
                          <FontAwesomeIcon icon={['fal', 'user']} />
                          &nbsp;&nbsp;{t('menu_content_creatorinfo')}</Link>
                        </li>
                        <li><Link to='/requestmanage' onClick={handleToggle}>
                          <FontAwesomeIcon icon={['fal', 'tasks']} />
                          &nbsp;&nbsp;{t('menu_content_requestmanage')}</Link>
                        </li>
                        <li><Link to='/salesmanage' onClick={handleToggle}>
                          <FontAwesomeIcon icon={['fal', 'yen-sign']} />
                          &nbsp;&nbsp;{t('menu_content_salesmanage')}</Link>
                        </li>
                        <li><Link to='/workmanage' onClick={handleToggle}>
                          <FontAwesomeIcon icon={['fal', 'palette']} />
                          &nbsp;&nbsp;{t('menu_content_worksmanage')}</Link>
                        </li>
                        <li><Link to='/bankaccountregister' onClick={handleToggle}>
                          <FontAwesomeIcon icon={['fal', 'university']} />
                          &nbsp;&nbsp;{t('menu_content_bankaccount')}</Link>
                        </li>
                      </React.Fragment>
                    :
                      <div></div>
                    }
                    <li className={hamburgermenu.menu_title}>
                      <p>{t('menu_title_usermenu')}</p>
                    </li>
                    <li><Link to='/creators' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'paint-brush']} />
                      &nbsp;&nbsp;{t('menu_content_creators')}</Link>
                    </li>
                    {userId !== "" ?
                    <li><Link to='/myaccount' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'user']} />
                      &nbsp;&nbsp;{t('menu_content_myprofile')}</Link>
                    </li>
                    :
                    <div></div>
                    }
                    <li><Link to='/likes' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'star']} />
                      &nbsp;&nbsp;{t('menu_content_likes')}</Link>
                    </li>
                    <li><Link to='/follows' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'plus']} />
                      &nbsp;&nbsp;{t('menu_content_follows')}</Link>
                    </li>
                    <li><Link to='/requesthistory' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'shopping-cart']} />
                      &nbsp;&nbsp;{t('menu_content_requests')}</Link>
                    </li>
                    <li><Link to='/contact' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'envelope']} />
                      &nbsp;&nbsp;{t('menu_content_contact')}</Link>
                    </li>
                    <li><Link to='/howtouse' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'mobile-alt']} />
                      &nbsp;&nbsp;{t('menu_content_howtouse')}</Link>
                    </li>
                    <li><Link to='/guideline' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'book']} />
                      &nbsp;&nbsp;{t('menu_content_guideline')}</Link>
                    </li>
                    <li className={hamburgermenu.menu_title}>
                      <p>{t('menu_title_artless')}</p>
                    </li>
                    <li><Link to='/ourstory' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'heart']} />
                      &nbsp;&nbsp;{t('menu_content_ourstory')}</Link>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/kurita_irasuto/" target="_blank" rel="noopener noreferrer" onClick={handleToggle}>
                        <FontAwesomeIcon icon={faInstagram} />
                        &nbsp;&nbsp;{t('menu_content_instagram')}
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/kurita_irasuto" target="_blank" rel="noopener noreferrer" onClick={handleToggle}>
                        <FontAwesomeIcon icon={faTwitter} />
                        &nbsp;&nbsp;{t('menu_content_twitter')}
                      </a>
                    </li>
                    <li><Link to='/termsofuse' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'file-alt']} />
                      &nbsp;&nbsp;{t('menu_content_termsofuse_privacypolicy')}
                      </Link>
                    </li>
                    <li><Link to='/company' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'building']} />
                      &nbsp;&nbsp;{t('menu_content_company')}</Link>
                    </li>
                    <li><Link to='/specifiedcommercial' onClick={handleToggle}>
                      <FontAwesomeIcon icon={['fal', 'book-open']} />
                      &nbsp;&nbsp;{t('menu_content_specifiedcommercial')}</Link>
                    </li>
                    {!props.isCreator && userId !== ""?
                      <li className={hamburgermenu.hidden_link}>
                          <Link to='/creatorregister' onClick={handleToggle}>
                            <FontAwesomeIcon icon={['fal', 'pen']} />
                            &nbsp;&nbsp;
                            {t('menu_content_creatorregister')}
                          </Link>
                      </li>
                    :
                      <div></div>
                    }

                </ul>
                </div>
            </nav>
        
            <div className={hamburgermenu.toggle_btn} onClick={handleToggle}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            
            <div className={hamburgermenu.mask}>
              <div className={hamburgermenu.close_area} onClick={handleToggle}></div>
            </div>
        </div>
    </div>    

  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
