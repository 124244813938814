import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from "@material-ui/core/styles";

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import hamburgermenu from '../css/hamburgermenu.module.css';

import { languageCode } from '../service/constants';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const options = ['日本語', 'English', '繁體中文', '簡体中文'];

const useStyles = makeStyles(
  theme => ({
    listItemSelected: {
      backgroundColor: "#F5F6FA!important", 
      color: "#5278FF",
    },
    listItem: {
      fontSize: 16,
      fontWeight: 400,
      paddingTop: "8px",
      paddingBottom: "8px",
      textAlign:"center",
      "&:hover, &:focus": {
        backgroundColor: "#5278FF",
        color: "white"
      }
    }
  }),
  { name: "ListMenu" }
);


function SelectDialog(props) {
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleMenuItemClick = (option, index) => {
    setSelectedIndex(index);
    onClose(option);
  };


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select Your Language</DialogTitle>
      <List
        >
          {options.map((option, index) => (
            <ListItem
              key={option}
              selected={index === selectedIndex}
              classes={{
                selected: classes.listItemSelected
              }}  

              onClick={() => handleMenuItemClick(option, index)}
            >
              <ListItemText 
                primary={option} 
                classes={{
                  root: classes.listItem,
                }}  
              />
            </ListItem>
          ))}
        </List>

    </Dialog>
  );
}

SelectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SwitchLanguageModal(props) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(options[1]);


  useEffect(()=>{
    const fetch = async () =>{
      // ローディング画面が出てるときはなにもしない
      if(!props.initialDisplayOn){
        if (document.cookie.indexOf('visited=yes') === -1) {
          document.cookie = 'visited=yes path=/';
          console.log('初回のアクセスです');
          // 初回アクセス時のみ、クリックせずにopen
          setOpen(!props.initialDisplayOn);
        } else {
          // 2回目以降のアクセス
          console.log('2回目以降のアクセスです'+open);
        }  
      }
    }
    fetch();
  }, [props]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    if(value === '日本語'){
      props.handleSwitchLang(languageCode.japanese);
    } else if (value === 'English'){
      props.handleSwitchLang(languageCode.english);
    } else if (value === '繁體中文'){
      // 繁体が台湾
      props.handleSwitchLang(languageCode.taiwan);
    } else if (value === '簡体中文'){
      // 簡体が中国
      props.handleSwitchLang(languageCode.chinese);
    }else{
      // languageCodeがunknownの場合は英語
      props.handleSwitchLang(languageCode.english);
    }
  };

  return (
    <div>
      {/* デバッグ用に残しとく */}
      {/* <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography> */}
      <div className={hamburgermenu.translation_icon} onClick={handleClickOpen}>
        <FontAwesomeIcon icon={['far', 'language']} />
      </div>
      <SelectDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
