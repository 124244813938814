import React , { useState, useEffect, useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import * as Api from "../service/api";
import dig from "object-dig";
import SinglSquareImageList from "./SinglSquareImageList";
import GoBack from "./GoBack";

//言語切替
import { useTranslation } from 'react-i18next';



function Likes() {
    const currentUser = useContext(AuthContext);
    const [images, setImages] = useState([]);

    const [t] = useTranslation();

    useEffect(()=>{
        const fetch = async() =>{
            if(dig(currentUser,"currentUser", "uid")){
                const likeImagesList = await Api.getLikeListOfUser(currentUser.currentUser.uid);
                setImages(likeImagesList);
            }
        }    
        fetch();
    }, [currentUser]);

    return(
        <div>
            <GoBack />
            <h2>{t('likes_title')}</h2>
            <div>
            {images.length >0 ?
            <SinglSquareImageList images={images} />
            :
            <p>{t('likes_nothing')}</p>
            }
            </div>
        </div>
    )
}

export default Likes;