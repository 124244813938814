import React , { useState, useEffect, useContext } from "react";
import { UserInfoContext } from "../providers/UserInfoProvider";
import * as Api from "../service/api";
import * as Logic from "../service/logic";
import { Link, useLocation } from 'react-router-dom';
import creatorlist from '../css/creatorlist.module.css';
import GoBack from "./GoBack";
import { eventCode, queryParameterKeys } from '../service/constants';


//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//言語切替
import { useTranslation } from 'react-i18next';


function Creators() {
    const userInfo = useContext(UserInfoContext);
    const [profileImages, setProfileImages] = useState([]);
    const [firstVisit, setFirstVisit] = useState(true);

    const [t] = useTranslation();

    const location = useLocation();
    let trackingId ="";
    let queryParams = {};
    queryParams = Logic.convertToAssociatvieArray(location);
    // for (let key in queryParams) {
    //     console.log('key_result:' + key + ' value_result:' + queryParams[key]);
    // }
    if(Object.keys(queryParams).length > 0){
        if(typeof queryParams[queryParameterKeys.trackingId] !== "undefined"){
            trackingId = queryParams[queryParameterKeys.trackingId]
        }
    }
    
    useEffect(()=>{
        const fetch = async() =>{
            const creatorList = await Api.getCreators();
            setProfileImages(creatorList);
            if(firstVisit){
                Logic.eventLogging(userInfo, eventCode.viewCreatorList, "", trackingId);
            }
            setFirstVisit(false);
        }  
        fetch();
    }, [userInfo]);

    const followList = profileImages.map((profileImage) => {
        if(profileImage.roomId !== ""){
            return (
                <div key={profileImage.id}>
                    {
                        (() => {
                            return(
                                <div className={creatorlist.item}>
                                    <Link to={"/profile/"+profileImage.roomId}>
                                        <div className={creatorlist.flex}>
                                            <div className={creatorlist.requstorImage}>
                                                <img src={profileImage.profileImageUrl} alt="" />
                                            </div>
                                            <div className={creatorlist.content}>
                                                <p>{profileImage.displayName}<br />
                                                    <span className={creatorlist.roomId_label}>@{profileImage.roomId}</span>
                                                </p>
                                            </div>
                                            <div className={creatorlist.arrow}>
                                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                                            </div>
                                        </div>
                                    </Link>
                                </div> 

                            )
                        })()
                    }
                </div>
            );
        }
    });


    return(
        <div>
            <GoBack />
            <h2>{t('creators_title')}</h2>
            {profileImages.length >0 ?
                <div>
                    {followList}
                </div>
            :
                <div>
                    <p>{t('creators_nothing')}</p>
                </div>
            }
        </div>
    )
}

export default Creators;