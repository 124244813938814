import React , { useEffect } from "react";
import {
    Link,
  } from 'react-router-dom';

import main from '../css/main.module.css';


  const SinglSquareImageListForMainContents = (props) => {
    
    const setLastViewItemBeforeTransition = (id) =>{
        props.updateLastViewItemIndex(id);
    }

    useEffect(()=>{
        let unmounted = false;
        fetch();
        return () => {
            unmounted = true
        }
    }, []);

    const fetch = () =>{
        if (props.lastViewItemIndex !== "") {
            document.getElementById(props.lastViewItemIndex).scrollIntoView({
                behavior: "instant",
                block: "center"
            });                
        }
    }

    return (
        <div className={main.flex}>
            {props.images.map((image) => {
                const to = {
                    pathname: '/workdetail',
                    search: '?workId='+image.workId+'&creatorId='+image.authorId,
                };
                return (
                        <Link to={to} key={image.workId} onClick={() => setLastViewItemBeforeTransition(image.workId)} id={image.workId}>
                            <div className={main.list_img}>
                                <img src={image.imageUrl} alt="" />
                            </div>
                        </Link>
                );
            })}
        </div>
    );
}

export default SinglSquareImageListForMainContents;

