import React, { useState } from "react";
import howtouse from '../css/howtouse.module.css';
import artwork_icon from '../img/artwork.png';
import contact_icon from '../img/contact.png';
import find_icon from '../img/find.png';
import request_icon from '../img/request.png';
import send_icon from '../img/send.png';
import smartphone_icon from '../img/smartphone.png';
import confirm_icon from '../img/confirm.png';
import GoBack from "./GoBack";



//言語切替
import { useTranslation } from 'react-i18next';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



function HowToUse() {
    const [t] = useTranslation();
    const [onCreatorTab, setOnCreatorTab] = useState(true);
    // inputタグに初期値を与えてないとエラーになる
    const [avoidErrorDummy, setAvoidErrorDummy] = useState("");

    const tabConponent = () => {
        if(onCreatorTab){
            return(
                <div>
                    <input id="user" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={howtouse.tab_item_on_user} htmlFor="user" onClick={() => setOnCreatorTab(true)}>{t('how_to_use_tab_for_user')}</label>
                    <input id="creator" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={howtouse.tab_item_off} htmlFor="creator" onClick={() => setOnCreatorTab(false)}>{t('how_to_use_tab_for_creator')}</label>
                </div>
            )
        } else {
            return(
                <div>
                    <input id="user" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={howtouse.tab_item_off} htmlFor="user" onClick={() => setOnCreatorTab(true)}>{t('how_to_use_tab_for_user')}</label>
                    <input id="creator" type="radio" name="tab_item" value={avoidErrorDummy}/>
                    <label className={howtouse.tab_item_on_creator} htmlFor="creator" onClick={() => setOnCreatorTab(false)}>{t('how_to_use_tab_for_creator')}</label>
                </div>
            )
        }
    }

    const switchContentConponent = () => {
        if(onCreatorTab){
            return(
                <div>
                    {forUserContentConponent()}
                </div>
            )
        } else {
            return(
                <div>
                    {forCreatorContentConponent()}
                </div>
            )
        }
    }


    const forUserContentConponent = () => {
        return(
            <React.Fragment>
                <div className={howtouse.step_for_user}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_user}>
                            <div className={howtouse.number_for_user}>
                                <span>1</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_user_step1_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={find_icon} alt="find" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_user_step1_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>
                <div className={howtouse.step_for_user}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_user}>
                            <div className={howtouse.number_for_user}>
                                <span>2</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_user_step2_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={request_icon} alt="request" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_user_step2_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>
                <div className={howtouse.step_for_user}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_user}>
                            <div className={howtouse.number_for_user}>
                                <span>3</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_user_step3_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={contact_icon} alt="contact" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_user_step3_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>

                <div className={howtouse.step_for_user}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_user}>
                            <div className={howtouse.number_for_user}>
                                <span>4</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_user_step4_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={smartphone_icon} alt="smartphone" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_user_step4_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>

                <div className={howtouse.step_for_user}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_user}>
                            <div className={howtouse.number_for_user}>
                                <span>5</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_user_step5_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={artwork_icon} alt="artwork" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_user_step5_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>

                <div className={howtouse.step_for_user_last}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_user}>
                            <div className={howtouse.number_for_user}>
                                <span>6</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_user_step6_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={send_icon} alt="send" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_user_step6_body')}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const forCreatorContentConponent = () => {
        return(
            <React.Fragment>
                <div className={howtouse.step_for_creator}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_creator}>
                            <div className={howtouse.number_for_creator}>
                                <span>1</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_creator_step1_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={contact_icon} alt="contact" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_creator_step1_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>
                <div className={howtouse.step_for_creator}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_creator}>
                            <div className={howtouse.number_for_creator}>
                                <span>2</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_creator_step2_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={send_icon} alt="send" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_creator_step2_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>
                <div className={howtouse.step_for_creator}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_creator}>
                            <div className={howtouse.number_for_creator}>
                                <span>3</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_creator_step3_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={confirm_icon} alt="confirm" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_creator_step3_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>

                <div className={howtouse.step_for_creator}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_creator}>
                            <div className={howtouse.number_for_creator}>
                                <span>4</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_creator_step4_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={artwork_icon} alt="artwork" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_creator_step4_body')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={howtouse.arrow}>
                    <FontAwesomeIcon icon={['far', 'arrow-down']} />
                </div>

                <div className={howtouse.step_for_creator_last}>
                    <div className={howtouse.inner_wrapper}>
                        <div className={howtouse.title_section_for_creator}>
                            <div className={howtouse.number_for_creator}>
                                <span>5</span>
                            </div>
                            <div className={howtouse.title_text}>
                                {t('how_to_use_for_creator_step5_title')}
                            </div>
                        </div>
                        <div className={howtouse.detail_section}>
                            <div className={howtouse.detail_icon}>
                                <img src={smartphone_icon} alt="smartphone" />
                            </div>
                            <div className={howtouse.detail_text}>
                                {t('how_to_use_for_creator_step5_body')}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }



    return(
        <div>
            <GoBack />
            <div className={howtouse.tabs}>
                {tabConponent()}
            </div>
            <h2>{t('how_to_use_title')}</h2>
            <div className={howtouse.summary_section}>
                <div className={howtouse.summary_content}>
                    {t('how_to_use_summary_p1')}
                    <br />
                    <br />
                    {t('how_to_use_summary_p2')}
                </div>
            </div>
            <h2>{t('step_to_receive_title')}</h2>
                {switchContentConponent()}
        </div>
    )
}

export default HowToUse;