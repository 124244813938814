import React, {useContext} from "react";
import requestdetail from '../css/requestdetail.module.css';
import { requestClass, objectGender, objectArea, usePurpose, imageOrientation} from '../service/constants';
import { UserInfoContext } from "../providers/UserInfoProvider";
import dig from "object-dig";
import { languageCode } from '../service/constants';


//言語切替
import { useTranslation } from 'react-i18next';

const moment = require('moment');


function RequestViewComponent(props) {
    const userInfo = useContext(UserInfoContext);

    const [t] = useTranslation();

    const isNotUndefinedOrBlank = (text) =>{
        if(text !== undefined && text !== ""){
            return true;
        }else{
            return false;
        }

    }

    const translationComponent = (textJa, textEn, textZh, textTw, translation_en_ja, translation_zh_ja, translation_tw_ja) =>{
        if(dig(userInfo,"userInfo", "uid")){
            const localLangCode = userInfo.userInfo.langCode;
            if(isNotUndefinedOrBlank(textJa)){
                // 日本人が入れてる。これの英訳はないので、どんなときも日本語のテキスト
                    return(
                        <div>{textJa}</div>
                    )
            }else{
                //　海外の人が入れてるので、見てる側の言語コードによって見せるものを変える
                if(localLangCode === languageCode.japanese){
                    // 見てる人が日本人
                    if(isNotUndefinedOrBlank(translation_en_ja)){
                        return(
                            <div>{translation_en_ja.ja}</div>
                        ) 
                    }else if(isNotUndefinedOrBlank(translation_zh_ja)){
                        return(
                            <div>{translation_zh_ja.ja}</div>
                        ) 
                    }else if(isNotUndefinedOrBlank(translation_tw_ja)){
                        return(
                            <div>{translation_tw_ja.ja}</div>
                        ) 
                    }else{
                        return(
                            <div>{t('request_view_nothing')}</div>
                        ) 
                    }
                }else if(localLangCode === languageCode.english){
                    // 見てる人が英語話者
                    return(
                        <div>{textEn}</div>
                    ) 
                }else if(localLangCode === languageCode.chinese){
                    // 見てる人が中国人
                    return(
                        <div>{textZh}</div>
                    ) 
                }else if(localLangCode === languageCode.taiwan){
                    // 見てる人が台湾人
                    return(
                        <div>{textTw}</div>
                    ) 
                }else{
                    // こんな場合ないけど、一応
                    return(
                        <div>{t('request_view_nothing')}</div>
                    ) 
                }

            }
        }
    }

    const attachedSampleImageComponent = (sampleImageUrl) => {
        if(sampleImageUrl !== ""){
            return(
                <div className={requestdetail.refimg_sample}>
                    <img src={sampleImageUrl} alt="" />
                </div>    
            )
        }
    }
    const attachedImageComponent = (request) => {
        if(request.willAttachImage){
            if(request.sampleImageUrl1 === "" && request.sampleImageUrl2 === "" && request.sampleImageUrl3 === "" && request.sampleImageUrl4 === ""){
                return(
                    <React.Fragment>
                        <div className={requestdetail.section_area}>
                            <div className={requestdetail.section_title}>
                                {t('request_view_will_attach_sample_image')}
                            </div>
                            <div className={requestdetail.text}>
                                {t('request_view_will_attach_sample_image_yes')}
                            </div>
                        </div>
                        <div className={requestdetail.section_area}>
                            <div className={requestdetail.section_title}>
                                {t('request_view_sample_image')}
                            </div>
                            <div className={requestdetail.text}>
                                {t('request_view_nothing')}
                                <div className={requestdetail.no_images_caveat}>
                                    {t('request_view_will_attach_sample_image_yes_caveat_wihtout_images')}    
                                </div>                            
                            </div>
                        </div>
                    </React.Fragment>
                )    
            }else{
                return(
                    <React.Fragment>
                        <div className={requestdetail.section_area}>
                            <div className={requestdetail.section_title}>
                                {t('request_view_will_attach_sample_image')}
                            </div>
                            <div className={requestdetail.text}>
                                {t('request_view_will_attach_sample_image_yes')}
                            </div>
                        </div>
                        <div>
                            <div className={requestdetail.section_title}>
                                {t('request_view_sample_image')}
                            </div>
                            {attachedSampleImageComponent(request.sampleImageUrl1)}
                            {attachedSampleImageComponent(request.sampleImageUrl2)}
                            {attachedSampleImageComponent(request.sampleImageUrl3)}
                            {attachedSampleImageComponent(request.sampleImageUrl4)}
                        </div>
                    </React.Fragment>
                )
            }
        }else{
            if(request.sampleImageUrl1 === "" && request.sampleImageUrl2 === "" && request.sampleImageUrl3 === "" && request.sampleImageUrl4 === ""){
                return(
                    <React.Fragment>
                        <div className={requestdetail.section_area}>
                            <div className={requestdetail.section_title}>
                                {t('request_view_will_attach_sample_image')}
                            </div>
                            <div className={requestdetail.text}>
                                {t('request_view_will_attach_sample_image_no')}
                            </div>
                        </div>
                        <div className={requestdetail.section_area}>
                            <div className={requestdetail.section_title}>
                                {t('request_view_sample_image')}
                            </div>
                            <div className={requestdetail.text}>
                                {t('request_view_nothing')}
                            </div>
                        </div>
                    </React.Fragment>
                )    
            }else{
                return(
                    <React.Fragment>
                        <div className={requestdetail.section_area}>
                            <div className={requestdetail.section_title}>
                                {t('request_view_will_attach_sample_image')}
                            </div>
                            <div className={requestdetail.text}>
                                {t('request_view_will_attach_sample_image_no')}
                            </div>
                        </div>
                        <div className={requestdetail.section_area}>
                            <div className={requestdetail.section_title}>
                                {t('request_view_sample_image')}
                            </div>
                            {attachedSampleImageComponent(request.sampleImageUrl1)}
                            {attachedSampleImageComponent(request.sampleImageUrl2)}
                            {attachedSampleImageComponent(request.sampleImageUrl3)}
                            {attachedSampleImageComponent(request.sampleImageUrl4)}
                        </div>
                    </React.Fragment>
                )
            }
        }
    }
    
    const requestsView = props.requests.map((request) => {
        return (
            <div key={request.id} className={requestdetail.viewcomponent_section}>
                {
                    (() => {
                            return(
                                <div>
                                    <div className={requestdetail.top_section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_view_reference_work')}
                                        </div>
                                        <div className={requestdetail.refimg}>
                                            <img src={request.workImageUrl} alt="" />
                                        </div>
                                    </div>
                                    {request.deliveryDate !== "" && typeof request.deliveryDate !== 'undefined'?
                                        <div className={requestdetail.section_area}>
                                            <div className={requestdetail.section_title}>
                                                {t('request_create_form_delivery_date')}
                                            </div>
                                            <div className={requestdetail.text}>
                                                {moment(request.deliveryDate.seconds * 1000).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                    :
                                        <div></div>
                                    }
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_title')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            <div>
                                                {request.requestType === requestClass.original?
                                                    <div>{t('request_create_form_request_type_option1')}</div>
                                                    :
                                                <div></div>
                                                }
                                                {request.requestType === requestClass.icon?
                                                    <div>{t('request_create_form_request_type_option2')}</div>
                                                    :
                                                <div></div>
                                                }
                                                {request.requestType === requestClass.portrait?
                                                    <div>{t('request_create_form_request_type_option3')}</div>
                                                    :
                                                <div></div>
                                                }
                                                {request.requestType === requestClass.existUse?
                                                    <div>{t('request_view_use')}</div>
                                                    :
                                                <div></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_purpose_of_use')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            <div>
                                                {request.usePurpose === usePurpose.personal?
                                                    <div>{t('request_create_form_purpose_of_use_option1')}</div>
                                                    :
                                                <div></div>
                                                }
                                                {request.usePurpose === usePurpose.snsicon?
                                                    <div>{t('request_create_form_purpose_of_use_option2')}</div>
                                                    :
                                                <div></div>
                                                }
                                                {request.usePurpose === usePurpose.commercial?
                                                    <div>{t('request_create_form_purpose_of_use_option3')}</div>
                                                    :
                                                <div></div>
                                                }
                                                {request.usePurpose === "" && request.requestType === requestClass.existUse?
                                                    <div>{t('request_use_purpose_of_use')}</div>
                                                    :
                                                <div>{t('request_view_nothing')}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_gender_of_object')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            {request.objectGenger === objectGender.male?
                                                <div>{t('request_create_form_gender_of_object_option1')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.objectGenger === objectGender.female?
                                                <div>{t('request_create_form_gender_of_object_option2')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.objectGenger === objectGender.anythingok?
                                                <div>{t('request_create_form_anything_fine_option')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.objectGenger === ""?
                                                <div>{t('request_view_nothing')}</div>
                                            :
                                                <div></div>
                                            }

                                        </div>
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_draw_area')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            {request.objectArea === objectArea.face?
                                                <div>{t('request_create_form_draw_area_option1')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.objectArea === objectArea.bust?
                                                <div>{t('request_create_form_draw_area_option2')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.objectArea === objectArea.full?
                                                <div>{t('request_create_form_draw_area_option3')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.objectArea === objectArea.anythingok?
                                                <div>{t('request_create_form_anything_fine_option')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.objectArea === ""?
                                                <div>{t('request_view_nothing')}</div>
                                            :
                                                <div></div>
                                            }

                                        </div>
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_image_orientation')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            {request.imageOrientation === imageOrientation.vertical?
                                                <div>{t('request_create_form_image_orientation_option1')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.imageOrientation === imageOrientation.horizontal?
                                                <div>{t('request_create_form_image_orientation_option2')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.imageOrientation === imageOrientation.anythingok?
                                                <div>{t('request_create_form_anything_fine_option')}</div>
                                            :
                                                <div></div>
                                            }
                                            {request.imageOrientation === "" || typeof request.imageOrientation === 'undefined'?
                                                <div>{t('request_view_nothing')}</div>
                                            :
                                                <div></div>
                                            }
                                        </div>
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_exist_background')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            {request.existBackground?
                                                <div>{t('request_create_form_exist_background_option1')}</div>
                                            :
                                                <div>{t('request_create_form_exist_background_option2')}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_exist_signature')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            {request.existSignature?
                                                <div>{t('request_create_form_exist_signature_option1')}</div>
                                            :
                                                <div>{t('request_create_form_exist_signature_option2')}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        {attachedImageComponent(request)}
                                    </div>
                                    <div className={requestdetail.section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_create_form_other_requirement')}
                                        </div>
                                        <div className={requestdetail.text}>                                    
                                            {translationComponent(request.textJa, request.textEn, request.textZh, request.textTw, request.translated_en_to_ja, request.translated_zh_to_ja,  request.translated_tw_to_ja)}
                                        </div>
                                    </div>
                                    <div className={requestdetail.bottom_section_area}>
                                        <div className={requestdetail.section_title}>
                                            {t('request_view_request_date')}
                                        </div>
                                        <div className={requestdetail.text}>
                                            {moment(request.createdAt.seconds * 1000).format('YYYY-MM-DD')}
                                        </div>
                                    </div>
                                </div>
                            )
                    })()
                }
            </div>
        );
      });

    return(
        <div>
            {requestsView}
        </div>
    )
}

export default RequestViewComponent;